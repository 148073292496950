import React from "react";
import { Link } from "react-router-dom";
import logoBase from "../../img/logo.png";
import styled from "styled-components";
import { device } from "./commonsStyled/index";

const LogoImg = styled.img`
  display: flex;
  background-repeat: no-repeat;
  width: fit-content;
`;
const LogoContainer = styled.div`
  display: flex;
 
  @media ${device.mobileS} {
  
  }
  @media ${device.laptop} {
    
  }
  
`;

const Logo = (props) => {
  return (
    <LogoContainer>
      <Link to="/">
        <LogoImg src={logoBase} />
      </Link>
    </LogoContainer>
  );
};

export default Logo;
