import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions';
import styled from "styled-components";

const LogOut = () =>{
    const dispatch = useDispatch()
    return(
      <Button onClick={() => dispatch(logout())}>Exit</Button>
    )
  }

export default LogOut;

const Button = styled.button`
  width:100px;
  height:50px;
  border-radius:25px;
  font-family: Montserrat-Bold;
  font-size:16px;
  color:#209ebf;
  border:1px solid #209ebf;
  background-color:transparent;
`;