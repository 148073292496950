import React from "react";
import styled from "styled-components";
import { device } from './../commons/commonsStyled/index';

const ProductTable = (props = {}) => {
  const products = props.product || {};


  function getStorageString(storage_type_id)
  {
    switch (storage_type_id) {
      case 0:
        return 'Chilled'
      case 1:
        return 'Frozen'
      case 2:
        return 'Shelf Stable'
      default:
        return 'undefined'
    }
  }

  return (
    <Table>

      {products.length == 0 ?
        <h4>There are no products to display</h4>
        :
        <>
        <thead>
        <Tr>
          <Th align="left" width="70%">PRODUCT NAME</Th>
          <Th align="center" width="15%">SHELF-LIFE (DAYS)</Th>
          <Th align="center" width="15%">STORAGE TYPE</Th>
        </Tr>
      </thead>
      <tbody>

        {products.map(
          prod => {
            return (<Tr>
              <Td align="left">{prod.name} </Td>
              <Td align="center">{prod.self_life}</Td>
              <Td align="center">{getStorageString(prod.storage_type)}</Td>
            </Tr>)
          }
        )}
      
      </tbody></>
      }
      
    </Table>
  );
};

export default ProductTable;


export const Table = styled.table`
  border-spacing: 20px;
  width:100%;
  color: #3f424c;
  @media ${device.mobileS} {
    border-spacing: 0px;
  }
  @media ${device.laptopL} {
    border-spacing: 20px;
  }
`;
export const Th = styled.th`
  font-family:Montserrat-Bold;
  margin-right:10px;
  text-align:${props => props.align};
  width:${props => props.width};
  
  &:after {
    display:block;
    content: '';
    width: 100%;
    height:2px;
    margin-top:9px;
    background-color: grey;
  }
  @media ${device.mobileS} {
    font-size:13px;
  }
  @media ${device.laptopL} {
    font-size:16px;
  }
`;

export const Td = styled.td`
  font-family:Montserrat-Medium;
  text-align:${props => props.align};
  width:${props => props.width};
  color: #3f424c;
  &:after {
    display:block;
    content: '';
    width: 100%;
    height:1px;
    margin-top:9px;
    background-color: grey;
  }
  @media ${device.mobileS} {
    font-size:13px;
  }
  @media ${device.laptopL} {
    font-size:16px;
  }
`;
export const Tr = styled.tr`
 vertical-align:bottom;
`;
