import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import addfilter from "../../../img/addfilter.png";
import deleteCategoryImg from "../../../img/delete-category.png";
import check from "../../../img/check.svg";
import { device } from "./../../commons/commonsStyled/index";
import {
  ButtonImg,
  ButtonText,
  RefineSeachButton,
  Img,
} from "../../pages/Directory";

const root = document.getElementById("root");

const customStyles = {
  content: {
    width: "625px",
    height: "695px",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "solid 1px #309da1",
    "&::WebkitScrollbarTrack": {
      background: "orange",
    },
  },
};

const AddCategoryField = ({
  input: { value, onChange, onBlur, onFocus },
  meta,
  categories,
}) => {
  const { error } = meta;
  const [open, setOpen] = useState(false);
  const [selectedCat, setSelectedCat] = useState(null);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedCat(null);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleChangeCategory = useCallback(
    (e) => {
      const newValue = [...value];
      const {
        target: { value: id, name },
      } = e;
      let valueCat = value.find((cat) => cat.id === selectedCat.id);
      if (!valueCat) {
        valueCat = { ...selectedCat, subcategories: [] };
        if (e.target.checked) {
          newValue.push(valueCat);
        }
      }
      if (e.target.checked) {
        valueCat.subcategories.push({ name, id });
      }
      if (!e.target.checked) {
        let index = valueCat.subcategories.findIndex(
          (element) => element.id === id
        );
        valueCat.subcategories.splice(index, 1);
      }

      if (valueCat.subcategories.length <= 0) {
        const catIndex = newValue.findIndex((cat) => cat.id === valueCat.id);
        newValue.splice(catIndex, 1);
      }

      // const catInValue = value.find()
      console.log(value);
      onChange(newValue);
    },
    [onChange, selectedCat, value]
  );

  const handleDeleteCategory = useCallback(
    ({ catId, subcatIndex }) => {
      let valueCat = value.find((cat) => cat.id === catId);

      if (!valueCat) {
        return;
      }
      const newValue = [...value];
      valueCat.subcategories.splice(subcatIndex, 1);
      if (valueCat.subcategories.length !== 0) {
        return onChange(newValue);
      }
      const catIndex = newValue.findIndex((cat) => cat.id === valueCat.id);
      if (catIndex !== -1) {
        newValue.splice(catIndex, 1);
        return onChange(newValue);
      }
    },
    [onChange, value]
  );

  const valueSelectedCat = useMemo(() => {
    if (selectedCat) {
      return value.find((cat) => cat.id === selectedCat.id);
    }
  }, [value, selectedCat]);
  console.log(valueSelectedCat);
  const showError = meta.error && (meta.dirty || meta.touched);

  return (
    <div onFocus={onFocus} onBlur={onBlur}>
      {/*!!showError && <p style={{ color: "red" }}>{error}</p>*/}

      {value
        ? value.map((valueCat) => (
            <Container key={valueCat.id}>
              <Category>{valueCat.name}</Category>
              <SubcategoryList>
                {valueCat.subcategories.map((valueSubcat, subcatIndex) => (
                  <Subcategory>
                    {valueSubcat.name}
                    <SubcategoryDeleteButton
                      type="button"
                      key={valueSubcat.id}
                      onClick={() =>
                        handleDeleteCategory({
                          catId: valueCat.id,
                          subcatIndex,
                        })
                      }
                    />
                  </Subcategory>
                ))}
              </SubcategoryList>
            </Container>
          ))
        : null}
      {value.length < 5 && (
        <RefineSeachButton type="button" onClick={handleOpen}>
          <ButtonText>Adicionar Categoria</ButtonText>
          <ButtonImg>
            <Img src={addfilter} alt="addfilter" />
          </ButtonImg>
        </RefineSeachButton>
      )}

      <Modal
        appElement={root}
        isOpen={open}
        onRequestClose={handleClose}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ModalContainer>

          {selectedCat === null ? (
            <Title>
              Select the <br />
              product category
            </Title>
          ) : (
            <Title>
              Select {selectedCat.name} <br />
              sub-category
            </Title>
          )}
          <List>
            {selectedCat === null ? (
              <>
                {categories
                  ? categories.map((item) => (
                      <Item key={item.id} onClick={() => setSelectedCat(item)}>
                        {item.name}
                        <>
                          <Arrow
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.889"
                            height="28.25"
                            viewBox="0 0 15.889 28.25"
                          >
                            <path
                              id="Контур_300"
                              fill="none"
                              data-name="Контур 300"
                              d="M0,0,14.806,14.322,0,27.517"
                              transform="translate(0.348 0.359)"
                            />
                          </Arrow>
                        </>
                      </Item>
                    ))
                  : null}
              </>
            ) : (
              <>
                {selectedCat.subcategories.map((subcat) => (
                  <div key={subcat.id}>
                    <InputCastom
                      id={subcat.name}
                      type="checkbox"
                      name={subcat.name}
                      value={subcat.id}
                      onChange={handleChangeCategory}
                      checked={
                        !!valueSelectedCat?.subcategories.some(
                          (valueSubcat) => {
                            return valueSubcat.id == subcat.id;
                          }
                        )
                      }
                    />
                    <ItemLabel htmlFor={subcat.name}>{subcat.name}</ItemLabel>
                  </div>
                ))}
              </>
            )}
          </List>
          <Button onClick={handleClose} style={{display:"block", margin:"auto", marginBottom:"5px"}}>Confirm</Button>
        </ModalContainer>
      </Modal>
    </div>
  );
};

export default AddCategoryField;

export const Category = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  border-radius: 36px;
  border-right: solid 1px #20babf;
  border-left: solid 1px #20babf;
  border-bottom: solid 1px #20babf;
  background-color: #ffffff;
  margin-right: 19px;
  @media ${device.mobileS} {
    min-width: 280px;
  }
  @media ${device.mobileL} {
    min-width: 380px;
  }
  @media ${device.tablet} {
    min-width: 200px;
    margin-right: 10px;
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 37px;
  border-radius: 36px;
  border: solid 1px #20babf;
  background-color: #eef9f9;
  font-family: Montserrat-Bold;
  font-size: 16px;
  font-weight: bold;
  color: #20babf;
  margin-bottom: 19px;
  @media ${device.mobileS} {
    width: 280px;
    flex-direction: column;
    padding-right: 0px;
  }
  @media ${device.mobileL} {
    width: 380px;
  }
  @media ${device.tablet} {
    flex-direction: row;
    width: fit-content;
    align-items: flex-start;
  }
`;

export const SubcategoryList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media ${device.mobileS} {
    padding: 10px;
    width: 260px;
  }
  @media ${device.mobileL} {
    width: 350px;
  }
  @media ${device.tablet} {
    width: 400px;
    padding: 0px;
    margin-bottom: 0px;
  }
  @media ${device.laptopL} {
    width: 700px;
  }
`;
export const Subcategory = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  border-radius: 36px;
  border: solid 1px #20babf;
  background-color: #ffffff;
  font-family: Montserrat-Medium;
  font-weight: 500;
  margin-right: 19px;
  margin-top: 8px;
  margin-bottom: 6px;
  padding: 10px 16px 10px 18px;
  @media ${device.mobileS} {
    font-size: 11px;
  }
  @media ${device.tablet} {
    font-size: 16px;
  }
`;
export const SubcategoryDeleteButton = styled.button`
  display:flex;
  width: 12px;
  height: 12px;
  border:none;
  background-color:transparent;
  background-image:url("${deleteCategoryImg}");
  background-size:cover;
  margin-left:25px;
`;

export const Title = styled.p`
  font-size: 38px;
  color: #4e4e4e;
  margin: 60px 0 50px 0;
  @media ${device.mobileL} {
    font-size: 20px;
  }
`;
export const List = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 504px;
  height: 58px;
  border-radius: 36px;
  border: solid 1px #aaaaaa;
  background-color: #ffffff;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: Montserrat-Bold;
  color: #aaaaaa;
  padding: 0 27px 0 27px;
  &:hover {
    border-color: #309da1;
    path {
      stroke: #309da1;
    }
  }
  @media ${device.mobileS} {
    width: 280px;
    font-size: 9px;
    height: 30px;
    box-sizing: border-box;
  }
  @media ${device.mobileL} {
    width: 350px;
    font-size: 15px;
    height: 50px;
    box-sizing: border-box;
  }
`;

export const ModalContainer = styled.div`
  margin: 0 auto;
  height: 50vh;
  width: 90%;
  @media ${device.mobileS} {
    width: 280px;
  }
  @media ${device.mobileL} {
    width: 350px;
  }
`;
export const Arrow = styled.svg`
  path {
    stroke: #aaa;
  }
  @media ${device.mobileS} {
    height: 15px;
  }
  @media ${device.mobileL} {
    height: 20px;
  }
`;
export const ItemLabel = styled.label`
  display: flex;
  position:relative;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 504px;
  height: 58px;
  border-radius: 36px;
  border: solid 1px;
  border-color: #aaaaaa;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: Montserrat-Bold;
  color: #aaaaaa;
  padding: 0 54px 0 27px;
  &:after {
    position:absolute;
    right:2%;
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: solid 1px;
    border-color: #aaaaaa;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    @media ${device.mobileS} {
      width: 15px;
      height: 15px;
    }
    @media ${device.mobileL} {
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    border-color: #309da1;
    path {
      stroke: #309da1;
    }
  }
  @media ${device.mobileS} {
    width: 280px;
    font-size: 9px;
    height: fit-content;
    box-sizing: border-box;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  @media ${device.mobileL} {
    width: 350px;
    font-size: 15px;
    height: fit-content;
    box-sizing: border-box;
  }
`;

export const InputCastom = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
  &:checked + ${ItemLabel}:after {
    border-color: #309da1;
    background-image: url(${check});
  }
  &:checked + ${ItemLabel} {
    border-color: #309da1;
  }
  @media ${device.mobileS} {
    max-width: 280px;
  }
`;

export const Button = styled.button`
  width: 252px;
  height: 58px;
  border-radius: 29px;
  background-color: #20babf;
  border: none;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  font-family: Montserrat-Medium;
  margin: 39px 0 42px 0;
  @media ${device.mobileS} {
    width: 152px;
    height: 48px;
  }
`;



