import { ADD_FILTER_CATEGORIES, DELETE_FILTER_CATEGORIES,ADD_FILTER_SUBCATEGORIES } from "./actionTypes";

const initialState = [];

export const filterCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILTER_CATEGORIES:
      return [action.payload];
    // case ADD_FILTER_SUBCATEGORIES:
    //   return {...state,subcategories:action.payload};
    // case DELETE_FILTER_CATEGORIES:
    //   return [...state.splice(action.payload, 1)];;
    default:
      return state;
  }
};
