import React,{useState,useCallback} from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LogOut from "./LogOut";
import styled from "styled-components";
import { device } from "./commonsStyled/index";
import Modal from "react-modal";
import open from '../../img/mobile-nav.svg'
import openBlack from '../../img/mobile-nav-black.svg'
import close from '../../img/product-close.svg'
import { HashLink } from 'react-router-hash-link';
const root = document.getElementById("root");
const customStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    border: "solid 1px #309da1",
    
  },
};

const ModalContainer = styled.div`
  display:flex;
  width:100%;
  height:100%;
  justify-content:center;
  align-items:center;
  position:relative;
 
`;
const NavModal = styled.div`
  display:none;
  @media ${device.mobileS} {
    display:flex;
  }
  @media ${device.mobileL} {
    display:flex;
  }
  @media ${device.tablet} {
    display:none;
  }
  
  
`;
const List = styled.ul`
  display:flex;
  flex-direction:column;
  align-items:center;
`;
const NavList = styled.ul`
  
  align-items:center;
  @media ${device.mobileS} {
    display: none;
  }
  @media ${device.mobileL} {
    display: none;
  }
  @media ${device.tablet} {
    display: flex;
    font-size: 13px;
  }
  
  
`;
const NavItem = styled.li`
  
  @media ${device.mobileS} {
    padding-right: 0px;
    margin-bottom:20px;
  }
  @media ${device.tablet} {
    padding-right: 20px;
    margin-bottom:0px;
  }
`;
const LinkItem = styled(Link)`
  
  text-decoration: none;
  font-family: "SemiBold";
  text-transform: uppercase;

  @media ${device.mobileS} {
    color:black;
  }
  @media ${device.tablet} {
    color: ${(props) => (props.home && !props.isSticky ? "white" : "black")};
  }
  
`;
const HashLinkItem = styled(HashLink)`
  
  text-decoration: none;
  font-family: "SemiBold";
  text-transform: uppercase;

  @media ${device.mobileS} {
    color:black;
  }
  @media ${device.tablet} {
    color: ${(props) => (props.home && !props.isSticky ? "white" : "black")};
  }
  
`;
const ButtonNav = styled.button`
  width:26px;
  height:16px;
  background-color:transparent;
  background-image:url("${(props) => props.home && !props.isSticky ? open : openBlack}");
  background-repeat:no-repeat;
  cursor: pointer;
  border:none;
`;

const ButtonClose = styled.button`
@media ${device.mobileS} {
  width:30px;
  height:30px;
  background-color:transparent;
  background-image:url("${close}");
  background-repeat:no-repeat;
  background-size:cover;
  border:none;
  position:absolute;
  top:0;
  right:0;

  }
  
`;
const LinkItemCastom = styled(LinkItem)`
@media ${device.mobileL} {
  font-size:20px;

  }
  
`;

const HashLinkItemCastom = styled(HashLinkItem)`
@media ${device.mobileL} {
  font-size:20px;

  }
  
`;

const Navigation = (props) => {
  /**Paulinho esta var define se está a aceder com as tais cardenciais de cliente que se vai registar */
  const haveLoginCardencials = false;

  const isAuth = useSelector((root) => root.auth.isLoggedIn);
 
  const [open, setOpen] = useState(false);
  const [newClaims, setNewClaims] = useState([]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  return (
    <>
      <NavList>
        <NavItem>
          <HashLinkItem  {...props} to="/#about">
            How it works
          </HashLinkItem >
        </NavItem>
        <NavItem>
          <LinkItem {...props} to="/directory?title= ">
            Directory
          </LinkItem>
        </NavItem>
        <NavItem>
          <HashLinkItem {...props} to="/#contact">
            Contact
          </HashLinkItem>
        </NavItem>
        
        
        {isAuth ? (
          <NavItem>
            <LinkItem {...props} to="/registos">
              Registos
            </LinkItem>
          </NavItem>
        ) : (
          <NavItem>
          <HashLinkItem {...props} to="/login">
            Login
          </HashLinkItem>
      </NavItem>
        )}

        {isAuth ? (
          <NavItem>
          <LogOut />
          </NavItem>
        ) : (
          ""
        )}
      </NavList>
      
      <NavModal> <ButtonNav {...props} onClick={handleOpen} ></ButtonNav> </NavModal>
        <Modal
          appElement={root}
          isOpen={open}
          onRequestClose={handleClose}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <ModalContainer>
            <List>
              <NavItem>
                <HashLinkItemCastom  {...props} to="/#about">
                  How it works
                </HashLinkItemCastom >
              </NavItem>
              <NavItem>
                <LinkItemCastom {...props} to="/directory?title= ">
                  Directory
                </LinkItemCastom>
              </NavItem>
              <NavItem>
                <HashLinkItemCastom {...props} to="/#contact">
                  Contact
                </HashLinkItemCastom>
              </NavItem>
     
              {isAuth ? (
                <NavItem>
                  <LinkItemCastom {...props} to="/registos">
                    Registos
                  </LinkItemCastom>
                </NavItem>
              ) : (
                ""
              )}
              {!isAuth ? (
                <NavItem>
                  {" "}
                  <LinkItemCastom {...props} to="/login">
                    Login
                  </LinkItemCastom>
                </NavItem>
              ) : (
                <NavItem>
                  <LogOut />
                </NavItem>
              )}
              <ButtonClose onClick={handleClose}></ButtonClose>
            </List>
          </ModalContainer>
        </Modal>
      
    </>
  );
};

export default Navigation;
