import React, { useEffect, useState } from "react";
import {
  Link,
  useRouteMatch,
} from "react-router-dom";
import styled from "styled-components";
import Navigation from "../commons/Navigation";
import { fetchClaims, fetchCategories, fetchCertifications, fetchCompanies, fetchPrivacyPolicy, addFilterClaims, addFilterCertification, addFilterPrivateLabel, addFilterCategories } from '../../redux/actions'

import {
  HeaderContainer,
  Wrapper,
  Container,
  ContentContainer,
  Br,
} from "../commons/commonsStyled/index";
import Logo from "./../commons/Logo";
import Footer from "../commons/Footer";
import Search from "../commons/Search/Search";
import companylogo from "../../img/companylogo.png";
import FilterModal from "../commons/FilterModal"
import { device } from './../commons/commonsStyled/index';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from "../commons/Cookies";
import Loading from "../commons/Loading";

let filter = {
  title: null,
  private_label: null,
  claims: [],
  categories: [],
  certification: [],
  all: null
}

const filterColor = {
  category: "#20babf",
  claims: "#209ebf",
  sertifications: "#556fab",
};



function isTrue(arr, arr2) {
  let c = 0;
  arr.forEach(element => {
    arr2.forEach(element2 => {
      if (element.id == element2.id) c++;
    });
  });

  if (c === arr2.length) return true;
  else return false;
}

function isTrueByName(arr, arr2) {
  let c = 0;
  arr.forEach(element => {
    arr2.forEach(element2 => {
      if (element.name == element2.name) c++;
    });
  });

  if (c === arr2.length) return true;
  else return false;
}


const tranformD = (companies, aplyFilter = true) => {
  if (!companies) return [];


  //console.log("execute" , filter);

  let result = companies;

  result = result.sort(function(a, b) {
      var textA = a.business_name.toUpperCase();
      var textB = b.business_name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
 
  result = result.filter(x => x.status === 1);



  if (aplyFilter && filter.title) {
    result = result.filter(x => x.business_name.includes(filter.title));
  }

  if (aplyFilter && filter.private_label) {
    result = result.filter(x => x.private_label == filter.private_label);
  }

  if (aplyFilter && filter.claims.length > 0) {
    result = result.filter(x => isTrue(x.claims, filter.claims));
  };

  if (aplyFilter && filter.certification && filter.certification.length > 0) {
    result = result.filter(x => isTrueByName(x.certifications, filter.certification));
  }

  if (aplyFilter && filter.categories && (filter.categories.length > 0 && filter.categories[0].length > 0)) {
    result = result.filter(p => {
      return filter.categories[0].map(p => p.subcategories[0]).every(subCategory => {
        return p.subcategories.find(elem => ('' + elem.id) == ('' + subCategory.id)) != undefined;
      });
    });
  };

  /**Filter all */
  if (aplyFilter && filter.all) {
    let filterIterable = filter.all.split(" ");

    
   
    filterIterable.forEach(word => {
      result = result.filter(item =>
      
        Object.keys(item).some(
          k => {
            if (Array.isArray(item[k])) {
             // console.log("é array!!! crl", item[k]);
  
              return item[k].some(
                item2 =>{
                  return Object.keys(item2).some(
                    g =>{
                      //console.log(item2[g]);
                      return item2[g] != null && item2[g].toString().toLowerCase().includes(word.toLowerCase());
                    }
                  )
                } 
              )
  
            }
            else {
              return item[k] != null && item[k].toString().toLowerCase().includes(word.toLowerCase());
            }
  
          }
  
        )
      );
    });

  }

  console.log("result", result);

  return result.map(resp => {
    return {
      title: resp.business_name || '',
      text: resp.description || '',
      img: resp.logo_url || companylogo,
      id: resp.id,
      filterHead: 'Category',
      filterlist: {
        category: (resp.subcategories || []).map(sb => ({ id: sb.id, name: sb.name, category_id: sb.category_id })),
        claims: (resp.claims || []).map(sb => ({ id: sb.id, name: sb.name })),
        certifications: (resp.certifications || []).map(sb => ({ id: sb.id, name: sb.name })),
      },
      private_label: resp.private_label

      /*title  - >  business_name
      text  - >  description
      img -> logo_url
      id  -> id
filterHead -> ‘Category’*/

    }
  });
}

// -------------------------
// Funcoes leitura e escrita
// do 'filter'
// -------------------------



// ---------------------------------------------
// Funcao que fazem o parse dos filtros da 'URL'
// ---------------------------------------------

const loadClaimsFromUrl = (claims, dispatch, uRLSearchParamsObject = null) => {
  uRLSearchParamsObject = (uRLSearchParamsObject) ? uRLSearchParamsObject : new URLSearchParams(window.location.search);

  if (uRLSearchParamsObject.has('claims') && claims && claims.length > 0) {
    (uRLSearchParamsObject.get('claims').split(',') || []).forEach(searchElement => {

      claims.forEach(elem => {
        //console.log("value",elem);
        let v = elem.children.find(c => c.id == searchElement);
        if(!v){
          v =  elem.id == searchElement ? elem : null;
        }
        
        if (v) {
          dispatch(addFilterClaims({ id: v.id, name: v.name }));
        }
      });
    });
  }
}

const loadCertificationFromUrl = (certifications, dispatch, uRLSearchParamsObject = null) => {
  uRLSearchParamsObject = (uRLSearchParamsObject) ? uRLSearchParamsObject : new URLSearchParams(window.location.search);

  if (uRLSearchParamsObject.has('certification') && certifications && certifications.length > 0) {
    uRLSearchParamsObject.get('certification').split(',').forEach(searchElement => {
      const v = certifications.find(c => c.id == searchElement);
      if (v) {
        dispatch(addFilterCertification(v));
      }
    });
  }
}

const loadPrivateLabelFromUrl = (dispatch, uRLSearchParamsObject = null) => {
  uRLSearchParamsObject = (uRLSearchParamsObject) ? uRLSearchParamsObject : new URLSearchParams(window.location.search);

  if (uRLSearchParamsObject.has('private_label')) {
    dispatch(addFilterPrivateLabel({ id: uRLSearchParamsObject.get('private_label') }));
  }
}

const loadCategoriesFromUrl = (categories, dispatch, uRLSearchParamsObject = null) => {
  uRLSearchParamsObject = (uRLSearchParamsObject) ? uRLSearchParamsObject : new URLSearchParams(window.location.search);

  if (uRLSearchParamsObject.has('categories')) {
    let objParsed = {};
    let objParsedActualKey = null;

    const urlCategoriesAndSubCategories = uRLSearchParamsObject.get('categories').split(',');
    urlCategoriesAndSubCategories.forEach(elem => {
      const firstChar = (elem.length > 0) ? elem[0] : null;

      if (firstChar) {
        if (firstChar === 'c') {
          objParsedActualKey = elem.replace('c', '');
          objParsed[objParsedActualKey] = [];
        } else if (firstChar === 's') {
          objParsed[objParsedActualKey].push(elem.replace('sc', ''));
        }
      }
    });

    const finalResult = Object.keys(objParsed).reduce((acc, catID) => {
      const category = categories.find(c => c.id == catID);
      const subcategories = category.subcategories.reduce((acc, elem) => {
        if (objParsed[catID].find(sc => sc == elem.id)) {
          acc.push({ id: elem.id, name: elem.name });
        }

        return acc;
      }, []);

      if (subcategories.length > 0) {
        acc.push({
          id: category.id,
          name: category.name,
          subcategories: subcategories
        });
      }

      return acc;
    }, []);

    if (finalResult && finalResult.length > 0) {
      dispatch(addFilterCategories(finalResult));
    }

  }
}

// --------------------------------
// Funcao de renderização principal
// --------------------------------

let loadedClaimsFromUrl = false;

const Directory = () => {
  let { url } = useRouteMatch();
  const dispatch = useDispatch();

  let [Loadingvar, setLoading] = useState(true);
  // ----------------
  // waiting for data
  // ----------------

  const list = useSelector((root) => root.companies.companies);
  const claims = useSelector((root) => root.claims.claims.data);
  const certifications = useSelector((root) => root.certifications.certifications.data);
  const categories = useSelector((root) => root.categories.categories.data);
  // const result = useSelector((root) => root.privacyPolicy.data);

  // a escuta de alteracoes nos filtros
  const listFilterClaims = useSelector((root) => root.filterClaims);
  const listFilterCertifications = useSelector((root) => root.filterCertifications);
  const privateLabel = useSelector((root) => root.filterPrivateLabel);
  const listFilterCategories = useSelector((root) => root.filterCategories);

  // actualiza o filtro sempre que os dados mudarem
  // assim ao clicarmos search (callback = updateFilter) temos sempre os filtro preenchidos
  filter.claims = listFilterClaims || [];
  filter.certification = listFilterCertifications || [];
  filter.private_label = privateLabel[0] ? privateLabel[0].id : null;
  filter.categories = listFilterCategories || [];

  // -------------
  // load from URL
  // -------------

  if (claims && certifications && categories && !loadedClaimsFromUrl) {
    const urlObj = new URLSearchParams(window.location.search);
    loadedClaimsFromUrl = true;

    loadClaimsFromUrl(claims, dispatch, urlObj);
    loadCertificationFromUrl(certifications, dispatch, urlObj);
    loadPrivateLabelFromUrl(dispatch, urlObj);
    loadCategoriesFromUrl(('data' in categories) ? categories.data : [], dispatch, urlObj);
  }
// funcao que actualiza o objecto filter ao clicar em Search
const updateFilter = (search, dispatch, applyAllFilters = true) => {
  // console.log('-------------SEARCH---------');

  setLoading(true);
  filter.all = search;

  const url = new URL(window.location.href);

  (
    (!applyAllFilters)
      ? [{ name: 'title', data: filter.title }]
      : [
        { name: 'title', data: filter.title },
        { name: 'claims', data: filter.claims },
        { name: 'certification', data: filter.certification },
        { name: 'categories', data: filter.categories },
        { name: 'private_label', data: filter.private_label },
      ]
  ).forEach(({ name, data }) => {
    url.searchParams.delete(name);

    if (data && data.length > 0) {
      switch (name) {
        case 'title':
        case 'private_label':
          url.searchParams.append(name, data);
          break;
        case 'categories':
          const resultData = data.reduce((acc, aCat) => {
            return [...acc, aCat.map(cat => ['c' + cat.id, ...cat.subcategories.map(sc => 'sc' + sc.id)])];
          }, []).join();

          if (resultData.length > 0) {
            url.searchParams.append(name, resultData);
          }
          break;
        default:
          url.searchParams.append(name, data.map(c => c.id));
          break;
      }
    }
  });

  window.history.pushState(null, null, url);
 
  dispatch(fetchCompanies()).then(()=>{setLoading(false)});
  // console.log('-------------End Search---------');
};
  // --------------------
  // execute after render
  // --------------------

  useEffect(() => {
    dispatch(fetchPrivacyPolicy());
    dispatch(fetchClaims());
    dispatch(fetchCategories());
    dispatch(fetchCertifications());
    dispatch(fetchCompanies(true)).then(()=>{setLoading(false)});
    


    document.title = 'Portuguese Food & Beverage Portfolio';
   
  }, []);
  


  return (
    <>
      <Cookies />
      {Loadingvar == true ? <Loading loading={true} loadingState={2} loadMessage={"Geting all companies..."}></Loading> : <></>}
      <Wrapper>
        <Container>
          <HeaderContainer>
            <Logo />
            <Navigation />
          </HeaderContainer>
        </Container>
        <ContentContainer>
          <Search
            home="true"
            directory="true"
            callback={(search, applyallfilters) => { updateFilter(search, dispatch, applyallfilters)}}
            companies={tranformD(list, false)}
          />
          {/*result ?  <div dangerouslySetInnerHTML={{__html: result.description}}></div> : null*/}
        </ContentContainer>
        <RefineSeach>
          <ContentContainer>
            <RefineSeachText>Refine search</RefineSeachText>
            <RefineSeachList>
              <RefineSeachItem></RefineSeachItem>
            </RefineSeachList>

            <FilterModal
              filterClaims={filter.claims}
              filterCertifications={filter.certification}
              filterPrivateLabel={filter.private_label}
              filterCategories={filter.categories}
            />
            <Br />
          </ContentContainer>
        </RefineSeach>
        <SearchResult>
          <ContentContainer>
            {/* <div class="ya-share2" data-services="whatsapp,vkontakte,gplus" data-url="https://www.google.com/" data-size="s"></div> */}
            <RefineSeachText>Search result</RefineSeachText>
            <ResultList>
              
              {tranformD(list).length > 0 ? 
              
              tranformD(list).map((item, it) => (
                <Link key={'link_' + it} to={`${url}/${item.title}`}>
                  <ResultItem>
                    <ItemImg>
                      <Img src={item.img} alt="img"></Img>
                    </ItemImg>
                    <ItemContent>
                      <ContentTitle>{item.title}</ContentTitle>
                      <ContentText>{item.text}</ContentText>
                      <FilterList>
                        <FilterItem>
                          <FilterItemHead category={filterColor.category}>
                            Categories
                          </FilterItemHead>
                          <FilterItemList>
                            {item.filterlist.category.map((item, it) => (
                              <Filter key={'filter_categories_' + it} category={filterColor.category} onClick={() => window.location = ('/directory?categories=c' + item.category_id + ',sc' + item.id)}>
                                {item.name}
                              </Filter>
                            ))}
                          </FilterItemList>
                        </FilterItem>
                        <FilterItem>
                          <FilterItemHead claims={filterColor.claims}>
                            Claims
                          </FilterItemHead>
                          <FilterItemList>
                            {item.filterlist.claims.map((item, it) => (
                              <Filter key={'filter_claims_' + it} claims={filterColor.claims} onClick={() => window.location = ('/directory?claims=' + item.id)}>
                                {item.name}
                              </Filter>
                            ))}
                          </FilterItemList>
                        </FilterItem>
                        <FilterItem>
                          <FilterItemHead
                            certifications={filterColor.sertifications}
                          >
                            Certifications
                          </FilterItemHead>
                          <FilterItemList>
                            {item.filterlist.certifications.map((item, it) => (
                              <Filter
                                key={'filter_certifications_' + it}
                                certifications={filterColor.sertifications}
                                onClick={() => window.location = ('/directory?certification=' + item.id)}
                              >
                                {item.name}
                              </Filter>
                            ))}
                          </FilterItemList>
                        </FilterItem>
                      </FilterList>
                    </ItemContent>
                  </ResultItem>
                </Link>
               
              )) :
              <h3> {filter.all ? " No results for "+filter.all :" "} 
                {filter.categories.length >0 || filter.certification.length >0 ||  filter.claims.length >0 || filter.private_label
                || filter.title ? " No results with the present filters" : " "}
              </h3>}
            </ResultList>
          </ContentContainer>
        </SearchResult>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Directory;

export const RefineSeach = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f8f8f8;
  margin-top: 60px;
  padding: 40px 0 57px 0px;
`;
export const RefineSeachText = styled.p`
  font-family: Montserrat-Regular;
`;
export const RefineSeachList = styled.ul`
  margin-top: 23px;
`;
export const RefineSeachItem = styled.li``;

export const RefineSeachButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 349px;
  height: 58px;
  padding: 0px 20px 0px 26px;
  margin-bottom: 18px;
  border-radius: 36px;
  border: solid 1px #a2a2a2;
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  @media ${device.mobileS} {
    width: 189px;
    height: 44px;
    padding: 0px 10px 0px 16px;
  }
  @media ${device.mobileL} {
    width: 200px;
    height: 44px;
  }
  @media ${device.laptopL} {
    width: 348px;
    height: 58px;
  }
`;
export const ButtonText = styled.p`
  font-family: Montserrat-Regular;
  font-size: 16px;
  line-height: 1.19;
  color: #a2a2a2;
  @media ${device.mobileS} {
    font-size: 12px;
  }
  @media ${device.laptopL} {
    font-size: 16px;
  }
`;
export const ButtonImg = styled.div`
@media ${device.mobileS} {
    width:22px;
    height:22px;
  }
  @media ${device.laptopL} {
    width:32px;
    height:32px;
  }
`;
export const SearchResult = styled(RefineSeach)`
  background-color: #ffffff;
`;
export const ResultList = styled.ul`
@media ${device.mobileS} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media ${device.laptopL} {
    align-items: flex-start;
  }
    
`;
export const ResultItem = styled.li`
  display: flex;
  border: solid 1px #e4e4e4;
  margin-top: 45px;
  /*height: 397px;*/
  @media ${device.mobileS} {
    flex-direction:column;
    /*height:577px;*/
    width: 290px;
  }
  @media ${device.mobileL} {
    flex-direction:column;
    /*height:577px;*/
    width: 360px;
  }
  @media ${device.tablet} {
    flex-direction:column;
    /*height:630px;*/
    width: 557px;
  }
  @media ${device.laptop} {
    flex-direction:row;
    /*height:300px;*/
    width: 900px;
  }
  @media ${device.laptopL} {
    /*height:400px;*/
    width: 1200px;
  }
  @media ${device.desktop} {
    /*height:400px;*/
    width: 1600px;
  }
`;

export const Img = styled.img`
@media ${device.mobileS} {
  width: 100%;
  height: auto;
  vertical-align: middle;
  }
 
  
  
`;
export const ItemImg = styled.div`
  width: 348px;
  /*height: 397px;*/
  @media ${device.mobileS} {
    width: 290px;
    /*height: 191px;*/
  }
  @media ${device.mobileL} {
    width: 360px;
    /*height: 220px;*/
  }
  @media ${device.tablet} {
    /*height:300px;*/
    width: 557px;
  }
  @media ${device.laptop} {
    /*height:300px;*/
    max-width: 300px;
  }
  @media ${device.laptopL} {
    /*height:400px;*/
    min-width: 348px;
  }
  
`;
export const ItemContent = styled.div`
  padding: 40px;
  @media ${device.mobileS} {
    width: 280px;
    padding: 10px;
    
  }
  @media ${device.tablet} {
    width:100%;

  }
  @media ${device.laptop} {
    padding: 20px;

  }
  
`;

export const ContentTitle = styled.p`
  font-family: Montserrat-Bold;
  font-size: 22px;
  color: #4b4b4b;
  @media ${device.mobileS} {
    font-size: 15px;
    margin-top:10px;
  }
  @media ${device.tablet} {
    font-size: 18px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
  }
`;
export const ContentText = styled.p`
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #4b4b4b;
  margin: 23px 0px 42px 0px;
  height: 68px;
  overflow: hidden;
  @media ${device.mobileS} {
    font-size: 12px;
    margin: 18px 0px 18px 0px;
    height:61px;
    width:100%;
    display:flex;
  }
  @media ${device.tablet} {
    font-size: 16px;
    padding: 5px;
    width: 520px;
    height:53px;
  }
  @media ${device.laptopL} {
    font-size: 18px;
    height:66px;
    width: 700px;
    padding:0px;
  }
  
`;
export const FilterList = styled.ul`
  width:100%;
  @media ${device.laptopL} {
    margin-top: 40px;
  }
`;
export const FilterItem = styled.li`
  display: flex;
  
  margin-bottom: 16px;
  @media ${device.mobileS} {
    flex-direction:column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  @media ${device.tablet} {
    flex-direction:row;
    margin-bottom: 10px;
  }
 
`;
export const FilterItemHead = styled.p`
  flex-direction:column;
  margin-top:10px;
  align-items: flex-start;
  min-width:100px;
  font-family: Montserrat-Bold;
  font-size: 10px;
  text-transform: uppercase;
  margin-right: 5px;
  color: ${(props) => props.category};
  color: ${(props) => props.claims};
  color: ${(props) => props.certifications};
  @media ${device.mobileS} {
    margin-bottom:6px;
  }
`;
export const FilterItemList = styled.ul`
  display: flex;
  flex-wrap:wrap;
  @media ${device.laptop} {
    flex-wrap:wrap;
  }
`;
export const Filter = styled.li`
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;
  border: solid 1px;
  border-color: ${(props) => props.category};
  border-color: ${(props) => props.claims};
  border-color: ${(props) => props.certifications};
  padding: 10px 17px 10px 17px;
  border-radius: 20px;
  margin-right: 10px;
  color: ${(props) => props.category};
  color: ${(props) => props.claims};
  color: ${(props) => props.certifications};
  @media ${device.mobileS} {
    font-size: 11px;
    margin-bottom:8px;
    padding:5px 10px 5px 10px;
  }
  @media ${device.laptop} {
    padding:7px;
    font-size:14px;
    margin-bottom:8px;
  }
  
`;
