import React, { useState } from "react";
import styled from "styled-components";
import {
  HeaderContainer,
  Wrapper,
  Container,
  Section,
  ContentContainer,
  Content,
  RegistosTitle,
  RegistosText,
} from "../commons/commonsStyled/index";
import {
  Label,
  Row,
  Input,
  ContentFormButtom,
  Circle,
  ContentFormButtonText,
  Textarea,
  InputButton,
  LabelButton,
  CastomInput,
  RowCastom,
  LabelCastomTel,
  LabelCastomWeb
} from "../commons/Form/styledForm";

import plusSign from "../../img/pluswhite.png";
import Navigation from "../commons/Navigation";
import Logo from "./../commons/Logo";
import Footer from "../commons/Footer";
import { Br, device } from "./../commons/commonsStyled/index";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ChangePassword from "../commons/ChangePassword";

const Registos = () => {
  let isNewRegisterAvailable = true;

  let comapniesList = useSelector((root) => root.user.user.companies);

  // if (comapniesList && comapniesList.find(c => c.isDraft == true && c.status == 0)) {
  if (comapniesList.length > 0) {
    isNewRegisterAvailable = false;
    var chosenCompany  = comapniesList[comapniesList.length-1];
  }




  window.scrollTo({
    //top:0,
  });

  return (
    <>
      <Wrapper>
        <Section>
          <Container>
            <HeaderContainer>
              <Logo />
              <Navigation />
            </HeaderContainer>
          </Container>
          <ContentContainer>
            <RegistosTitle>Registos</RegistosTitle>
            <RegistosText>
              Pode consultar aqui o estado do registo da sua empresa
            </RegistosText>
            <Br />
          </ContentContainer>
        </Section>
        <ContentContainer>
          <Content>
            {
              (isNewRegisterAvailable)
                ? (
                  <AddCompanyButton to="/registos/add-company">
                    <span style={{ marginLeft: "20px" }}>ADICIONAR EMPRESA</span>
                    <Circle2 >
                      <img src={plusSign} alt="circleImg" />
                    </Circle2>
                  </AddCompanyButton>
                )
                : null
            }
            <TableContainer>
              <TableTitle style={{ background: "#FADBD3", padding: "20px", paddingLeft: "10px" }}>Rascunho</TableTitle>
              <Br />
              <Table>
                <tbody>
                  <Tr>
                    <Td width="30%">Nome da Empresa</Td>
                    <Td width="10%"></Td>
                    <Td width="10%"></Td>
                    <Td width="20%"></Td>
                    <Td width="20%"></Td>
                  </Tr>
                </tbody>
              </Table>
              <Br />
              <Table>
                <tbody>
                  {comapniesList.map(
                    (item) => (
                      item.isDraft === true && item == chosenCompany ?
                        <Tr>
                          <Td width="30%">{item.business_name}</Td>
                          <Td width="10%"></Td>
                          <Td width="10%"></Td>
                          <Td width="20%"></Td>
                          <th width="20%">
                            <Link to={{ pathname: '/registos/add-company', query: { companyId: item.business_name } }}><Button color="#1a9fc0">EDITAR</Button></Link>
                          </th>
                        </Tr>
                        : <></>
                    )
                  )}
                </tbody>
              </Table>


              <TableTitle style={{ background: "#C6F1F3", padding: "20px", paddingLeft: "10px" }}>Aprovado</TableTitle>
              <Br />
              <Table>
                <thead></thead>
                <tbody>
                  <Tr>
                    <Td width="30%">Nome da Empresa</Td>
                    <Td width="10%"></Td>
                    <Td width="10%"></Td>
                    <Td width="20%"></Td>
                    <Td width="20%"></Td>
                  </Tr>
                </tbody>
              </Table>
              <Br />
              <Table>
                <tbody>
                  {comapniesList.map(
                    (item) => (
                      item.status === 1 && item == chosenCompany  ?
                        <Tr>
                          <Td width="30%"><h2>{item.business_name}</h2></Td>
                          <Td width="10%"></Td>
                          <Td width="10%"></Td>
                          <Td width="20%"></Td>
                          <th width="20%">

                          </th>
                        </Tr>
                        : <></>
                    )
                  )}
                </tbody>
              </Table>


              <TableTitle style={{ background: "#FAF0D3", padding: "20px", paddingLeft: "10px" }}>Pendente</TableTitle>
              <Br />
              <Table>
                <tbody>
                  <Tr>
                    <Td width="30%">Nome da Empresa</Td>
                    <Td width="15%"></Td>
                    <Td width="15%"></Td>
                    <Td width="20%"></Td>
                    <Td width="20%"></Td>
                  </Tr>
                </tbody>
              </Table>
              <Br />
              <Table>
                <tbody>
                  {comapniesList.map(
                    (item) => (
                      item.status === 0 && item.isDraft === false && item == chosenCompany  ?
                        <Tr>
                          <Td width="30%"><h2>{item.business_name}</h2></Td>
                          <Td width="15%"></Td>
                          <Td width="15%"></Td>
                          <Td width="20%"></Td>
                          <th width="20%">

                          </th>
                        </Tr>
                        : <></>
                    )
                  )}
                </tbody>
              </Table>
            </TableContainer>
            <ChangePassword />

          </Content>
        </ContentContainer>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Registos;

export const TableContainer = styled.div`
  margin-bottom: 100px;
`;
export const TableTitle = styled.h1`
  margin-bottom: 20px;
  font-family: Montserrat-Regular;
  @media ${device.mobileS} {
    font-size: 16px;
  }
  @media ${device.tablet} {
    font-size: 20px;
  }
  @media ${device.laptop} {
    font-size: 22px;
  }
  
`;
export const Table = styled.table`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-spacing: 0px 20px;
`;
export const Tr = styled.tr`
  width: 100%;
`;
export const Td = styled.td`
  width: ${(props) => props.width};
  color: #858585;
  font-size: 16px;
  @media ${device.mobileS} {
    font-size: 9px;
  }
  @media ${device.mobileL} {
    font-size: 10px;
    width: 100px;
  }
  @media ${device.tablet} {
    font-size: 13px;
  }
  
  @media ${device.laptop} {
    font-size: 15px;
  }
  
  
`;

export const Button = styled.button`
  width: 200px;
  height: 54px;
  border-radius: 50px;
  border: none;
  font-family: Montserrat-Bold;
  font-size: 20px;
  background-color: ${(props) => props.color};
  color: #ffffff;
  @media ${device.mobileS} {
    width: 60px;
    height: 25px;
    font-size: 9px;
  }
  @media ${device.mobileL} {
    width: 70px;
    height: 30px;
    font-size: 11px;
  }
  
  @media ${device.tablet} {
    width: 120px;
    height: 34px;
    font-size: 14px;
  }
  @media ${device.laptop} {
    width: 170px;
    height: 44px;
    font-size: 18px;
  }
  
`;

export const AddCompanyButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Montserrat-Bold;
  width: 400px;
  height: 60px;
  object-fit: contain;
  border-radius: 29px;
  background-color: #20babf;
  font-size: 19px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 50px;
  position:relative;
  @media ${device.mobileS} {
    width: 280px;
    height: 64px;
    font-size: 13px;
  }
  @media ${device.tablet} {
    width: 300px;
    height: 64x;
    font-size: 18px;
  }
  @media ${device.laptop} {
    width: 300px;
    height: 64px;
    font-size: 18px;
  }
  
`;

export const Circle2 = styled.div`
display: flex;
justify-content: center;
align-items: center;
  width: ${(props) => (props.login ? "55px" : "64px")};
  height: ${(props) => (props.login ? "55px" : "64px")};
  background-color: #309da1;
  border-radius: 50%;
  position: absolute;
  right: 0px;
`;