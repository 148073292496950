import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Home from "./components/pages/Home/Home";
import Registos from "./components/auth/Registos";
import About from './components/pages/About';
import Directory from './components/pages/Directory';
import Contact from './components/pages/Contact';
import Login from './components/pages/Login';
import Detail from './components/pages/Detail';
import Terms from './components/pages/Terms';
import Privacy from './components/pages/Privacy';
import AddCompany from './components/auth/addCompany/AddCompany';
import ScrollToTop from "./ScrollToTop";
import SimpleReactLightbox from 'simple-react-lightbox';
import ContactJoinUs from "./components/pages/ContactJoinUs";
import Success from "./components/pages/Success";
import RecoverPassword from "./components/pages/RecoverPassword";


const App = () => {
  const isAuth = useSelector((root) => root.auth.isLoggedIn);
  if (isAuth) {
    return (
      <>
        <SimpleReactLightbox>
          <ScrollToTop />
          <Switch>
          <Route path="/register" component={ContactJoinUs} />
            <Route path="/terms-of-service" component={Terms} />
            <Route path="/privacy-policy" component={Privacy} />
            <Route path="/recover" exact component={RecoverPassword} />
            <Route path="/directory" exact component={Directory} />
            <Route path="/directory/:id" component={Detail} />
            <Route path="/contact" component={Contact} />
            <Route path="/success" component={Success} />
            <Route path="/registos"  exact  component={Registos} />
            <Route path="/registos/add-company"  component={AddCompany} />
            <Route path="/" exact component={Home} />
            <Redirect to="/registos"/>
          </Switch>
        </SimpleReactLightbox>
      </>
    );
  } else {
    return (
      <div> 
        <SimpleReactLightbox>
          <ScrollToTop />
          <Switch>
          <Route path="/register" component={ContactJoinUs} />
            <Route path="/" exact component={Home} />
            <Route path="/recover" exact component={RecoverPassword} />
            <Route path="/terms-of-service" component={Terms} />
            <Route path="/privacy-policy" component={Privacy} />
            <Route path="/directory" exact component={Directory} />
            <Route path="/directory/:id" component={Detail} />
            <Route path="/contact" component={Contact} />
            <Route path="/success" component={Success} />
            <Route path="/login" component={Login} /> 
            <Redirect to="/"/>
          </Switch>
        </SimpleReactLightbox>
      </div>
    );
  }
};

export default App;
