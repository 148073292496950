import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import styled from "styled-components";
import save from "../../../img/save.png";
import submit from "../../../img/submit.png";
import arrayMutators from "final-form-arrays";
import { device } from "./../../commons/commonsStyled/index";
import { ErrorSharp } from "@material-ui/icons";
import { useSelector } from "react-redux";
import Loading from "../../commons/Loading";

//**VALIDATION METHODS */
function ValidateEmail(mail) {
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail)) {
    return (true)
  }
  return (false)
}

function ValidateYoutube(link) {
  var re = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=([^&]+)/m;
  var re2 = /^(https|http):\/\/(?:www\.)?youtube.com\/embed\/[A-z0-9]+/m;
  if (re.test(link) || re2.test(link)) {
    return (true)
  }
  return (false)
}

function ValidURL(str) {
  var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  if (!regex.test(str)) {
    return false;
  } else {
    return true;
  }
}

function ValidNumber(str) {
  if (/^-?\d+$/.test(str) && str.length == 9) {
    return true;
  } else {
    return false;
  }
}

function objToString(targerObj){
  let fstrin= "O formulário não se encontra devidamente preenchido reveja:";
  for (var prop in targerObj) {
    //fstrin+= " "+prop.replace("_", " ")+" /";
    fstrin+= "<br />"+targerObj[prop];
  }
  return fstrin;
}

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      pageForther: 0,
      values: props.initialValues || {},
      showLogMessage: false,
      showFormErrorMessage: false,
      showLoading: false,
      errors: {}
    };
  }

  next = (values) => {
    this.setState((state) => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      pageForther: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }));
  }

  previous = () => {
    this.setState((state) => ({
      page: Math.max(state.page - 1, 0),
    }));
  }

  updateStateProperty = (property, value) => {
    const state = this.state;
    state[property] = value;
    this.setState(state);
  }

  updateShowLogMessage = (show) => this.setState({ showLogMessage: show });


  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };



  handleSubmit = (values) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;

    if (isLastPage || values['submit'] == 1) {
      return onSubmit(values);
    } else {
      this.next(values);
    }
  };

  handleSubmit2 = (values) => {
    const { children, onSubmit } = this.props;
    return onSubmit(values);
  }

  render() {
    let company = this.props.company;
    console.log("do Wizard", company);
    const { children } = this.props;
    const { page, pageForther, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;


    return (
      <>
        {/** AQUI VOU POR A CENA COM AS BOLINHAS */}
        <GuideBox>
          <h3 style={{ color: "#20BABF" }}>Passo para completar<br />o formulário de registo</h3>
          <StepHolder style={page == 0 ? { borderColor: "#000000" } : { borderColor: "#20BABF" }} >
            <NumberHolder style={page == 0 ? { color: "#000000" } : { color: "#20BABF" }}>1.</NumberHolder>
          </StepHolder>
          <StepHolder style={page == 1 ? { borderColor: "#000000" } : pageForther > 1 ? { borderColor: "#20BABF" } : { borderColor: "#AAAAAA" }}>
            <NumberHolder style={page == 1 ? { color: "#000000" } : pageForther > 1 ? { color: "#20BABF" } : { color: "#AAAAAA" }}>2.</NumberHolder>
          </StepHolder>
          <StepHolder style={page == 2 ? { borderColor: "#000000" } : pageForther > 2 ? { borderColor: "#20BABF" } : { borderColor: "#AAAAAA" }}>
            <NumberHolder style={page == 2 ? { color: "#000000" } : pageForther > 2 ? { color: "#20BABF" } : { color: "#AAAAAA" }}>3.</NumberHolder>
          </StepHolder>
          <StepHolder style={page == 3 ? { borderColor: "#000000" } : pageForther > 3 ? { borderColor: "#20BABF" } : { borderColor: "#AAAAAA" }}>
            <NumberHolder style={page == 3 ? { color: "#000000" } : pageForther > 3 ? { color: "#20BABF" } : { color: "#AAAAAA" }}>4.</NumberHolder>
          </StepHolder>
          <StepHolder style={page == 4 ? { borderColor: "#000000" } : pageForther > 4 ? { borderColor: "#20BABF" } : { borderColor: "#AAAAAA" }}>
            <NumberHolder style={page == 4 ? { color: "#000000" } : pageForther > 4 ? { color: "#20BABF" } : { color: "#AAAAAA" }}>5.</NumberHolder>
          </StepHolder>
          <StepHolder style={page == 5 ? { borderColor: "#000000" } : pageForther > 5 ? { borderColor: "#20BABF" } : { borderColor: "#AAAAAA" }}>
            <NumberHolder style={page == 5 ? { color: "#000000" } : pageForther > 5 ? { color: "#20BABF" } : { color: "#AAAAAA" }}>6.</NumberHolder>
          </StepHolder>
        </GuideBox>
        <Form
          // initialValues={values}
          // validate={this.validate}
          onSubmit={this.handleSubmit}
          mutators={{
            ...arrayMutators,
          }}
          validate={values => {
            const errors = {}

            console.log(values['submit'])
            //pag1
            if (values['submit'] !== 1) {

              if (page == 0) {
                if (!values.business_name) {
                  errors.business_name = ' Campo Obrigatório'
                }
                if (!values.logo_url) {
                  errors.logo_url = ' Campo Obrigatório'
                }

                if (!values.description) {
                  errors.description = ' Campo Obrigatório'
                }
                if (values.description ?
                  values.description.length > 1000 ?
                    true : false : false) {
                  errors.description = ' Não deve ultrapassar os 1000 caracteres'
                }


                if (!values.website_url) {
                  errors.website_url = ' Campo Obrigatório'
                } else if (!values.website_url.includes("http://") && !values.website_url.includes("https://")) {
                  errors.website_url = 'Comece com http:// ou https://';
                }
                else if (!(ValidURL(values.website_url))) {
                  errors.website_url = 'Este URL é inválido'
                }


                if (!values.products_image_url) {
                  errors.products_image_url = ' Campo Obrigatório'
                }
                if (!values.company_phone) {
                  errors.company_phone = ' Campo Obrigatório'
                } else if (values.company_phone.length > 9) {
                  errors.company_phone = 'Máximo de 9 dígitos'
                }
                else if (!ValidNumber(values.company_phone)) {
                  errors.company_phone = ' Formato inválido'
                }

                if (!values.company_email) {
                  errors.company_email = ' Campo Obrigatório'
                }
                if (values.company_email ?
                  !ValidateEmail(values.company_email) ?
                    true : false : false) {
                  errors.company_email = 'Esse formato de email é inválido'
                }

                if (values.video_url) {
                  if (!ValidateYoutube(values.video_url)) {
                    errors.video_url = 'Este youtube URL é inválido'
                  }
                }

              }
              if (page == 1) {
                //Page 2 
                if (!values.categories || values.categories.length === 0) {
                  errors.categories = ' Campo Obrigatório'
                }

                if (!values.claims || values.claims.length === 0) {
                  errors.claims = ' Campo Obrigatório'
                }
              }
              //Needs work
              if (page == 2) {
                if (!values.brands || values.brands.length === 0) {
                  errors.brands = ' Campo Obrigatório'
                }
                // else{
                //   values.brands.forEach(element => {
                //     if(element.name || element.picture_url){
                //       if(!errors.brands){
                //         errors.brands = 'Não pode ter valores vazios'
                //       }
                //     }
                //   });
                // }


                if (values.private_label == undefined || values.private_label == null) {

                  errors.private_label = ' Campo Obrigatório'
                }

              }

              if (page == 3) {
                /* if (!values.main_markets) {
                   errors.main_markets = ' Campo Obrigatório'
                 }*/
              }

              if (page == 4) {
                if (!values.products_description) {
                  errors.products_description = ' Campo Obrigatório'
                }

                if (values.products_description ?
                  values.products_description.length > 1000 ?
                    true : false : false) {
                  errors.products_description = ' Não deve ultrapassar os 1000 caracteres'
                }

                if (!values.productImg) {
                  errors.productImg = ' Campo Obrigatório'
                }

                if (!values.products) {
                  errors.ProductsError = "O campo não pode ser vazio";
                }

                //  if (values.products) {
                //    Object.keys(values.products).forEach(key => {
                //      const element = values.products[key];
                //    element.forEach(e => {
                //      if (!e.name || e.name == "" || !e.storageType || e.storageType == "" || !e.shelfLife || e.shelfLife == "") {
                //        errors.ProductsError = "Deve preencher os campos pedidos";
                //       }
                //      console.log(e);
                //     })
                //  });
                // }

                // if (values.products && values.categories) {
                //   const result = values.categories.every(cat => {
                //     const subcategories = cat.subcategories;

                //     return subcategories.every(subCat => (subCat.id in values.products) && values.products[subCat.id] && values.products[subCat.id].length > 0);
                //   });


                //   if (result) {
                //     console.log('PUMBA - OK');
                //   } else {
                //     errors.ProductsError = "Deve adicionar pelo menos um produto a cada sub-categoria";
                //   }
                // }


              }
              if (page == 5) {
                if (values.contact_email) {
                  if (!ValidateEmail(values.contact_email)) {
                    errors.contact_email = 'Esse formato de email é inválido'
                  }
                }
              }

            }


            //return {} //Just to omit errors dureing testing
            return errors
          }}
          initialValues={

            company
              ?
              company
              :
              {
                // business_name:"",
                // logo_url:"",
                // description:"",
                // products_image_url:"",
                // website_url:"",
                // company_phone:"",
                // company_email:"",
                // categories:[],
                // claims:[],
                // brands:[],
                // certifications:[],
                // private_label:"0",
                // productImg:[],
                // products:[]
                video_url: null,

                distinction_1: null,
                distinction_2: null,
                distinction_3: null,
                distinction_4: null,
                distinction_5: null,
                production_capacity: null,
                main_markets: null,
                main_sales_channels: null,
                products_description: null,
                // productImg:[],
                // products:[]
                contact_first_name: null,
                contact_last_name: null,
                contact_role: null,
                contact_email: null,
                contact_phone: null
              }
          }
        >
          {({
            handleSubmit,
            values,
            submitting,
            form: {
              mutators: { push, pop },
            },
          }) => (

            <form name="form" onSubmit={handleSubmit}>
              {activePage}
              <Buttons>
                {page > 0 && (
                  <ButtonLabelBack type="button" onClick={this.previous}>
                    <PageBack>{page}</PageBack>
                    <ButtonText>PASSO ANTERIOR</ButtonText>
                  </ButtonLabelBack>
                )}
                <ButtonSave type="button" onClick={(e) => { values['submit'] = 1; this.handleSubmit2(values); }}>
                  <ButtonImg src={save} alt="save" />
                SALVAR RASCUNHO
              </ButtonSave>
                {!isLastPage && (
                  <ButtonLabelNext type="button" onClick={(e) => { this.setState({ page: page + 1 }); }}>
                    <ButtonText>PRÓXIMO PASSO</ButtonText>
                    <PageNext>{page + 2}</PageNext>
                  </ButtonLabelNext>
                )}
                {isLastPage && (
                  <ButtonSubmit type="submit" disabled={submitting} onClick={() => { values['submit'] = 0; }}>
                    <ButtonText>SUBMETER</ButtonText>
                    <PageSubmit>
                      <ButtonImgSubmit src={submit} alt="submit" />
                    </PageSubmit>
                  </ButtonSubmit>
                )}
              </Buttons>
              {(this.state.showLogMessage) ? <SucessMessage>Rascunho guardado com sucesso.</SucessMessage> : null}
              {(this.state.showFormErrorMessage) ? <ErrorMessage dangerouslySetInnerHTML={{__html : objToString(this.state.errors)}}></ErrorMessage> : null}
              {(submitting || this.state.showLoading) ? <Loading loading={true} loadingState={2} loadMessage={"A enviar formulário..."}></Loading> : <></>}
            </form>
          )}
        </Form>
      </>
    );
  }
}

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 55px;
  @media ${device.mobileS} {
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-flow: wrap;
  }
`;
export const ButtonSave = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 348px;
  height: 70px;
  border: none;
  border-radius: 36px;
  background-color: #c8bca7;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  font-family: Montserrat-Bold;
  margin: 0 223px 0 223px;
  padding: 0;
  outline: none;

  &:active {
    box-shadow: inset 5px 5px 30px -12px #000000;
  }
  
  @media ${device.mobileS} {
    width: 130px;
    font-size: 9px;
    height: 30px;
    order: 3;
    margin: 10px 5px 0 5px;
  }
  @media ${device.mobileL} {
    width: 170px;
    font-size: 10px;
    height: 30px;
    order: 3;
    margin-top: 10px;
    margin: 10px 5px 0 5px;
  }
  @media ${device.tablet} {
    width: 200px;
    height: 40px;
    order: 2;
    margin: 0 5px 0 5px;
    font-size: 10px;
  }
  @media ${device.laptop} {
    width: 248px;
    height: 50px;
    margin: 0 13px 0 13px;
    font-size: 12px;
  }
  @media ${device.laptopL} {
    width: 348px;
    height: 70px;
    margin: 0 23px 0 23px;
    font-size: 16px;
  }
`;

export const ButtonImg = styled.img`
  margin-right: 13px;
  @media ${device.mobileS} {
    max-width: 10px;
    max-height: 10px;
  }
  @media ${device.tablet} {
    max-width: 20px;
    max-height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media ${device.laptopL} {
    height: 70px;
    max-width: 30px;
    max-height: 30px;
  }
`;
export const ButtonImgSubmit = styled(ButtonImg)`
  @media ${device.mobileS} {
    max-width: 15px;
    max-height: 15px;
    margin-right: 0px;
  }
  @media ${device.tablet} {
    max-width: 16px;
    max-height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media ${device.laptopL} {
    max-width: 26px;
    max-height: 30px;
  }
`;

export const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 278px;
  /* @media ${device.mobileS} {   
    font-size: 9px;

  } */
`;
export const ButtonLabelBack = styled(ButtonSave)`
  background-color: rgba(32, 158, 191, 0.61);
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  @media ${device.mobileS} {
    order: 1;
  }
`;
export const ButtonLabelNext = styled(ButtonLabelBack)`
  justify-content: flex-end;
  @media ${device.mobileS} {
    order: 2;
  }
`;

export const PageNext = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  width: 70px;
  height: 70px;
  border-radius: 36px;
  background-color: #209ebf;
  @media ${device.mobileS} {
    height: 30px;
    font-size: 20px;
  }
  @media ${device.tablet} {
    height: 40px;

    border-radius: 50%;
  }
  @media ${device.laptop} {
    height: 50px;
    font-size: 30px;
    border-radius: 50%;
  }
  @media ${device.laptopL} {
    height: 70px;
    font-size: 40px;
  }
`;
export const PageBack = styled(PageNext)`
  left: 0;
`;
export const ButtonSubmit = styled(ButtonLabelNext)`
  background-color: #20babf;
`;

export const PageSubmit = styled(PageNext)`
  background-color: #309da1;
`;

export const GuideBox = styled('div')`

    height:100px;
    background-color: #F5F5F5;
    position: relative;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom:60px;
`;

export const StepHolder = styled('div')`
  height:70px;
  width:70px;
  border-radius:50%;
  border: 4px solid #AAAAAA; 
  position: relative;
`;

export const NumberHolder = styled('div')`
position: absolute;
top: 50%; left: 50%;
transform: translate(-50%,-50%);
font-family: Montserrat-ExtraBold;
color:#20BABF;
font-size:30pt;
`;

// ------------
// Added By T.C
// ------------

export const SucessMessage = styled.h1`
  font-size: 18px;
  font-family: Montserrat-Bold;
  color: #9dbb9b;
  line-height: 0.96;
  margin-top: 25px;
  margin-left: 13px;
  // text-transform: capitalize;
`;

export const ErrorMessage = styled.h1`
  font-size: 18px;
  font-family: Montserrat-Bold;
  color: #E27F7F; //FF1600
  line-height: 0.96;
  margin-top: 25px;
  margin-left: 13px;
  // text-transform: capitalize;
`;
