import {FETCH_COMPANIES} from "./actionTypes";

const initialState = {
    companies:[]
};

export const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANIES:
      return {...state, companies: action.payload.data} // (action.payload.data) ? action.payload.data : []}
    default:
      return state;
  }
};