import React, { useState } from "react";
import styled from "styled-components";
import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import { Label, LabelText } from "./styled/commonStyled";
import downloadImg from "../../../img/download-img.png";
import close from "../../../img/close.svg";
import addfilter from "../../../img/addfilter.png";
import {
  InputFileContainer,
  DownloadImg,
  InputFileText,
  Strong,
  InputFileDesc,
  InputFile,
} from "./InformationAboutCompany";
import MyDropzone from "./MyDropzone";
import { Br, device } from './../../commons/commonsStyled/index';
import {
  ButtonImg,
  ButtonText,
  RefineSeachButton,
  Img
} from "../../pages/Directory";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from "@material-ui/core";

const options = ['ASC - Aquaculture Stewardship Council', 
                 'BRC Global Standard for Food Safety',
                 'Fairtrade International',
                 'FDA - U.S. Food and Drug Administration',
                 'FSSC 22000',
                 'GLOBALG.A.P.',
                 'Halal',
                 'IFS - International Featured Standards',
                 'ISO 14001',
                 'ISO 22000',
                 'ISO 9001',
                 'Kosher',
                 'MSC - Marine Stewardship Council',
                 'Non-GMO',
                 'Organic EU',
                 'Rainforest Alliance Certified',
                 'Sedex',
                 'SQF - Safe Quality Food',
                 'V-Label Vegan',
                 'V-Label Vegetarian'
                
                
                ];

let universalname =  {}//Passar para aqui




const AddCertificationsFrom = () => {
  const [state, setState] = useState([]);
  return (
    <>
      <FieldArray name="certifications">
        {({ fields }) => (
          <>
            {fields.map((name, index) => (
              <div key={name}>
                          <>
                        <Delete

                              onClick={() => {
                                fields.remove(index);
                              }}
                              style={{ cursor: "pointer", position:"static", marginLeft:"auto", marginTop:"25px" }}
                            >
                              <img src={close} alt="close" />
                         </Delete>
                      </>
                <LabelContainerBrand>
      
                  <Brand>
  
                    <Label>
                      <LabelText>Nome da Certificação</LabelText>
                      {/*<FieldInput
                        name={`${name}.name`}
                        component="input"
                        type="text"
                        onKeyUp={(event) => {
                          universalname[index] = event.target.value;
                          fields.update(index, { ...fields.value[index], name: event.target.value })
                        }}
                      />*/}
                       <Autocomplete
                        value={fields.value[index] ? fields.value[index].name : ''}
      
                       /* onKeyUp={(event) => {
                       // console.log("onChange ",event.target.value);
                        universalname[index] = event.target.value;
                        fields.update(index, { ...fields.value[index], name: event.target.value})
                        }}*/
                        //inputValue={inputValue}
                        onChange={(event, newInputValue) => {
                          //console.log(newInputValue);
                          universalname[index] = newInputValue;
                          fields.update(index, { ...fields.value[index], name: newInputValue})
                        }}
                      
                        onBlur={(ev) => {
                          //console.log(newInputValue);
                        
                          console.log(ev.target)
                          fields.update(index, { ...fields.value[index], name: ev.target.value})
                        }}

                        freeSolo
                        options={options}
                        style={{minWidth:"fit-content", width: "32vw" }}
                        renderInput={(params) => 
                        <CssTextField
                           {...params} 
                                       name={`${name}.name`}
                            />
                          }
                      />
                    </Label>
                  </Brand>
                  <BrandImg>
                    <LabelText>Upload de logotipo da Certificação</LabelText>

                    <InputFileContainer>
                      <InputFileBrand>
                        {(fields.value[index] && fields.value[index].picture_url != '') ? (
                          <>
                            <DownloadImg src={fields.value[index].picture_url} />
                            <Delete
                              onClick={() => {
                                // state.splice(index, 1);
                                //fields.remove(index);
                                console.log("delete",fields.value[index]);
                                fields.update(index, { name: 
                                  fields.value[index].name ? fields.value[index].name :
                                  index in universalname ? universalname[index] 
                                  :'', img: '', picture_url:''})
                                //fields.pop()
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={close} alt="close" />
                            </Delete>
                          </>
                        )
                          : (
                            <Label>
                              <div>
                                <img src={downloadImg} alt="download-img" />
                                <InputFileText>
                                  <Strong>JPEG/PNG</Strong>
                                  300 x 300 px
                              </InputFileText>
                                <Field name={`${name}.picture_url`} component="input">
                                  {(props) => (
                                    <MyDropzone
                                      validateSize={[300, 300]}
                                      // updateFieldsValues(fields, { img: marca.map((item) => item)
                                      sendImg={(marca) => {
                                        console.log("LOST",fields.value[index]);
                                        fields.update(index, { name:  fields.value[index].name ? fields.value[index].name :
                                          index in universalname ? universalname[index] 
                                          :'',
                                        
                                        img: marca.map((item) => item) })
                                      }}
                                      {...props.input}
                                    />
                                  )}
                                </Field>
                              </div>
                            </Label>
                          )}
                      </InputFileBrand>
                      <InputFileDesc>
                        Formato jpg ou png.
                        <br />
                        <br />
                        Dimensões:
                        <br />
                        300 x 300 px
                        <br />
                        <br />
                        Tamanho:
                        <br />
                        1mb
                      </InputFileDesc>
                    </InputFileContainer>
                  </BrandImg>

                </LabelContainerBrand>
                <Br />
              </div>
            ))}
            <ButtonBrand type="button"
              onClick={() => {
                // if (fields.value)
                //   fields.value.map((item) => console.log(item.picture_url.preview));
                // console.log(state);
                fields.push({ name: "", picture_url: "" });
              }}>
              <ButtonText>Adicionar Certificação</ButtonText>
              <ButtonImg>
                <Img src={addfilter} alt="addfilter" />
              </ButtonImg>
            </ButtonBrand>
            <Br />
          </>
        )}
      </FieldArray>
    </>
  );
};

export default AddCertificationsFrom;

export const FieldInput = styled(Field)`
  width: 536px;
  height: 54px;
  border-radius: 27px;
  border: solid 1px rgba(63, 66, 76, 0.3);
  background-color: #ffffff;
  outline: none;
  padding-left: 20px;
  @media ${device.mobileS} {
    width: 280px;
    height: 44px;
    border-radius: 22px;
  }
  @media ${device.laptop} {
    width: 348px;
    height: 44px;
    border-radius: 22px;
  }
  @media ${device.laptopL} {
    width: 548px;
    
  }
`;
export const LabelContainer = styled.div`
  display: flex;
`;
export const Brand = styled.div`
  margin-right: 94px;
  @media ${device.mobileS} {
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    margin-right: 44px;
  }
 
`;
export const Delete = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  position:absolute;
  width: 33px;
  height: 33px;
  background-color: #e27f7f;
  top: 0;
  right:0;

`;
export const BrandImg = styled.div``;
export const InputFileBrand = styled(InputFile)`
  position:relative;
  border-top-right-radius: 0px;
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  border-bottom-right-radius: 27px;
`;
export const LabelContainerBrand = styled(LabelContainer)`
  margin:58px 0 63px 0;
  @media ${device.mobileS} {
    display:flex;
    flex-direction:column;
  }
  @media ${device.tablet} {
    display:flex;
    flex-direction:row;
  }
`;
export const ButtonBrand = styled(RefineSeachButton)`
  margin:47px 0 47px 0;
`;
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#20babf"
    }
  }
})(TextField);