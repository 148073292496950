import React,{useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useDispatch ,useSelector} from "react-redux";
import { addFilterClaims } from "../../redux/actions";
import { addFilterPrivateLabel,deleteFilterPrivateLabel } from './../../redux/actions';
import {

  InputCastom,
  ItemLabel,
  List,
} from "../auth/addCompany/AddCategoryField";


const AddPrivateLabelFilter = ({
  input: { value, onChange, onBlur, onFocus },
  meta,
  calims,
}) => {
  const dispatch = useDispatch();
  const privateLabel = useSelector((root) => root.filterPrivateLabel);

  const handleChangeClaims = useCallback(
    (e) => {
      
      const newValue = [...value];
      let claim = {id: e.target.id,name:e.target.value}
     
      // let find = newClaims.some(element => element.id === claim.id)
      // if(!find) newValue.push(claim);
      if(e.target.checked) {
        newValue.push(claim);
        dispatch(addFilterPrivateLabel(claim))
      }
      if(!e.target.checked)  {
        let index = newValue.findIndex(element => element.id === claim.id);
        let elem = newValue.find(element => element.id === e.target.id);
        dispatch(deleteFilterPrivateLabel(e.target.id))
        newValue.splice(index,1)
      }
      onChange(newValue);
    },
    [onChange, value,deleteFilterPrivateLabel,addFilterPrivateLabel]
  );


  return (
    <>
      <List>
        {calims.map((item) => (
          <div key={item.id}>
            <InputCastom
              id={item.id}
              name="claims"
              value={item.value}
              type="checkbox"
              onChange={handleChangeClaims}
              checked={
                !!privateLabel?.some((element) => {
                  return element.id == item.id;
                })
              }
            />
            <ItemLabel htmlFor={item.id}>{item.name}</ItemLabel>
          </div>
        ))}
      </List>
    </>
  );
};

export default AddPrivateLabelFilter;
export const TitleModal = styled.p`
  font-size: 38px;
  color: #4e4e4e;
  margin: 60px 0 50px 0;
`;