import React from "react";
import styled from "styled-components";
import { ContentContainer } from "../commons/commonsStyled/index";
import Logo from "./Logo";
import algavre from '../../img/algavre.png'
import compete from '../../img/compete.png'
import europa from '../../img/europa.png'
import lisba from '../../img/lisba.png'
import portugal from '../../img/portugal.png'
import { Br, device } from './commonsStyled/index';
import { Link } from "react-router-dom";

const year = (new Date()).getFullYear();

const Footer = () => {
  return (
    <FooterContainer>
      <ContentContainer>
        <Br />
        <FooterTop>

          <LogoMediaRuller>
            <Logo />
          </LogoMediaRuller>

          <ContainerList style={{ marginTop: '25px' }}>
            Cofinanciado por:
            <List>
              <Item>
                <ItemImg src={compete} alt="compete" />
              </Item>
              <Item>
                <ItemImg src={portugal} alt="portugal" />
              </Item>
              <Item>
                <ItemImg src={algavre} alt="algavre" />
              </Item>
              <Item>
                <ItemImg src={lisba} alt="lisba" />
              </Item>
              <Item>
                <ItemImg src={europa} alt="europa" />
              </Item>
            </List>
          </ContainerList>

        </FooterTop>
      </ContentContainer>
      <FooterBottom>
        <FooterBottomList>
          <Text>Copyright {year} -&nbsp;<TextBold><a style={{ textDecoration: 'none !important', color: "black" }} href="https://www.portugalfoods.org/" target="_Blank">PortugalFoods</a></TextBold></Text>
          <MyLink end="true" to="/terms-of-service">Terms of Service</MyLink>
          <MyLink to="/privacy-policy">Privacy Policy</MyLink>
        </FooterBottomList>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;

export const LogoMediaRuller = styled.div`
@media ${device.mobileS} { 
  display:none;
}
@media ${device.laptopL} { 
  display:block;
}

`;

export const ContainerList = styled.div`
  font-size:50%

  @media ${device.tablet} { 
    margin-top:20px;
    margin-left:10px;
  }
  @media ${device.laptop} { 
    justify-content: space-between;
    align-items: flex-start;
    margin-left:0px;
  }
  @media ${device.laptopL} { 
    justify-content: space-between;
    align-items: flex-start;
    margin-left:8vw;
  }


`;
export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  margin-top: 126px;
  @media ${device.mobileL} {
    height:auto;
  }
  @media ${device.tablet} { 
    display: flex-root;
    height:auto;
  }
  
`;

export const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 27px;
  margin-bottom: 28px;
  @media ${device.tableS} { 
    flex-direction:column;
    align-items:flex-start;
  }
  @media ${device.laptop} { 
    display: flow-root;
    flex-direction:row;
    align-items:center;
  }
  @media ${device.laptopL} { 
    display: flex;
    flex-direction:row;
    align-items:center;
  }


`;
export const FooterBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f6f6f6;
  height: auto;
  @media ${device.mobileS} {
    height:auto;
    padding: 20px 0px 0px 0px;
  }
  @media ${device.tablet} {
    height: auto;
    padding: 20px 0px 20px 0px;
  }
  

`;
export const FooterBottomList = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  @media ${device.mobileS} {
    flex-direction:column;
    align-items:flex-start;
  }
  @media ${device.tablet} {
    flex-direction:row;
    align-items: center;
  }
  
`;
const Comp = (props) => {
  return <div {...props}></div>
}
export const Text = styled(Comp)`
  display:flex;
  align-items: start;
  font-family:Montserrat-Regular;
  color: #4a4a4a;
  font-size:14px;
  
  @media ${device.mobileS} {
    margin-left:0px;
    margin-bottom:16px;
    font-size:12px;
  }
  @media ${device.tablet} {
    margin-left:${props => props.end ? 'auto' : '5vw'};
    margin-right:${props => props.end ? '35px' : '0px'};
    padding-right:10vw;
    margin-bottom:0px;
    font-size:12px;
  }

`;
export const MyLink = styled(Link)`
  display:flex;
  align-items: start;
  font-family:Montserrat-Regular;
  color: #4a4a4a;
  font-size:14px;
  @media ${device.mobileS} {
    margin-left:0px;
    margin-bottom:16px;
    font-size:12px;
  }
  @media ${device.tablet} {
    margin-left:${props => props.end ? 'auto' : '0px'};
    margin-right:${props => props.end ? '35px' : '5vw'};
    margin-bottom:0px;
    font-size:12px;
  }

`;
export const TextBold = styled(Text)`
  font-family: Montserrat-Bold;
  margin-left:0px !important;
`;
export const List = styled.ul`
  display:flex;
  margin-top:10px;
  @media ${device.mobileS} {
    
    flex-direction:column;
    justify-content: center;
    img{
      width:25vw;
      height:auto;
    }
 
  }
  @media ${device.tablet} {
    flex-direction:row;
    flex-wrap: nowrap;
    img{
      height:auto;
      width:100%;
      
    }
  }

  @media ${device.laptopL} { 
    
    flex-wrap: nowrap;
    justify-content: flex-start ;
    img{
      height:auto;
      width:auto;
      
    }
  }


 
  
`;
export const Item = styled.li`
  display:flex;
  margin-right:30px;
  @media ${device.mobileS} {

 
    margin-right:0px;
    margin-bottom:30px;
  }
  @media ${device.mobileL} {


    margin-bottom:30px;
  }
  @media ${device.tablet} { 
    margin-right:10px;


  }
  @media ${device.laptop} { 
    margin-right:10px;


  }
 
  @media ${device.laptopL} { 
    margin-right:10px;

    width: auto;
  }
  
  
  
`;
export const ItemImg = styled.img`
  @media ${device.laptop} { 
   width:100%;
   margin-right:20px;
   
  }
  @media ${device.laptopL} { 
    width:100%;
    margin-right:20px;
  }
  @media ${device.mobileS} {
    width:100%;
    
  }
`;
