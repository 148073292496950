import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const acceptUploadImages = (acceptedFiles, props, files) => {
  props.sendImg(files.map(file => file.preview))

  let reader = new FileReader();
  reader.onload = function (event) {
    let base64 = event.target.result;
    // console.log(base64)
    props.onChange(base64);
  };

  // console.log(generalPhotos)
  if (acceptedFiles && acceptedFiles.length > 0) {
    reader.readAsDataURL(acceptedFiles[0]);
  }
};

const MyDropzone = (props) => {
  const [generalPhotos, setGeneralPhotos] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    const files = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    if (props.validateSize && props.validateSize.length == 2) {
      // entao devemos validar o tamanho da imagem
      const maxWidth = props.validateSize[0];
      const maxheight = props.validateSize[1];
      const _URL = window.URL;
      const img = new Image();

      files.forEach(file => {
        const objectUrl = _URL.createObjectURL(file);

        img.onload = function () {
          if(file.size > 1000000){
            alert("Tamanho máximo do ficheiro é 1mb");
          }
          else if (this.width != maxWidth || this.height != maxheight) {
            alert("Dimensões da imagem inválidas");
          } 
          else {
            acceptUploadImages(acceptedFiles, props, files);
          }
          _URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl;
      });
    } else {
      // senao fazer o normal
      acceptUploadImages(acceptedFiles, props, files);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} name={props.myName} id={props.id} />
    </div>
  );
};
export default MyDropzone;
// function MyDropzone(props) {
//   const [files, setFiles] = useState([]);
//   const [generalPhotos,setGeneralPhotos] = useState("")
//   const { getRootProps, getInputProps } = useDropzone({
//     accept: "image/*",
//     multiple: false,
//     noDrag: true,
//     onDrop: acceptedFiles => {
//       const files = acceptedFiles.map(file =>
//         Object.assign(file, {
//           preview: URL.createObjectURL(file)
//         })
//       );
//       setFiles(files);
//       props.sendImg(files.map(file => file.preview))
//       console.log(files.map(file => file.preview))
//       let reader = new FileReader();
//       reader.onload =  function(event) {
//           let base64 =  event.target.result;
//           console.log(base64)
//           props.onChange(base64);
//       };
//       console.log(generalPhotos)
//       reader.readAsDataURL(acceptedFiles[0]);



//     }
//   });

  // const removeFile = file => () => {
  //   const newFiles = [...files];
  //   newFiles.splice(newFiles.indexOf(file), 1);
  //   setFiles(newFiles);
  // };

  // const thumbs = files.map(file => (
  //   <div style={thumb} key={file.name}>
  //     <div style={thumbInner}>
  //       <img src={file.preview} style={img} alt="" />
  //     </div>
  //     <button onClick={removeFile(file)}>Remove File</button>
  //   </div>
  // ));

//   useEffect(
//     () => () => {
//       // Make sure to revoke the data uris to avoid memory leaks
//       files.forEach(file => URL.revokeObjectURL(file.preview));
//     },
//     [files]
//   );

//   return (
//     <div >
//       <div {...getRootProps()}>
//         <input {...getInputProps()} />

//       </div>
//       {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
//     </div>
//   );
// }

// export default MyDropzone;
