import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProductTable from "./ProductTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  accordionMain: {
    marginTop:"37px"
  },
  accordion: {
    marginTop:"7px"
  },
  accordionFalse:{
    backgroundColor:"rgba(48, 157, 161, 0.49)"  
  },
  accordionTrue:{
    backgroundColor:"#20babf"  
  },
  p:{
    color:'#ffffff'
  }
}));

export default function ControlledAccordions(props={}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel0");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const company = props.company || {categories:[], products:[]};
  const companyCategory = props.companyCategory|| {};
  


  console.log(companyCategory);

  return (
    <div className={classes.root}>
      
      {
          companyCategory.subcategoty.map(
            (elm,iterator) => {
                return(
                <>
                  <Accordion
                    className={classes.accordion}
                    expanded={expanded === "panel" + iterator}
                    onChange={handleChange("panel" + iterator)}
                  >
                    <AccordionSummary
                      className={expanded === "panel" + iterator ? classes.accordionTrue : classes.accordionFalse}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography style={{fontFamily:"Montserrat-Bold" }} className={classes.p}>{elm.name} </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <ProductTable product={company.products.filter(el => el.sub_category_id === elm.id)} />
                    </AccordionDetails>
                  </Accordion>
                 </>
 
              )
            }
          ) }
                {console.log(company)}

    </div>
  );
}
