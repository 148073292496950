import React, { useState } from "react";
import styled from "styled-components";
import { AddCompanyButton, TableTitle } from "../auth/Registos";
import { CastomInput, Row, RowCastom } from "./Form/styledForm";
import Loading from "./Loading";

let posY = 0;

const ChangePassword = () => {

    window.scrollTo({
        top: posY,
      });

    let [password, setPassword] = useState("");
    let [confirmPassword, setConfirmPassword] = useState("");
    let [token, setToken] = useState("");
    let [email, setEmail] = useState("");

    let [wait, setWait] = useState(false);

    let [passo, setPasso] = useState(1);

    let [resultFrom, setResut] = useState("");

    let [errorMsg, setErrorMsg] = useState("");

    const incrementPasso = () => { setPasso(passo + 1) }

    const updateInputValue = (evt) => {
        setPassword(evt.target.value);
    }


    const doSubmit = () => {
        posY = window.pageYOffset;
        if (password.length > 5) {
            return true;
        }
        else {
            return false;
        }
    }
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;  //Remove on production
    //PEDIDOS E SUBMiTS
    const sendEmail = async (values) => {
        setWait(true);
        setErrorMsg("");
        posY = window.pageYOffset;
        try {
            let response = await fetch(apiBaseUrl + '/api/request-reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(values)
            });


            let result = await response.json();
        
            if (result.success) {
                setWait(false);
                setResut(result.success)
               
             
                incrementPasso();

                setTimeout(function () {

                }, 2000);
            }
            if (result.error  && result.error.length > 0){
                setWait(false);
                setErrorMsg(result.error[0])
            }

        } catch (error) {
            setErrorMsg("Error de servidor, por favor tente mais tarde");
            setWait(false);
        }
    }

    const sendToken = async (values) => {
        setWait(true);
        setErrorMsg("");
        posY = window.pageYOffset;
        try {
            let response = await fetch(apiBaseUrl + '/api/validate-reset-password-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(values)
            });

            let result = await response.json();
            if (result.success) {
                setWait(false);
                console.log(result);
                incrementPasso();
            }
            if ( result.error && result.error.length > 0) {
                setErrorMsg(result.error[0])
                setWait(false);
               
            }

        } catch (error) {
            setErrorMsg("Error de servidor, por favor tente mais tarde");
            setWait(false);
        }
    }

    const sendNewPassword = async (values) => {
        setWait(true);
        setErrorMsg("");
        posY = window.pageYOffset;
        try {
            let response = await fetch(apiBaseUrl + '/api/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(values)
            });

            let result = await response.json();
            if (result.success) {
                setWait(false);
                incrementPasso();

                setTimeout(function () {

                }, 2000);
            }
            if ( result.error && result.error.length > 0) {
                setErrorMsg(result.error[0])
                setWait(false);
               
            }

        } catch (error) {
            setErrorMsg("Error de servidor, por favor tente mais tarde");
            setWait(false);
        }


    }


    return (

        <>
        {errorMsg == "" ? null : <h3 style={{color:"red", marginBottom:"10px"}}>{errorMsg}</h3>}
        {wait==true?  <Loading></Loading> : null}
        <ParentPassChanger>
        <TableTitle style={{background: "#DFDFDF", padding: "20px", paddingLeft: "10px"}}>Alterar Password</TableTitle>
            {passo == 1 ?
                <>
                        <h2 style={{marginLeft:'10px'}}>Coloque o seu email</h2>
                        <RowCastom style={{ display: "inline-flex", alignItems: "baseline", justifyContent: "space-evenly", width: "100%"}}>
                        <Row>
                            <CastomInput name="email" type="email" placeholder="email" value={email} onChange={(e) => {   posY = window.pageYOffset;setEmail(e.target.value)}} />
                        </Row>
                        <Row>
                            <AddCompanyButton 
                                style={{justifyContent: "center"}}
                                onClick={() => { sendEmail({ email: email }) }}>
                                Confirmar
                              </AddCompanyButton>
                        </Row>

                    </RowCastom>
                </>
                :
                <></>
            }
            {passo == 2 ?

                <>

                    <h2 style={{marginLeft:'10px'}}>Coloque o token que recebeu pelo email</h2>
                    <RowCastom style={{ display: "inline-flex", alignItems: "baseline", justifyContent: "space-evenly", width: "100%"}}>

                        <Row>
                            <CastomInput name="token" type="password" placeholder="token" onChange={(e) => {   posY = window.pageYOffset;setToken(e.target.value)}} />
                        </Row>
                        <Row>
                            <AddCompanyButton  onClick={() => { sendToken({ token: token }) }}    style={{justifyContent: "center"}}>
                                Confirmar
                        </AddCompanyButton>
                        </Row>

                    </RowCastom> </>
                :
                <></>
            }
            {passo == 3 ?
                <>
                    <RowCastom style={{ display: "inline-flex", alignItems: "baseline", justifyContent: "space-evenly", width: "100%"}}>
                        <Row>
                            <CastomInput name="newPassword" type="password" placeholder="nova password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </Row>
                        <Row>
                            <AddCompanyButton key="Arandomone"  onClick={()=>{sendNewPassword({ token: token, password: password })}}    style={{justifyContent: "center"}}>
                                Alterar
                            </AddCompanyButton>
                        </Row>
                    </RowCastom>
                </>
                :
                <></>
            }
               {passo == 4 ?
                <>
                    <h4>A Password foi gravada com sucesso</h4>
                </>
                :
                <></>
            }
        </ParentPassChanger>
        </>

    );
}
export default ChangePassword;

export const ParentPassChanger = styled.div`
  background-color:#F8F8F8;

  
`;