import React, { useState, useCallback } from "react";
import Modal from "react-modal";
import { Field, useField } from "react-final-form";
import styled from "styled-components";
import addfilter from "../../../img/addfilter.png";
import { Br, RequiredCircle } from "../../commons/commonsStyled";
import AddClaimsField from './AddClaimsField';
import { useSelector } from 'react-redux';
import AddCategoryField from "./AddCategoryField";
import { device } from './../../commons/commonsStyled/index';

import {
  InputContainer,
  Label,
  LabelText,
  NynPage,
  Page,
  Title,
  Recommendation,
} from "./styled/commonStyled";
import { ContainerHelper, Example, ExampleButton, ExampleText, Helper, HelperLogo, HelperText, Strong } from "./InformationAboutCompany";
import helperImg from "../../../img/helper.png";
const root = document.getElementById("root");
const customStyles = {
  content: {
    width: "625px",
    height: "695px",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Error = ({ name }) => {
  const {
    meta: { touched, error },
  } = useField(name, { subscription: { touched: true, error: true } });
  return touched && error ? (
    <span style={{ color: "red" }}>{error}</span>
  ) : null;
};
// const category = [
//   {
//     id: 1,
//     name: "Овощи",
//     subcategory: [
//       {
//         id: 1,
//         name: "Капуста",
//       },
//       {
//         id: 2,
//         name: "Морковь",
//       },
//       {
//         id: 3,
//         name: "Салат",
//       },
//       {
//         id: 4,
//         name: "Помидор",
//       },
//       {
//         id: 5,
//         name: "Огурец",
//       },
//       {
//         id: 6,
//         name: "Перец",
//       },
//       {
//         id: 7,
//         name: "Баклажан",
//       },
//     ],
//   },
//   {
//     id: 2,
//     name: "Фрукты",
//     subcategory: [
//       {
//         id: 1,
//         name: "яблоки",
//       },
//       {
//         id: 2,
//         name: "бананы",
//       },
//       {
//         id: 3,
//         name: "груша",
//       },
//     ],
//   },
//   {
//     id: 3,
//     name: "Фрукты",
//     subcategory: [
//       {
//         id: 1,
//         name: "яблоки",
//       },
//       {
//         id: 2,
//         name: "бананы",
//       },
//       {
//         id: 3,
//         name: "груша",
//       },
//     ],
//   },
//   {
//     id: 4,
//     name: "Фрукты",
//     subcategory: [
//       {
//         id: 1,
//         name: "яблоки",
//       },
//       {
//         id: 2,
//         name: "бананы",
//       },
//       {
//         id: 3,
//         name: "груша",
//       },
//     ],
//   },
//   {
//     id: 5,
//     name: "Фрукты",
//     subcategory: [
//       {
//         id: 1,
//         name: "яблоки",
//       },
//       {
//         id: 2,
//         name: "бананы",
//       },
//       {
//         id: 3,
//         name: "груша",
//       },
//     ],
//   },
//   {
//     id: 6,
//     name: "Фрукты",
//     subcategory: [
//       {
//         id: 1,
//         name: "яблоки",
//       },
//       {
//         id: 2,
//         name: "бананы",
//       },
//       {
//         id: 3,
//         name: "груша",
//       },
//     ],
//   },
//   {
//     id: 7,
//     name: "Фрукты",
//     subcategory: [
//       {
//         id: 1,
//         name: "яблоки",
//       },
//       {
//         id: 2,
//         name: "бананы",
//       },
//       {
//         id: 3,
//         name: "груша",
//       },
//     ],
//   },
// ];

const value = {
  products: {
    cat1: [{ name: "", storageType: "Chilled", lifeTime: 1 }],
  },
};

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const categoriesInitialValue = [];
const claimsInitialValue = [];
const validateCategories = (value) =>
  value.length === 0 ? "Add Category!" : undefined;

// Modal.setAppElement("body");
const AddCategoryForm = () => {
  const claims = useSelector((root) => root.claims);
  const categories = useSelector((root) => root.categories ? root.categories.categories.data.data : []);
  // console.log(categories)
  window.scrollTo({
    top: 0,
  });
  return (
    <>
      <ContainerHelper>
        <Helper>
          <HelperText>
            Se tiver alguma dúvida no preenchimento deste formulário, por favor{" "}
            <br /> contacte a <Strong>Market Division</Strong> da{" "}
            <Strong>PortugalFoods</Strong> através do
            <Strong> 220 944 476</Strong>
          </HelperText>
          <HelperLogo>
            <img src={helperImg} alt="img-helper" />
          </HelperLogo>
        </Helper>
        <Br />
        <Example>
          <ExampleText>
            Clique para ver um exemplo de uma página de Empresa
          </ExampleText>
          <ExampleButton to="/directory/PortugalFoods" target="_blank">EXEMPLO PÁGINA DE EMPRESA</ExampleButton>
        </Example>
        <Br />
      </ContainerHelper>
      <Page>
        <Title>
          <NynPage>2. </NynPage>Categorias de Produto
        </Title>
        <InputContainer>
          <Label>
            <LabelText>Categorias de Produto <RequiredCircle style={{ display: "inline-block" }} /><Error name="categories" /></LabelText>
            <Recommendation>
              Indique as categorias de produto e respetivas subcategorias,
              <br /> que melhor representam os produtos comercializados pela sua
              empresa.
              <br />
              <RecommendationStrong>
                Pode escolher até 5 categorias de produto.
              </RecommendationStrong>
            </Recommendation>
          </Label>
          <Field
            initialValue={categoriesInitialValue}
            name="categories"
            categories={categories}
            component={AddCategoryField}
            validate={validateCategories(value)}
          />
        </InputContainer>
        <Br />


        <InputContainer>
          <Label>
            <LabelText>Claims<RequiredCircle style={{ display: "inline-block" }} /> <Error name="claims" /></LabelText>
            <Recommendation>
              Selecione as claims que melhor se adequam à sua empresa e aos
              produtos comercializados.
            </Recommendation>
          </Label>
          <Field
            initialValue={claimsInitialValue}
            name="claims"
            claims={claims.claims.data}
            component={AddClaimsField}
          />

        </InputContainer>
      </Page>
      <Br />
    </>
  );
};

export default AddCategoryForm;

export const FieldInput = styled(Field)``;

export const RecommendationStrong = styled.span`
  font-family: Montserrat-Bold;
  font-weight: bold;
  color: #556fab;
  @media ${device.mobileS} {
    font-size:11px;
  }
  @media ${device.tablet} {
    font-size:14px;
  }
`;
