

export const LOGIN_USER = "LOGIN_USER";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const SEND_FEEDBACK_ERROR = "SEND_FEEDBACK_ERROR";

export const FETCH_CLAIMS = "FETCH_CLAIMS";
export const FETCH_CERTIFICATIONS = "FETCH_CERTIFICATIONS";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const FETCH_COMPANY = "FETCH_COMPANY";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const ADD_FILTER_CLAIMS = "ADD_FILTER_CLAIMS";
export const DELETE_FILTER_CLAIMS = "DELETE_FILTER_CLAIMS";

export const ADD_FILTER_CERTIFICATIONS = "ADD_FILTER_CERTIFICATIONS";
export const DELETE_FILTER_CERTIFICATIONS = "DELETE_FILTER_CERTIFICATIONS";

export const ADD_FILTER_PRIVATE_LABEL = "ADD_FILTER_PRIVATE_LABEL";
export const DELETE_FILTER_PRIVATE_LABEL = "DELETE_FILTER_PRIVATE_LABEL";

export const ADD_FILTER_SEARCH = "ADD_FILTER_SEARCH";


export const ADD_FILTER_CATEGORIES = "ADD_FILTER_CATEGORIES";
export const ADD_FILTER_SUBCATEGORIES = "ADD_FILTER_SUBCATEGORIES";
export const DELETE_FILTER_CATEGORIES = "DELETE_FILTER_CATEGORIES";

export const FETCH_HOW_IT_WORKS = "FETCH_HOW_IT_WORKS";
export const FETCH_PRIVACY_POLICY = "FETCH_PRIVACY_POLICY";
export const FETCH_TERMS_OF_USAGE = "FETCH_TERMS_OF_USAGE";


export const UPDATE_IMAGE = "UPDATE_IMAGE";

export const RESET_OBJECT = "RESET_OBJECT";