import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import addfilter from "../../img/addfilter.png";
import certificationClose from "../../img/certification-close.svg";
import Modal from "react-modal";
import AddClaimsFilter from "./AddClaimsFilter";
import {
  deleteFilterClaims,
  deleteFilterCertifications,
  deleteFilterPrivateLabel,
} from "./../../redux/actions";
import { Form, Field } from "react-final-form";
import { ModalContainer, Button } from "../auth/addCompany/AddCategoryField";
import { Item, Arrow } from "../auth/addCompany/AddCategoryField";
import { device } from "./commonsStyled/index";
import {
  ClaimText,
  Claim,
  ClaimDeleteButton,
  ClaimsList,
} from "./../auth/addCompany/AddClaimsField";
import AddCategoriesFilterTest from "./AddCategoriesFilterTest";
import AddCertificationsFilter from "./AddCertificationsFilter";
import AddPrivateLabelFilter from "./AddPrivateLabelFilter";
import { union as _union } from 'lodash';

const onSubmit = async (values) => { };

const root = document.getElementById("root");
const resolution = window.innerWidth;
const mediaMatch = resolution >= 500;
const isFirefox = typeof InstallTrigger !== 'undefined';

const customStyles = {
  content: {
    width: isFirefox ? "-moz-fit-content" : "fit-content",
    height: isFirefox ? "50vh" : "fit-content",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "solid 1px #309da1",
    padding: "66px 63px 62px 63px",
  },
};

const customStylesSmall = {
  content: {
    width: isFirefox ? "-moz-fit-content" : "fit-content",
    height: isFirefox ? "-moz-fit-content" : "fit-content",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "solid 1px #309da1",
    padding: "66px 5px",
  },
};

const FilterModal = (props) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);

  // --------------------------
  // listeners e default values
  // --------------------------

  const companies = useSelector((root) => root.companies.companies);
  const allClaims = useSelector((root) => root.claims.claims.data);
  const allCertifications = useSelector((root) => root.certifications.certifications.data);
  const allCategories = useSelector((root) => root.categories.categories.data);
  const categoriesHelper = (allCategories) ? allCategories.data : []; // Porque sim

  // limpar os filtros para que apenas existam os que existem em empresas
  // NOTA: desta forma poupamos "ciclos/loops"
  let { claims, certifications, categories } = (companies || []).reduce((acc, company) => {
    if (company.status != 1) {
      return acc;
    }

    console.log(company)

    const claimsCampany = (allClaims || []).filter(claim => (company.claims) ? company.claims.find(c => c.claim_id == claim.id) != undefined : false);
    const certificationsCompany = (allCertifications || []).filter(certification => (company.certifications) ? company.certifications.find(c => c.id == certification.id) != undefined : false);
    const categoriesCompany = categoriesHelper.filter(category => (company.subcategories) ? company.subcategories.find(c => c.category_id == category.id) != undefined : false);

    if (claimsCampany.length > 0) {
      acc.claims = _union(acc.claims, claimsCampany);
    }

    if (certificationsCompany.length > 0) {
      acc.certifications = _union(acc.certifications, certificationsCompany);
    }

    if (categoriesCompany.length > 0) {
      // merge with existem
      acc.categories.data = _union(acc.categories.data, categoriesCompany);
    }

    return acc;
  }, { claims: [], certifications: [], categories: { data: [] } });

  // ultimo passo é limpar o segundo ramo da lista quando existem
  if (allClaims && claims) {
    claims.forEach(claim => {
      claim.children = claim.children.filter(child => companies.find(company => company.status == 1 && company.claims.find(c => c.id == child.id)) != undefined);
    });;
  }

  if (allCategories && allCategories.data && categories && categories.data) {
    (categories.data).forEach(category => {
      category.subcategories = category.subcategories.filter(child => companies.find(company => company.status == 1 && company.subcategories.find(sc => sc.id == child.id)) != undefined);
    });
  }

  const filter = [
    // {
    //   id: 1,
    //   name: "Product Type",
    //   filters: categories,
    // },
    {
      id: 2,
      name: "Claims",
      filters: claims,
    },
    {
      id: 3,
      name: "Certification",
      filters: certifications /*[
        {
          id: 1,
          name: "cer1",
        },
        {
          id: 2,
          name: "cer2",
        },
        {
          id: 3,
          name: "cer3",
        },
        {
          id: 4,
          name: "cer4",
        },
      ]*/,
    },
    {
      id: 4,
      name: "Private Label",
      filters: [
        {
          id: 1,
          name: "Private Label",
          value: "1",
        },
      ],
    },
  ];

  // ------------------------------
  // default values para os filtros
  // ------------------------------

  const listFilterClaims = props.filterClaims || [];
  const listFilterCertifications = props.filterCertifications || [];
  const privateLabel = (props.filterPrivateLabel != null) ? [{ id: props.filterPrivateLabel }] : [];
  const categoriesInitialValue = (props.filterCategories && props.filterCategories.length > 0) ? props.filterCategories[0] : [];

  // ---------
  // callbacks
  // ---------

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedFilter(null);
  }, []);

  return (
    <>
      {(listFilterClaims && listFilterClaims.length > 0) ? (
        <ClaimsFilter>
          <ClaimsFilterText>
            Claims
        <ArrowClaimsFilter
              xmlns="http://www.w3.org/2000/svg"
              width="15.889"
              height="28.25"
              viewBox="0 0 15.889 28.25"
            >
              <path
                id="Контур_300"
                fill="none"
                data-name="Контур 300"
                d="M0,0,14.806,14.322,0,27.517"
                transform="translate(0.348 0.359)"
              />
            </ArrowClaimsFilter>
          </ClaimsFilterText>

          <ClaimsFilterList>
            {listFilterClaims.map((item) => (
              <Claim key={item.id}>
                <ClaimFilterText>{item.parent ? item.parent + " -> " + item.name : item.name}</ClaimFilterText>
                <ClaimDeleteButton
                  type="button"
                  onClick={() => dispatch(deleteFilterClaims(item.id))}
                />
              </Claim>
            ))}
          </ClaimsFilterList>
        </ClaimsFilter>
      ) : null}

      {(listFilterCertifications && listFilterCertifications.length) > 0 ? (
        <CertificationFilter>
          <CertificationsFilterText>
            Certifications
        <ArrowCertificationsFilter
              xmlns="http://www.w3.org/2000/svg"
              width="15.889"
              height="28.25"
              viewBox="0 0 15.889 28.25"
            >
              <path
                id="Контур_300"
                fill="none"
                data-name="Контур 300"
                d="M0,0,14.806,14.322,0,27.517"
                transform="translate(0.348 0.359)"
              />
            </ArrowCertificationsFilter>
          </CertificationsFilterText>

          <CertificationsList>
            {listFilterCertifications.map((item) => (
              <Certification key={item.id}>
                <CertificationText>{item.name}</CertificationText>
                <CertificationDeleteButton
                  type="button"
                  onClick={() => dispatch(deleteFilterCertifications(item.id))}
                />
              </Certification>
            ))}
          </CertificationsList>
        </CertificationFilter>
      ) : null}

      {privateLabel.map((item) => (
        <PrivatLabelFilterText key={item.id}>
          <PrivatLabelText>Private Label</PrivatLabelText>
          <CertificationDeleteButton
            type="button"
            onClick={() => dispatch(deleteFilterPrivateLabel(item.id))}
          />
        </PrivatLabelFilterText>
      ))}

      <RefineSeachButton type="button" onClick={handleOpen}>
        <ButtonText>Add Filter</ButtonText>
        <ButtonImg>
          <Img src={addfilter} alt="addfilter"></Img>
        </ButtonImg>
      </RefineSeachButton>
      <Form
        onSubmit={() => { }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Field
                defaultValues={categoriesInitialValue}
                name="categories_test"
                categories={categories}
                component={AddCategoriesFilterTest}
              />
            </form>
          </>
        )}
      />

      <Modal
        appElement={root}
        isOpen={open}
        onRequestClose={handleClose}
        contentLabel="Example Modal"
        style={mediaMatch ? customStyles : customStylesSmall}
      >
        <ModalContainer>
          {selectedFilter === null ? (
            <TitleModal>Add search filter</TitleModal>
          ) : null}
          {selectedFilter ? (
            <TitleModal>{`Select the ${selectedFilter.name}`}</TitleModal>
          ) : null}
          <List>
            {selectedFilter === null ? (
              <>
                {filter.map((item) => (
                  <Item key={item.id} onClick={() => setSelectedFilter(item)}>
                    {item.name}
                    <>
                      <Arrow
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.889"
                        height="28.25"
                        viewBox="0 0 15.889 28.25"
                      >
                        <path
                          id="Контур_300"
                          fill="none"
                          data-name="Контур 300"
                          d="M0,0,14.806,14.322,0,27.517"
                          transform="translate(0.348 0.359)"
                        />
                      </Arrow>
                    </>
                  </Item>
                ))}
                <Button onClick={handleClose}>Done</Button>
              </>
            ) : (
                <>
                  <>
                    <Form
                      onSubmit={onSubmit}
                      render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values,
                      }) => (
                        <>
                          <form onSubmit={handleSubmit}>
                            {selectedFilter.id == 2 ? (
                              <Field
                                // initialValue={categoriesInitialValue}
                                name="filterClaims"
                                claims={selectedFilter.filters}
                                component={AddClaimsFilter}
                              />
                            ) : null}
                            {selectedFilter.id == 3 ? (
                              <Field
                                // initialValue={categoriesInitialValue}
                                name="filterCertifications"
                                certifications={selectedFilter.filters}
                                component={AddCertificationsFilter}
                              />
                            ) : null}
                            {selectedFilter.id == 4 ? (
                              <Field
                                // initialValue={categoriesInitialValue}
                                name="filterPrivateLabel"
                                calims={selectedFilter.filters}
                                component={AddPrivateLabelFilter}
                              />
                            ) : null}
                            {/* {selectedFilter.id == 1 ? (
                    <Field
                      initialValue={categoriesInitialValue}
                      name="filterCategories"
                      categories={selectedFilter.filters}
                      component={AddCategoriesFilter}
                    />
                  ) : null} */}
                          </form>
                        </>
                      )}
                    />
                    <Button onClick={handleClose}>Done</Button>
                  </>
                </>
              )}
          </List>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default FilterModal;

export const RefineSeachButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 349px;
  height: 58px;
  padding: 0px 20px 0px 26px;
  margin-bottom: 18px;
  border-radius: 36px;
  border: solid 1px #a2a2a2;
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  @media ${device.mobileS} {
    width: 189px;
    height: 44px;
    padding: 0px 10px 0px 16px;
  }
  @media ${device.mobileL} {
    width: 200px;
    height: 44px;
  }
  @media ${device.laptopL} {
    width: 348px;
    height: 58px;
  }
`;
export const ButtonText = styled.p`
  font-family: Montserrat-Regular;
  font-size: 16px;
  line-height: 1.19;
  color: #a2a2a2;
  @media ${device.mobileS} {
    font-size: 12px;
  }
  @media ${device.laptopL} {
    font-size: 16px;
  }
`;
export const ButtonImg = styled.div`
  @media ${device.mobileS} {
    width: 22px;
    height: 22px;
  }
  @media ${device.laptopL} {
    width: 32px;
    height: 32px;
  }
`;
export const TitleModal = styled.p`
  font-size: 38px;
  color: #4e4e4e;
  margin: 0 0 45px 0;
  @media ${device.mobileS} {
    font-size: 20px;
  }
  @media ${device.mobileL} {
    font-size: 20px;
  }
  @media ${device.tablet} {
    font-size: 38px;
  }
`;
export const Img = styled.img`
  @media ${device.mobileS} {
    width: 100%;
  }
`;
export const List = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const ClaimsFilter = styled(ClaimsList)`
  display: flex;
  background-color: rgba(32, 158, 191, 0.1);
  width: 280px;
  /* padding-right: 37px; */
  border-radius: 36px;
  color: #209ebf;
  border: solid 1px #209ebf;
  box-sizing: border-box;
  @media ${device.mobileL} {
    width: 380px;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction:row;
    width:fit-content;
    align-items:flex-start;
  }
  
`;
export const ClaimsFilterText = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 280px;
  min-height: 58px;
  border-radius: 36px;
  border-right: solid 1px #209ebf;
  border-left: solid 1px #209ebf;
  border-bottom: solid 1px #209ebf;
  background-color: #ffffff;
  font-weight: bold;
  /* margin-right: 19px; */
  padding: 0px 10px 0px 10px;
  box-sizing:border-box;
  @media ${device.mobileL} {
    width: 380px;
  }
  @media ${device.tablet} {
    width: 150px;
    margin-right:10px;
  }
`;
export const ArrowClaimsFilter = styled.svg`
  path {
    stroke: #209ebf;
  }
  @media ${device.mobileS} {
    height: 30px;
  }
  @media ${device.mobileL} {
    height: 20px;
  }
`;
export const ClaimsFilterList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  @media ${device.mobileS} {
    width: 260px;
    padding: 10px;
  }
  @media ${device.mobileS} {
    width: 360px;
  }
  @media ${device.tablet} {
    /* width: fit-content; */
    padding:0px;
    width: 400px;
  }
  @media ${device.laptopL} {
    width: 700px;
  }
  
`;
export const ClaimFilterText = styled(ClaimText)`
  display: flex;
  @media ${device.mobileS} {
    font-size: 11px;
  }
  @media ${device.tablet} {
    font-size: 16px;
  }
`;
export const CertificationFilter = styled(ClaimsList)`
  background-color: rgba(85, 111, 171, 0.1);
  border: solid 1px #556fab;
  border-radius: 36px;
  @media ${device.mobileS} {
    width: 280px;
  }
  @media ${device.mobileL} {
    width: 380px;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction:row;
    width:fit-content;
    align-items:flex-start;
  }
`;

export const CertificationsList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  box-sizing:border-box;
  @media ${device.mobileS} {
    width: 260px;
    padding: 10px;
  }
  @media ${device.mobileL} {
    width: 360px;
  }
  @media ${device.tablet} {
    width: 400px;
    padding:0px;
    margin-bottom: 0px;
  }
  @media ${device.laptopL} {
    width: 700px;
  }
`;

export const Certification = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-radius: 36px;
  border: solid 1px#556FAB;
  background-color: #ffffff;
  font-family: Montserrat-Medium;
  font-weight: 500;
  margin-right: 19px;
  margin-top: 8px;
  margin-bottom: 6px;
  padding: 0 16px 0 18px;
`;
export const CertificationText = styled.p`
  color: #556fab;
  @media ${device.mobileS} {
    font-size:11px;
  }
  @media ${device.tablet} {
    font-size:16px;
  }
`;
export const CertificationDeleteButton = styled.button`
  display:flex;
  width: 12px;
  height: 12px;
  border:none;
  background-color:transparent;
  background-image:url("${certificationClose}");
  background-size:cover;
  margin-left:25px;
`;

export const CertificationsFilterText = styled(ClaimsFilterText)`
  border-right: solid 1px  #556fab;
  border-left: solid 1px  #556fab;
  border-bottom: solid 1px #556fab;
  color:#556fab;
  @media ${device.mobileL} {
    width: 380px;
  }
  @media ${device.tablet} {
    width: 200px;
    margin-right:10px;
  }
`;
export const PrivatLabelFilterText = styled(CertificationsFilterText)`
  border-top: solid 1px  #556fab;
  border-right: solid 1px  #556fab;
  border-left: solid 1px  #556fab;
  border-bottom: solid 1px #556fab;
  color:#556fab;
  margin-bottom:20px;
  @media ${device.mobileS} {
    font-size:16px;
  }
  @media ${device.mobileL} {
    width: 380px;
  }
  @media ${device.tablet} {
    width: 200px;
    margin-right:10px;
  }
`;
export const PrivatLabelText = styled(CertificationText)`
@media ${device.mobileS} {
    font-size:16px;
  }
`;


export const ArrowCertificationsFilter = styled.svg`
  path {
    stroke:  #556fab;
  }
  @media ${device.mobileS} {
    height: 30px;
  }
  @media ${device.mobileL} {
    height: 20px;
  }
`;
