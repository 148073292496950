import React, { useState } from "react";
import {
  Label,
  Row,
  Input,
  ContentFormButtom,
  Circle,
  ContentFormButtonText,
  Textarea,
  InputButton,
  LabelButton,
  CastomInput,
  RowCastom,
  LabelCastomTel,
  LabelCastomWeb
} from "./styledForm";
import { RequiredCircle } from "../commonsStyled/index";
import circleImg from "../../../img/sircle.png";
import { Form, useField } from 'react-final-form'
import styled from "styled-components";
import Loading from "../Loading";
function ValidURL(str) {
  var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  if(!regex .test(str)) {
    return false;
  } else {
    return true;
  }
}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const Error = ({ name }) => {
  const {
    meta: { touched, error, submitError }
  } = useField(name, { subscription: { touched: true, error: true } });
  return touched && error ? <span style={{ color: "red" }}>{error}</span> : null;
};

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;  //Remove on production

const FormContainerJoinUS = () => {
  const [massage, setMassage] = useState(false)
  const [failedmsg, setfailedmsg] = useState("")
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values, form) => {
    setLoading(true);
    /*Tiago tenho de acopular aqui o registo, esperar pelo sucesso, e depois enviar o email 
com user e  pass anexada ao assunto */
    try {
      var randomstring = Math.random().toString(36).slice(-8);

      values.password =randomstring;

      console.log("values",values);

      let response = await fetch(apiBaseUrl + '/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify( values)
      });

      let result = await response.json();

      if (result.success) {
 
        setMassage(result.success)
        window.location.href = "/success?indication=registo";
      }
      else{
        setfailedmsg(result.error[0]);
        setLoading(false);
      }

    } catch (error) {
      setfailedmsg(error);
      setLoading(false);
    } 

  }


  return (
    <Form
      onSubmit={onSubmit}
      validate={values => {
        const errors = {}
        if (!values.name) {
          errors.name = 'Preenchimento obrigatório'
        }
        if (!values.email) {
          errors.email = 'Preenchimento obrigatório'
        }

        if (values.email && !values.email.match(/.+@.+/)) {
          errors.email = 'Email inválido';
        }

        if (!values.company) {
          errors.company = 'Preenchimento obrigatório'
        }

        if (!values.website_url) {
          errors.website_url = 'Preenchimento obrigatório'
        }/*else if(!ValidURL(values.website_url)){
          errors.website_url = 'Formato de website inválido'
        }*/



        if (!values.phone) {
          errors.phone ='Preenchimento obrigatório';
        } else if (isNaN(values.phone)) {
          errors.phone = 'Formato incorrecto'
        }

      
        
        if (!values.CAE) {
          errors.CAE = 'obrigatório'
        }else if (values.CAE.length >5) {
          errors.CAE = 'O CAE tem no máximo 5 algarismos'
        }else if (isNaN(values.CAE)) {
          errors.CAE = 'Formato incorrecto, o CAE é composto apenas por números'
        }




        if (!values.name || !values.email || !values.company  || !values.CAE) {
          errors.error = 'Erros encontrados no preenchimento'
        }

        return errors
      }}
      render={({ handleSubmit, form, submitError, submitting, pristine, values, error }) => (
        <form onSubmit={handleSubmit}>
          {failedmsg ?  <Label style={{color:"red"}}>{failedmsg}</Label> : <></>}
          <Label>
            <Row>
              Nome
          <RequiredCircle />
              <Error name="name" />
            </Row>
            <Input name="name" component="input" submitting={submitting} />

          </Label>
          <Label>
            <Row>
              Email
          <RequiredCircle />
              <Error name="email" />
            </Row>
            <Input name="email" type="email" component="input" submitting={submitting} />
          </Label>
          <LabelCastomTel>
              <Row>
                Telefone
                <RequiredCircle />
          <Error name="phone" />
              </Row>
              <Input name="phone" component="input" type="tel" submitting={submitting} />
            </LabelCastomTel>
          <Label>
            <Row>
              Nome da Empresa
          <RequiredCircle />
              <Error name="company" />
            </Row>
            <Input name="company" component="input" submitting={submitting} />
          </Label>
     

           
            <LabelCastomWeb>
            <Row>
              Website da Empresa
              <RequiredCircle />
              <Error name="website_url" />
              </Row>

          <Input name="website_url" component="input" submitting={submitting} />

            </LabelCastomWeb>
          
           
      
         
            <Label>
            <Row>
            CAE Empresa (Subclasse principal)
              <RequiredCircle />
              <Error name="CAE" />
          </Row>
         
          <Input name="CAE" component="input"  submitting={submitting} />
            </Label>
           
  

          <Container>
            <LabelButton type="submit">
              <ContentFormButtom submitting={submitting}>
                <ContentFormButtonText style={{paddingLeft:0,margin:"auto", paddingRight:"35px",}}>Registar</ContentFormButtonText>
                <Circle>
                  <img src={circleImg} alt="circleImg" />
                </Circle>
              </ContentFormButtom>
            </LabelButton>
            {loading ? <Loading loading={true} loadingState={2} loadMessage={massage}></Loading> : <></>}
          </Container>
        </form>
      )}
    />
  );
};

export default FormContainerJoinUS;

export const Container = styled.div`
  display: flex;
  align-items:center;
`;