import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import thunk from "redux-thunk";
import { createStore, compose, applyMiddleware } from "redux";
import { rootReducer } from "./redux/rootReducer";
import Root from './components/Root';
import "./index.css";

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunk)
    : compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );

export const store = createStore(
  rootReducer,
  //devTools
  applyMiddleware(thunk)
);

// --------------------
// disabled logs by T.C
// --------------------

if (process.env.NODE_ENV !== 'development') {
  console.log = () => { };
}

ReactDOM.render(<Root store={store} />, document.getElementById("root"));

serviceWorker.unregister();
