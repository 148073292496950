import React from "react";
import logoHome from "../../../img/logo-home.png";
import logoWhite from "../../../img/logo-white.png"
import styled from "styled-components";
import { device } from "./../../commons/commonsStyled/index";
import { Link } from "react-router-dom";

const LogoImg = styled.img`
  display: flex;
  background-repeat: no-repeat;
  //width:100%;
`;
const LogoContainer = styled.div`
  display: flex;
  width: 283px;
  height: 65px;
  @media ${device.mobileS} {
    width: 120px;
    height: 30px;
  }
  @media ${device.laptop} {
    width: 340px;
    height: 37px;
  }
`;
const LogoHome = () => {
  return (
    <LogoContainer>
      <Link to="/">
        <LogoImg src={logoWhite} />
      </Link>
    </LogoContainer>
  );
};

export default LogoHome;
