import React, { useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { addFilterClaims, addFilterCertification, deleteFilterCertifications } from "../../redux/actions";
import { deleteFilterClaims } from './../../redux/actions';
import {

  InputCastom,
  ItemLabel,
  List,
} from "../auth/addCompany/AddCategoryField";



const AddCertificationsFilter = ({
  input: { value, onChange, onBlur, onFocus },
  meta,
  certifications,
}) => {
  const dispatch = useDispatch();
  const listFilterCertifications = useSelector((root) => root.filterCertifications);

  const handleChangeClaims = useCallback(
    (e) => {

      const newValue = [...value];
      let certification = { id: e.target.id, name: e.target.value }

      // let find = newClaims.some(element => element.id === claim.id)
      // if(!find) newValue.push(claim);
      if (e.target.checked) {
        if (newValue.find(v => v.id == certification.id) === undefined) {
          newValue.push(certification);
          dispatch(addFilterCertification(certification));
        }
      }

      if (!e.target.checked) {
        let index = newValue.findIndex(element => element.id === certification.id);
        let elem = newValue.find(element => element.id === e.target.id);
        dispatch(deleteFilterCertifications(e.target.id))
        newValue.splice(index, 1)
      }

      onChange(newValue);
    },
    [onChange, value, deleteFilterClaims, addFilterClaims]
  );

  //   const handleChecked = useMemo(() => {
  //     console.log(listFilterClaims)
  //     return listFilterClaims
  //   },[listFilterClaims])
  return (
    <>
      <List>
        {certifications.filter((v,i,a)=>a.findIndex(t=>(t.name===v.name))===i).map((item) => (
          <div key={item.id}>
            <InputCastom
              id={item.id}
              name="claims"
              value={item.name}
              type="checkbox"
              onChange={handleChangeClaims}
              checked={
                !!listFilterCertifications?.some((element) => {
                  return element.id == item.id;
                })
              }
            />
            <ItemLabel htmlFor={item.id}>{item.name}</ItemLabel>
          </div>
        ))}
      </List>
    </>
  );
};

export default AddCertificationsFilter;
export const TitleModal = styled.p`
  font-size: 38px;
  color: #4e4e4e;
  margin: 60px 0 50px 0;
`;