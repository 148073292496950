import styled from "styled-components";
const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "1920px",
};
export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  min-height: calc(100vh - 386px);
  overflow:hidden;
  @media ${device.mobileS} {
    min-width:320px;
  }

  
`;
export const StickyHeaderContainer = styled.div`
  
  padding-bottom: ${props => props.isSticky ? '17px' : '0px'};
  background-color: ${props => props.isSticky ? '#fff' : 'transparent'};
  border-bottom: ${props => props.isSticky ? 'solid 2px #20babf' : 'none'};
  z-index: 100;

  @media ${device.mobile} {
    padding-top: ${props => props.isSticky ? '17px' : '35px'};
  }

  @media ${device.tablet} {
    padding-top: ${props => props.isSticky ? '17px' : '74px'};
  }


`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin:auto;
  padding-top: 74px;
`;

export const MyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;

  margin:auto;

  @media ${device.mobileS} {
    height:4vh;

    img{
      height:4vh;
      width:auto;
    }
  }

  @media ${device.laptop  } {
    height:auto;
    img{
      height:auto;
    }
  
  }
`;

export const Container = styled.div`
  width: 100%;
  
`;

export const ContentContainer = styled.div`
  display: block;
  width: 80%;
  margin: 0 auto;
  @media ${device.mobileS} {
    width: 80%;
  }
`;
export const Title = styled.h1`
  font-size: 57px;
  font-weight: 300;
  color: #20babf;
  line-height: 0.96;
  margin-top: 138px;
  @media ${device.mobileS} {
    font-size: 34px;
    margin-top: 34px;
  }
  @media ${device.mobileL} {
    font-size: 44px;
    margin-top: 34px;
  }
  @media ${device.laptop  } {
    font-size: 64px;
    margin-top: 138px;
  }
  
  
`;
export const RegistosTitle = styled.h2`
  font-size: 57px;
  font-weight: 300;
  color: #20babf;
  line-height: 0.96;
  margin-top: 138px;
  @media ${device.mobileS} {
    font-size: 30px;
  }
`;
export const RegistosText = styled.p`
  font-size: 27px;
  font-weight: 300;
  color: #3f424c;
  line-height: 0.96;
  margin-top: 20px;
  margin-bottom: 20px;
  @media ${device.mobileS} {
    font-size: 18px;
  }
`;
export const RequiredCircle = styled.div`
  width: 10px;
  height: 10px;
  object-fit: contain;
  background-color: #e27f7f;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 5px;
`;
export const Content = styled.div`
  margin-top: 50px;
`;

export const Section = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;
  background-color: #fbfbfb;
`;
export const Br = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cbcbcb;
`;
