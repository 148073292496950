import {FETCH_CERTIFICATIONS} from "./actionTypes";

const initialState = {
  certifications:{}
};

export const certificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CERTIFICATIONS:
      return {...state,certifications:action.payload}
    default:
      return state;
  }
};