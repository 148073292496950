import React, { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { loginUser } from "./../../redux/actions";
import Navigation from "../commons/Navigation";
import styled from "styled-components";
import {
  Link,
  useRouteMatch,
} from "react-router-dom";

import enterIcon from "../../img/logIn.png";
import {
  Title,
  HeaderContainer,
  Wrapper,
  Container,
  Section,
  ContentContainer,
  Content,
} from "../commons/commonsStyled/index";
import Logo from "./../commons/Logo";
import Footer from "../commons/Footer";
import {
  Label,
  LabelButton,
  ContentFormButtom,
  Circle,
  InputButton,
  ContentFormButtonText,
  Input,
} from "../commons/Form/styledForm";
import { Form, useField } from "react-final-form";
import Loading from "../commons/Loading";
import Cookies from "../commons/Cookies";


function ValidateEmail(mail) 
{
if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail))
  {
    return (true)
  }
    return (false)
}
const Login = () => {
  const error = useSelector((root) => root.loginError);
  // const valueSelectedCat = useMemo(() => {
  //   if (selectedCat) {
  //     return value.find((cat) => cat.id === selectedCat.id);
  //   }
  // }, [value, selectedCat]);
  const [sending, setSending] = React.useState(false);


  const Error = ({ name }) => {
    const {
      meta: { touched, error },
    } = useField(name, { subscription: { touched: true, error: true } });
    return touched && error ? (
      <span style={{ color: "red" }}>{error}</span>
    ) : null;
  };
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    setSending(true);
    dispatch(loginUser(values));
  };
  return (
    <>
    {console.log("look at sending",sending)}

      <Wrapper>
        <Cookies />
        <Section>
          <Container>
            <HeaderContainer>
              <Logo />
              <Navigation />
            </HeaderContainer>
          </Container>
          <ContentContainer>
            <Title>Bem-vindo</Title>
            <p style={{marginTop:"25px", fontWeight:"bolder"}}>Área exclusiva para empresas portuguesas do setor agroalimentar.</p>
            <h4 style={{color:"grey"}}>Exclusive for Portuguese Food &amp; Beverage companies.</h4>    
          </ContentContainer>
        </Section>
        <ContentContainer>
          <Content>
            <h1>Perfil da Empresa</h1>
            <Form
              onSubmit={onSubmit}
              validate={(values) => {
              
                const errors = {};
                if (!values.email) {
                  errors.email = "Campo de preenchimento obrigatório";
                }else if(!ValidateEmail(values.email)){
                  errors.email = "formato inválido";
                }
                if (!values.password) {
                  errors.password = "Campo de preenchimento obrigatório";
                }/*else if (values.password.length < 8) {
                  errors.password = "must be more than 8 characters";
                }*/

                return errors;
              }}
              render={({
                handleSubmit,
                form,
                submitError,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  {error ? <Label style={{color:"red"}}>{error}</Label> : <></>}
                  <Label>
                    <span>
                      Email  <Error name="email" />
                    </span>

                    <Input name="email" component="input" />
                  </Label>
                  <Label>
                   
                    <span> Password  <Error name="password" /></span>
                   
                    <Input type="password" name="password" component="input"  />
                  </Label>
                  <LabelButton disabled={submitting || pristine} type="submit">
                    <ContentFormButtom login>
                      <ContentFormButtonText>ENTRAR</ContentFormButtonText>
                      <Circle login>
                         <img src={enterIcon} alt="circleImg" /> 
                      </Circle>
                    </ContentFormButtom>
                  </LabelButton>

                  {sending && !error ?  <Loading loading={true} loadingState={2} loadMessage={"A entrar..."}></Loading>:null}
                </form>
              )}
            />
            <Link to="/recover">Esqueceu a sua password?</Link>
          </Content>
        </ContentContainer>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Login;
