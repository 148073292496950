import styled from "styled-components";
import sectionMain from "../../../img/section-main.jpg";
import { device } from "./../../commons/commonsStyled/index";

export const TitleMain = styled.p`
  font-family: Montserrat-Light;
  width: 1260px;
  color: #ffffff;
  font-size: 93px;
  font-weight: 300;
  margin-top: 300px;
  margin-left: 160px;
  margin-bottom: 3px;
  @media ${device.mobileS} {
    font-size: 30px;
    margin-left: 10vw;
    width: 300px;
    margin-top: 150px;
  }
  @media ${device.mobileL} {
    font-size: 38px;
    margin-left: 10vw;
    width: 352px;
  }
  @media ${device.tablet} {
    width: 600px;
    font-size: 50px;
    margin-left: 50px;
    margin-top: 120px;
  }
  @media ${device.laptop} {
    width: 100vw;
    margin-left: 10vw;
    font-size: 10vh;
    
  }
  @media ${device.laptopL} {
    width: 100vw;
    margin-left: 10vw;
    font-size: 12vh;
  }
`;

export const TitleHome = styled.h1`
  font-size: 57px;
  font-weight: 300;
  color: #20babf;
  line-height: 0.96;
  margin-top: 128px;
  
  @media ${device.mobileS} {
    font-size: 34px;
    margin-top: 34px;
  }
  @media ${device.mobileL} {
    font-size: 44px;
    margin-top: 34px;
  }
  @media ${device.laptop  } {
    font-size: 99px;
    margin-top: 138px;
  }
  
  
`;

export const P = styled.p`
  font-family: Montserrat-Bold;
  font-size: 30px;
  color: rgba(77, 249, 255, 0.68);
 
  margin-top:24px;
  @media ${device.mobileS} {
    font-size: 15px;
    margin-left: 10.5vw;
  }
  @media ${device.mobileL} {
    font-size: 18px;
    margin-left: 10.5vw;
  }
  @media ${device.tablet} {
    font-size: 30px;
    margin-left: 5vw;
  }
  @media ${device.laptop} {
    font-size: 30px;
    margin-left: 10.5vw;
  }
`;

export const Text = styled.p`
  width: 1227px;
  color: #3f424c;
  line-height: 1.4;
  font-size: 20px;
  margin-top: 47px;
  @media ${device.mobileS} {
    width: 300px;
    font-size: 12px;
  }
  @media ${device.mobileL} {
    width: 358px;
    font-size: 15px;
  }
  @media ${device.tablet} {
    width: 600px;
  }
  @media ${device.laptop} {
    width: 100%;
    font-size: 24px;
  }
`;
export const CardsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex: 0 50%;
  justify-content: left;
  @media ${device.mobileS} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.laptop} {
    flex-direction: row;
    align-items: flex-start;
  }
`;
export const CardsItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 682px;
  height: 682px;
  margin-right: 118px;
  margin-bottom: 118px;
  &:nth-child(1) {
    background-color: #1cb5b9;
  }
  &:nth-child(2) {
    margin-right: 0px;
    background-color: #209ebf;
  }
  &:nth-child(4) {
    margin-right: 0px;
    background-color: #3f424c;
  }
  &:nth-child(3) {
    background-color: #546fac;
  }
  @media ${device.mobileS} {
    width: 250px;
    height: 250px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  @media ${device.mobileL} {
    width: 340px;
    height: 340px;
  }
  @media ${device.tablet} {
    margin-right: 0px;
    width: 500px;
    height: 500px;
  }
  @media ${device.laptop} {
    width: 39vw;
    flex-wrap: nowrap;
    margin: auto;
    margin-left:0px;
    margin-bottom: 118px;
   
    padding-top:100px;
    padding-bottom:100px;
  }
  @media ${device.laptopL} {
    width: 35vw;
    height: 35vw;
    margin-right: 118px;
    margin-bottom: 118px;

    padding-top:25px;
    padding-bottom:35px;
  }
`;
export const CardsItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 20vw;
  margin-bottom: 20vw;
  height: fit-content;
`;

export const CardsTitle = styled.h2`
  font-family: Montserrat-Bold;
  line-height: 1.4;
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: 60px;
  @media ${device.mobileS} {
    font-size: 14px;
  }
  @media ${device.mobileL} {
    font-size: 15px;
  }
  @media ${device.laptop} {
    font-size: 15px;
  }
  @media ${device.laptopL} {
    font-size: 18px;
  }
`;
export const CardsItemImg = styled.div`
  margin-left: 0px !important;
  
  @media ${device.mobileS} {
    width: 150px;
    height: auto;
    margin-top:0;
  }
  @media ${device.mobileL} {
    width: 240px;
    height: auto;
    margin-top:0;
  }
  @media ${device.tablet} {
    width: 90%;
    margin:auto;
    height: auto;
    margin-top:0;
  }
  @media ${device.laptop} {
    width: 300px;
    height: auto;
    margin-top:0;
  }
`;

export const CardImg = styled.img`
  @media ${device.mobileS} {
    
    width: 100%;
  }
`;

export const CardsItemTitle = styled.div`
  font-family: Montserrat-Light;
  font-size: 60px;
  color: #ffffff;
  margin: 20px 0px 30px 0px;
  @media ${device.mobileS} {
    margin: 10px 0px 20px 0px;
    font-size: 16px;
  }
  @media ${device.mobileL} {
    margin: 5px 0px 30px 0px;
    font-size: 22px;
  }
  @media ${device.tablet} {
    font-size: 30px;
  }
  @media ${device.laptop} {
    font-size: 62px;
  }
  @media ${device.laptopL} {
    font-size: 62px;
  }
`;
export const CardsItemText = styled.div`
  font-family: Montserrat-Light;
  font-size: 20px;
  color: #ffffff;
  @media ${device.mobileS} {
    font-size: 13px;
  }
  @media ${device.mobileL} {
    font-size: 15px;
  }
  @media ${device.tablet} {
    font-size: 20px;
  }
  @media ${device.laptop} {
    font-size: 20px;
  }
`;
export const Contact = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: rgba(32, 158, 191, 0.05);
  margin-top: 100px;
`;

export const Required = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat-Bold;
  font-size: 14px;
  width: 200px;
  background-color: #e5f3f8;
  padding: 20px 20px 20px 10px;
  margin-bottom: 20px;
`;

export const ContactTitle = styled.h2`
  font-size: 57px;
  font-weight: 300;
  color: #20babf;
  margin: 0;
  margin-top: 100px;
  margin-bottom: 50px;
  font-family: Montserrat-Light;
  
  @media ${device.mobileS} {
    font-size: 30px;
  }
  @media ${device.mobileL} {
    font-size: 45px;
  }
  @media ${device.laptop} {
    font-size: 99px;
 
  }

`;

export const Section = styled.section`
  display: flex;
  justify-content: center;
  background-image: url(${sectionMain});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-bottom: 80px;
`;

