import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import addfilter from "../../img/addfilter.png";
import deleteCategoryImg from "../../img/delete-category.png";
import check from "../../img/check.svg";
import { device } from './../commons/commonsStyled/index';
import {
  ButtonImg,
  ButtonText,
  RefineSeachButton,
  Img
} from "../pages/Directory";
import { addFilterCategories } from "../../redux/actions";
import { useDispatch } from 'react-redux';

const root = document.getElementById("root");
const isFirefox = typeof InstallTrigger !== 'undefined';

const customStyles = {
  content: {
    width: isFirefox ? "-moz-fit-content" : "fit-content",
    height: "50vh",
    top: "50%",
    left: "50%",
    overflowY: "scroll",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    /*padding:"66px 63px 62px 63px ",*/
    padding: "5vh 5vw 5vh 5vw ",
    border: "solid 1px #309da1",
  },
};

const AddCategoriesFilterTest = ({
  input: { value, onChange, onBlur, onFocus },
  meta,
  categories,
  defaultValues,
}) => {
  const { error } = meta;
  const [open, setOpen] = useState(false);
  const [selectedCat, setSelectedCat] = useState(null);
  const dispatch = useDispatch();

  // ---------
  // callbacks
  // ---------

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedCat(null);
  }, []);

  const handleChangeCategory = useCallback((e) => {
    const newValue = [...value];
    const { target: { value: id, name }, } = e;

    let valueCat = value.find((cat) => cat.id === selectedCat.id);
    if (!valueCat) {
      valueCat = { ...selectedCat, subcategories: [] };
      if (e.target.checked) {
        newValue.push(valueCat);
      }
    }
    if (e.target.checked) {
      valueCat.subcategories.push({ name, id });
    }
    if (!e.target.checked) {
      let index = valueCat.subcategories.findIndex(element => element.id === id)
      valueCat.subcategories.splice(index, 1)
    }

    if (valueCat.subcategories.length <= 0) {
      const catIndex = newValue.findIndex((cat) => cat.id === valueCat.id);
      newValue.splice(catIndex, 1);
    }

    onChange(newValue);
    dispatch(addFilterCategories(newValue));
  }, [onChange, selectedCat, value]);

  const handleDeleteCategory = useCallback(({ catId, subcatIndex }) => {
    let valueCat = value.find((cat) => cat.id === catId);

    if (!valueCat) {
      return;
    }
    const newValue = [...value];
    valueCat.subcategories.splice(subcatIndex, 1);
    if (valueCat.subcategories.length !== 0) {
      dispatch(addFilterCategories(newValue));
      return onChange(newValue);
    }
    const catIndex = newValue.findIndex((cat) => cat.id === valueCat.id);
    if (catIndex !== -1) {
      newValue.splice(catIndex, 1);
      dispatch(addFilterCategories(newValue));
      return onChange(newValue);
    }
  }, [onChange, value]);

  // --------------
  // default values
  // --------------

  value = defaultValues;

  const valueSelectedCat = useMemo(() => {
    if (selectedCat) {
      return value.find((cat) => cat.id === selectedCat.id);
    }
  }, [value, selectedCat]);

  const showError = meta.error && (meta.dirty || meta.touched);

  return (
    <div onFocus={onFocus} onBlur={onBlur}>
      {!!showError && <p style={{ color: "red" }}>{error}</p>}

      {(value && value.length > 0) ? value.map((valueCat) => (
        <Container key={valueCat.id}>
          <Category>{valueCat.name}</Category>
          <SubcategoryList>
            {valueCat.subcategories.map((valueSubcat, subcatIndex) => (
              <Subcategory key={'sub_v_' + subcatIndex}>
                {valueSubcat.name}
                <SubcategoryDeleteButton
                  type="button"
                  key={valueSubcat.id}
                  onClick={() => handleDeleteCategory({ catId: valueCat.id, subcatIndex })}
                />
              </Subcategory>
            ))}
          </SubcategoryList>
        </Container>
      )) : null}
      {value.length < 5 && (
        <RefineSeachButton type="button" onClick={handleOpen}>
          <ButtonText>Add Category Filter</ButtonText>
          <ButtonImg>
            <Img src={addfilter} alt="addfilter" />
          </ButtonImg>
        </RefineSeachButton>
      )}

      <Modal
        appElement={root}
        isOpen={open}
        onRequestClose={handleClose}
        contentLabel="Example Modal"
        style={customStyles}

      >
        <ModalContainer>
          {selectedCat ? <GoBack href="#" onClick={() => {
            if (selectedCat) {
              setSelectedCat(null);
            }
          }}>
            <Arrow
              xmlns="http://www.w3.org/2000/svg"
              width="15.889"
              height="28.25"
              viewBox="0 0 15.889 28.25"
              transform="scale(-1,1)"
            >
              <path
                id="Контур_300"
                fill="none"
                data-name="Контур 300"
                d="M0,0,14.806,14.322,0,27.517"
                transform="translate(0.348 0.359)"
              />
            </Arrow>
          </GoBack> : <></>}


          {selectedCat === null ? (
            <Title>
              Select the <br />
              product category
            </Title>
          ) : (
              <Title>
                Select {selectedCat.name} <br />
              sub-category
              </Title>
            )}
          <List>
            {selectedCat === null ? (
              <>
                {categories ? categories.data.map((item) => (
                  <Item key={item.id} onClick={() => setSelectedCat(item)}>
                    {item.name}
                    <>
                      <Arrow
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.889"
                        height="28.25"
                        viewBox="0 0 15.889 28.25"
                      >
                        <path
                          id="Контур_300"
                          fill="none"
                          data-name="Контур 300"
                          d="M0,0,14.806,14.322,0,27.517"
                          transform="translate(0.348 0.359)"
                        />
                      </Arrow>
                    </>
                  </Item>
                )) : null}
                <Button onClick={handleClose}>Done</Button>
              </>
            ) : (
                <>
                  {selectedCat.subcategories.map((subcat) => (
                    <div key={subcat.id}>

                      <InputCastom
                        id={subcat.name}
                        type="checkbox"
                        name={subcat.name}
                        value={subcat.id}
                        onChange={handleChangeCategory}
                        checked={
                          !!valueSelectedCat?.subcategories.some((valueSubcat) => {
                            return valueSubcat.id == subcat.id;
                          })
                        }
                      />
                      <ItemLabel htmlFor={subcat.name}>
                        {subcat.name}
                      </ItemLabel>
                    </div>
                  ))}
                  <Button onClick={handleClose}>Done</Button>
                </>
              )}
          </List>
        </ModalContainer>
      </Modal>
    </div>
  );
};

export default AddCategoriesFilterTest;

export const Category = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin:5px;
  min-height: 58px;
  border-radius: 36px;
  border-right: solid 1px #20babf;
  border-left: solid 1px #20babf;
  border-bottom: solid 1px #20babf;
  border-top: solid 1px #20babf;
  background-color: #ffffff;
  
  @media ${device.mobileS} {
    min-width: 280px;
  }
  @media ${device.mobileL} {
    min-width: 380px;
  }
  @media ${device.tablet} {
    min-width: 200px;
    margin-right:10px;
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  /* padding-right: 37px; */
  border-radius: 36px;
  border: solid 1px #20babf;
  background-color: #eef9f9;
  font-family: Montserrat-Bold;
  font-size: 16px;
  font-weight: bold;
  color: #20babf;
  margin-bottom: 19px;
  @media ${device.mobileS} {
    min-width: 280px;
    flex-direction:column;
  }
  @media ${device.mobileL} {
    width:380px;
  }
  @media ${device.tablet} {
    flex-direction:row;
    width:fit-content;
    align-items:flex-start;
    
  }
  
`;

export const SubcategoryList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media ${device.mobileS} {
    padding:10px;
    width:260px;
  }
  @media ${device.mobileL} {
    width:350px;
    
  }
  @media ${device.tablet} {
    padding:0px;
    margin-bottom: 0px;
    width: fit-content;
  }
  @media ${device.laptopL} {
    width: fit-content;
  }
`;
export const Subcategory = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-radius: 36px;
  border: solid 1px #20babf;
  background-color: #ffffff;
  font-family: Montserrat-Medium;
  font-weight: 500;
  margin-right: 19px;
  margin-top: 8px;
  margin-bottom: 6px;
  padding: 0 16px 0 18px;
  @media ${device.mobileS} {
    font-size: 11px;
  }
  @media ${device.tablet} {
    font-size: 16px;
  }

`;
export const SubcategoryDeleteButton = styled.button`
  display:flex;
  width: 12px;
  height: 12px;
  border:none;
  background-color:transparent;
  background-image:url("${deleteCategoryImg}");
  background-size:cover;
  margin-left:25px;
`;

export const Title = styled.p`
  font-size: 38px;
  color: #4e4e4e;
  padding-bottom:20px;
  
  @media ${device.mobileS} {
    margin-top:10px;
    font-size: 20px;
    
  }
  @media ${device.mobileL} {
    margin-top:10px;
    font-size: 20px
    
  }
  @media ${device.tablet} {
    font-size: 38px
    margin: 60px 0 50px 0;
  }
`;
export const List = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 504px;
  height: 58px;
  border-radius: 36px;
  border: solid 1px #aaaaaa;
  background-color: #ffffff;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: Montserrat-Bold;
  color: #aaaaaa;
  padding: 0 27px 0 27px;
  &:hover {
    border-color: #309da1;
    path {
      stroke: #309da1;
    }
  }
  @media ${device.mobileS} {
    width: 280px;
    font-size: 9px;
    height: 30px;
    box-sizing:border-box
  }
  @media ${device.mobileL} {
    width: 350px;
    font-size: 15px;
    height: 50px;
    box-sizing:border-box
  }
`;

export const ModalContainer = styled.div`
  margin: 0 auto;
  width: 90%;
  @media ${device.mobileS} {
    width: 280px;
    
  }
  @media ${device.mobileL} {
    width: 350px;
    
  }
`;
export const Arrow = styled.svg`
  path {
    stroke: #aaa;
  }
  @media ${device.mobileS} {
    height: 15px;
    
  }
  @media ${device.mobileL} {
    height: 20px;
    
  }
`;
export const ItemLabel = styled.label`
display: flex;
position:relative;
justify-content: space-between;
align-items: center;
cursor: pointer;
width: 504px;
height: 58px;
border-radius: 36px;
border: solid 1px;
border-color: #aaaaaa;
margin-bottom: 10px;
font-size: 16px;
font-family: Montserrat-Bold;
color: #aaaaaa;
padding: 0 54px 0 27px;
&:after {
  position:absolute;
  right:2%;
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: solid 1px;
  border-color: #aaaaaa;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  @media ${device.mobileS} {
    width: 15px;
    height: 15px;
  }
  @media ${device.mobileL} {
    width: 30px;
    height: 30px;
  }
}
&:hover {
  border-color: #309da1;
  path {
    stroke: #309da1;
  }
}
@media ${device.mobileS} {
  width: 280px;
  font-size: 9px;
  height: fit-content;
  box-sizing: border-box;
  padding-bottom: 15px;
  padding-top: 15px;
}
@media ${device.mobileL} {
  width: 350px;
  font-size: 15px;
  height: fit-content;
  box-sizing: border-box;
}
`;

export const InputCastom = styled.input`
 position: absolute;
  z-index: -1;
  opacity: 0;
  &:checked + ${ItemLabel}:after {
    border-color: #309da1;
    background-image:url(${check});
    
  }
  &:checked + ${ItemLabel} {
    border-color: #309da1;
  }
  
`;
export const Button = styled.button`
  width: 252px;
  height: 58px;
  border-radius: 29px;
  background-color: #20babf;
  border:none;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  font-family: Montserrat-Medium;
  margin:39px 0 42px 0;
  @media ${device.mobileS} {
    width: 152px;
    height: 48px;
    
  }

`;

export const GoBack = styled.a`
 *{
  position: absolute;
  top: 10px;
  left: 10px;
 }

`;