import React, { useEffect, useState } from "react";
import searchlogo from "../../../img/search.png";
import {
  SearchForm,
  SearchFormInput,
  SearchFormButtonLabel,
  SearchFormButtonCircle,
  SearchFormButtonText,
  SearchFormInputLabel,
  CircleImg,
} from "./styledSearch";
import { Form } from "react-final-form";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from "@material-ui/core";
import { isEmpty as _isEmpty } from 'lodash';


const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#20babf"
    }
  }
})(TextField);

let cameFromHome = false; // define se a search está a ser chamada na pagina principal o Directory
let propsCallback = undefined;

const Search = (props) => {
  const [search, setSearch] = useState("");
  propsCallback = props.callback;

  // ---------
  // Callbacks
  // ---------

  if (propsCallback === undefined) {
    // entao não vem do Directory mas sim da pagina principal
    cameFromHome = true;
    propsCallback = (search, applyfilters) => window.location = '/directory?title=' + search;
  }else{
    cameFromHome = false;
  }

  // funcao auxiliar que faz refresh do filtro Search
  const executeCallback = (search, applyAllfilters = true) => propsCallback(search, applyAllfilters);

  const onSubmit = async (values) => {
    if (cameFromHome) {
      // deve ser assim o if porque so devemos validar se esta vazio quando
      // vimos da pagina principal
      // do directory temos de ter em atencao aos filtros e tem de passar vazio
      if (!_isEmpty(values.search)) {
        executeCallback(values.search);
      }
    } else {
      executeCallback(search);
    }
  };

  const onChangeEvent = (value) => {
    setSearch(value);

    if (value == null || value == '') {
      executeCallback(null);
    }
  };

  // ---------------
  // Defaults values
  // ---------------

  const placeholder = 'Search for Companies, Products, Certifications...';
  let subtitle = '';

  if (search && !cameFromHome) {
    subtitle = 'You searched for';
  }

  // mesmo que a funcao componentDidUpdate/Mount
  useEffect(() => {
    if (_isEmpty(search)) {
      // load search from URL if necessary
      const urlSearchParamsClass = new URLSearchParams(window.location.search);


      if (urlSearchParamsClass.has('title')) {
        setSearch(urlSearchParamsClass.get('title'));
        executeCallback(urlSearchParamsClass.get('title'), false);

      }
    }
    //executeCallback()
  });

  return (
    <SearchForm {...props}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <>
            <form onSubmit={handleSubmit}>
              <SearchFormInputLabel >
                {subtitle}
                {props.companies ?
                  <Autocomplete
                    freeSolo
                    disableClearable
                    options={[].map((option) => option.title)}
                    value={search}
                    //onChange={(ev, val) => {onChangeEvent(val); handleSubmit()}}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        {...props}
                        name="search"

                        placeholder={placeholder}
                        margin="normal"

                        onChange={(ev, val) => {onChangeEvent(ev.target.value); }}
                      />
                    )}
                  />
                  :
                  <SearchFormInput
                    {...props}
                    name="search"
                    component="input"
                    placeholder={placeholder}
                  />
                }
              </SearchFormInputLabel>

              <SearchFormButtonLabel {...props}>
                <SearchFormButtonText>SEARCH</SearchFormButtonText>
                <SearchFormButtonCircle>
                  <CircleImg src={searchlogo} alt={search} />
                </SearchFormButtonCircle>
              </SearchFormButtonLabel>
            </form>
          </>
        )}
      />
    </SearchForm>
  );
};

export default Search;
