import {UPDATE_IMAGE} from "./actionTypes";

const initialState = "";

export const updateImageReducer= (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_IMAGE:
      return {...state, ...action.payload};
    default:
      return state;
  }
};