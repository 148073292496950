import React from "react";
import AddBrandFrom from "./AddBrandFrom";
import { Field, useField } from "react-final-form";
import styled from "styled-components";
import AddCertificationsFrom from "./AddCertificationsFrom";
import { RecommendationStrong } from "./AddCategoryForm";
import { Br, device, RequiredCircle } from "./../../commons/commonsStyled/index";
import AddAwardsFrom from "./AddAwardsFrom";
import {
  InputContainer,
  Label,
  LabelText,
  NynPage,
  Page,
  Title,
  Recommendation,
} from "./styled/commonStyled";
import { useDispatch, useSelector } from "react-redux";
import { updateImageTest } from "../../../redux/actions";
import { ContainerHelper, Example, ExampleButton, ExampleText, Helper, HelperLogo, HelperText, Strong } from "./InformationAboutCompany";
import helperImg from "../../../img/helper.png";
let posY = 0;
const InformationBrandCompany = ({ values }) => {
  window.scrollTo({
    top: posY,
  });
  const dispatch = useDispatch();
  const radio = useSelector((root) => root.imageObj.radio);

  const updateRadio = (value) => {
    posY = window.pageYOffset;
    dispatch(updateImageTest({ radio: value }));
  };

  const Error = ({ name }) => {
    const {
      meta: { touched, error },
    } = useField(name, { subscription: { touched: true, error: true } });
    return touched && error ? (
      <span style={{ color: "red" }}>{error}</span>
    ) : null;
  };
  return (
    <>
      <ContainerHelper>
        <Helper>
          <HelperText>
            Se tiver alguma dúvida no preenchimento deste formulário, por favor{" "}
            <br /> contacte a <Strong>Market Division</Strong> da{" "}
            <Strong>PortugalFoods</Strong> através do
            <Strong> 220 944 476</Strong>
          </HelperText>
          <HelperLogo>
            <img src={helperImg} alt="img-helper" />
          </HelperLogo>
        </Helper>
        <Br />
        <Example>
          <ExampleText>
            Clique para ver um exemplo de uma página de Empresa
          </ExampleText>
          <ExampleButton to="/directory/PortugalFoods" target="_blank">EXEMPLO PÁGINA DE EMPRESA</ExampleButton>
        </Example>
        <Br />
      </ContainerHelper>
      <Page>
        <Title>
          <NynPage>3. </NynPage> Informação da Marca
      </Title>
        <InputContainer>
          <Label>
            <LabelText>Indique as principais marcas da sua empresa <RequiredCircle style={{ display: "inline-block" }} /><Error name="brands" /></LabelText>
            <Recommendation>(máximo de 4 marcas)</Recommendation>
          </Label>
          <AddBrandFrom />
        </InputContainer>
        <InputContainer>
          <LabelText>A sua empresa produz para Private Label? <RequiredCircle style={{ display: "inline-block" }} /><Error name="private_label" /></LabelText>

          <InputContainerPrivate>
            <LabelPrivate>
              Sim
            <FieldPrivate
                name="private_label"
                component="input"
                type="radio"
                value="1"
                checked={radio == "1"}
                onClick={(event) => updateRadio(1)}
              />
            </LabelPrivate>
            <LabelPrivate>
              Não
            <FieldPrivate
                name="private_label"
                component="input"
                type="radio"
                value="0"
                checked={radio == "0"}
                onClick={(event) => updateRadio(0)}
              />
            </LabelPrivate>
          </InputContainerPrivate>
          <Br />
        </InputContainer>
        <InputContainer>
          <LabelText>Certificações</LabelText>
          <RecommendationStrong>
            Quais as certificações existentes na sua empresa / produtos?
        </RecommendationStrong>
          <AddCertificationsFrom />
        </InputContainer>
        <InputContainer>
          <LabelText>Distinções</LabelText>

          <Recommendation>
            <RecommendationStrong>
              Caso a sua empresa tenha recebido prémios internacionais, por favor
              indique os mais importantes.
        </RecommendationStrong><br />(máximo de 5 distinções). </Recommendation>
          <AddAwardsFrom />
        </InputContainer>
      </Page>
    </>
  );
};

export default InformationBrandCompany;

export const LabelPrivate = styled.label`
  display: flex;
  align-items: center;
  font-size: 19px;
  @media ${device.mobileS} {
    font-size: 14px;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
`;
export const FieldPrivate = styled(Field)`
  width: 36px;
  height: 36px;
  margin-right: 42px;
  @media ${device.mobileS} {
    width: 20px;
    height: 20px;
  }
  @media ${device.laptop} {
    width: 36px;
    height: 36px;
  }
`;
export const InputContainerPrivate = styled(InputContainer)`
  display: flex;
`;
