import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ControlledAccordions from "./ControlledAccordions";
import styled from "styled-components";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box p={3}>
        <div>{children}</div>
        // </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  subtitle: {
    color: '#20babf',
    fontFamily: 'Montserrat-Bold !important',
    fontSize: 18,
    marginBottom: 35,
  },
  tabs: {
    marginBottom: "20px",
    boxShadow: "0px 0px",
    borderBottom: "2px solid #20BABF",
  },
  tabFalse: {
    marginRight: "2px",
    backgroundColor: " #ebebeb",
    color: "#A2A2A2",


  },
  tabTrue: {
    marginRight: "2px",
    backgroundColor: "#20babf",
    color: "white",
  },
}));

export default function ScrollableTabsButtonAuto(props = {}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const company = props.company || { categories: [], products: [] };
  const categories = props.categories || {};

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCategoryByID = (id) => {
    const element = categories.find(element => (element.id == id));

    return element ? element.name : "";
  }

  return (
    <div className={classes.root}>
      <p className={classes.subtitle}>Below you will find some of the products manufactured by this company.</p>

      <AppBar position="static" color="default" className={classes.tabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs example"
          indicator="false"

          TabIndicatorProps={{
            style: {
              display: "none"
            }
          }}

        >

          {
            'category' in company ?
              company.category.map(
                (elm, iterator) => {
                  return (
                    <TabItem
                      className={value === iterator ? classes.tabTrue : null}
                      label={getCategoryByID(elm.category)}
                      {...a11yProps(iterator)}
                    />
                  )
                }
              ) : <></>}


        </Tabs>
      </AppBar>

      {
        'category' in company ?
          company.category.map(
            (elm, iterator) => {
              return (
                <TabPanel value={value} index={iterator} >
                  <ControlledAccordions company={company} companyCategory={elm} />
                </TabPanel>
              )
            }
          ) : <></>}

      {/*
       
          'products' in company ?
          company.products.map(
            (elm,iterator) => {
                return(
                  <TabPanel value={value} index={iterator}>
                  <ControlledAccordions company={company} categories={categories}  />
                    </TabPanel>
                )
            } 
          ): <></>*/}


      { /*  <TabPanel value={1} index={0} >
        <ControlledAccordions company={company} categories={categories} />
      </TabPanel>
      
     <TabPanel value={value} index={1}>
        <ControlledAccordions />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ControlledAccordions />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ControlledAccordions />
        </TabPanel>*/}

    </div>
  );
}

export const TabItem = styled(Tab)`
  font-size:9px;
  font-family: Montserrat-Bold !important;
`;