import {SEND_FEEDBACK_ERROR} from "./actionTypes";

const initialState = "";

export const sendFeedbackErrorReducer= (state = initialState, action) => {
  switch (action.type) {
    case SEND_FEEDBACK_ERROR:
      return action.payload
    default:
      return state;
  }
};