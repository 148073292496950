import React, { useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import addfilter from "../../../img/addfilter.png";
import close from "../../../img/close-claims.svg";
import { device } from "./../../commons/commonsStyled/index";
import {
  ButtonImg,
  ButtonText,
  RefineSeachButton,
  Img,
} from "../../pages/Directory";
import {
  InputCastom,
  ItemLabel,
  ModalContainer,
  Button,
  List,
} from "./AddCategoryField";

const customStyles = {
  content: {
    width: "625px",
    height: "695px",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "solid 1px #309da1",
  },
};
const root = document.getElementById("root");
const AddClaimsField = ({
  input: { value, onChange, onBlur, onFocus },
  meta,
  claims,
}) => {
  const [open, setOpen] = useState(false);
  const [isOpenChild, setOpenChild] = useState([false, 0]);
  const [newClaims, setNewClaims] = useState([]);

  console.log("this doesnt seems ok",claims);
  const handleClose = useCallback(() => {
    setOpen(false);
    setOpenChild([false,0]);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);


  const handleChangeClaims = useCallback(
    (e) => {
      const newValue = [...value];
      let claim = { id: e.target.id, name: e.target.value };

      // let find = newClaims.some(element => element.id === claim.id)
      // if(!find) newValue.push(claim);
      if (e.target.checked) {
        newValue.push(claim);
        setNewClaims([...newClaims, claim]);
      }
      if (!e.target.checked) {
        let index = newValue.findIndex((element) => element.id === claim.id);
        newValue.splice(index, 1);
        newClaims.splice(index, 1);
      }
      onChange(newValue);
    },
    [onChange, value]
  );
  const handleDeleteClaims = useCallback(
    ({ index }) => {
      const newValue = [...value];
      newValue.splice(index, 1);
      newClaims.splice(index, 1);
      onChange(newValue);
    },
    [onChange, value, newClaims]
  );

  const handleChecked = useMemo(() => {
    return newClaims;
  }, [newClaims]);

  return (
    <>
      <ClaimsList>
        {value
          ? value.map((item, index) => (
            <Claim key={item.name}>
              <ClaimText>{item.name}</ClaimText>
              <ClaimDeleteButton
                type="button"
                onClick={() => handleDeleteClaims({ index })}
              />
            </Claim>
          ))
          : null}
      </ClaimsList>
      <RefineSeachButton type="button" onClick={handleOpen}>
        <ButtonText>Adicionar Claims</ButtonText>
        <ButtonImg>
          <Img src={addfilter} alt="addfilter" />
        </ButtonImg>
      </RefineSeachButton>

      <Modal
        appElement={root}
        isOpen={open}
        onRequestClose={handleClose}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ModalContainer>
          <TitleModal>Select the claims</TitleModal>

          {!isOpenChild[0] ?  
          <>
          <List>
            {claims
              ? claims.map((item) => (
                <div key={item.id}>
                  {item.children.length ?
                    <>
                      <InputCastom
                        id={item.id}
                        name="claims"
                        value={item.name}
                        type="checkbox"    
                        onClick={() => { setOpenChild([true, item.id])}}    
                      />
                      <ItemLabelNoBall htmlFor={item.id} style={{position:"relative"}}><Arrow
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.889"
                        height="28.25"
                        viewBox="0 0 15.889 28.25"
                      >
                        <path
                          id="Контур_300"
                          fill="none"
                          data-name="Контур 300"
                          d="M0,0,14.806,14.322,0,27.517"
                          transform="translate(0.348 0.359)"
                        /></Arrow>{item.name}</ItemLabelNoBall>
                    </>
                    :
                    //NAO TEM FILHOS
                    <>
                    <InputCastom
                      id={item.id}
                      name="claims"
                      value={item.name}
                      type="checkbox"
                      onChange={handleChangeClaims}
                      checked={
                        !!handleChecked?.some((element) => {
                          return element.id == item.id;
                        })
                      }
                    />
                    <ItemLabel htmlFor={item.id}>{item.name}</ItemLabel>
                  </>

                  }
                </div>
              ))
              : null}
          </List>
          </>
          
          : 
          <>
          <List>
          
            {claims ? claims.find(x=>x.id == isOpenChild[1]).children.map((item) => (
              <div key={item.id}>               
                 <>
                      <InputCastom
                        id={item.id}
                        name="claims"
                        value={item.name}
                        type="checkbox"
                        onChange={handleChangeClaims}
                        checked={
                          !!handleChecked?.some((element) => {
                            element.parent = claims.find(x=>x.id == isOpenChild[1]).name;
                            return element.id == item.id;
                          })
                        }
                      />
                      <ItemLabel htmlFor={item.id}>{item.name}</ItemLabel>
                    </>
              </div>
            )) : null}
          </List>
        </>

          
          }
     <Button onClick={handleClose} style={{display:"block", margin:"auto"}}>Confirmar</Button>
        </ModalContainer>

      </Modal>
    </>
  );
};

export default AddClaimsField;
export const TitleModal = styled.p`
  font-size: 38px;
  color: #4e4e4e;
  margin: 60px 0 50px 0;
`;
export const ClaimsList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const Claim = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-radius: 36px;
  border: solid 1px #20babf;
  background-color: #ffffff;
  font-family: Montserrat-Medium;
  font-weight: 500;
  margin-right: 19px;
  margin-top: 8px;
  margin-bottom: 6px;
  padding: 0 16px 0 18px;
  box-sizing: border-box;
`;
export const ClaimText = styled.p`
  color: #209ebf;
  @media ${device.mobileS} {
    font-size: 11px;
  }
  @media ${device.tablet} {
    font-size: 16px;
  }
`;
export const ClaimDeleteButton = styled.button`
  display:flex;
  width: 12px;
  height: 12px;
  border:none;
  background-color:transparent;
  background-image:url("${close}");
  background-size:cover;
  margin-left:25px;
`;


export const Arrow = styled.svg`
  position: absolute;
  right: 5%;
  path {
    stroke: #aaa;
  }
  @media ${device.mobileS} {
    height: 15px;
    
  }
  @media ${device.mobileL} {
    height: 20px;
    
  }
`;

export const ItemLabelNoBall = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 504px;
  height: 58px;
  border-radius: 36px;
  border: solid 1px;
  border-color: #aaaaaa;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: Montserrat-Bold;
  color: #aaaaaa;
  padding: 0 27px 0 27px;
  
    @media ${device.mobileS} {
      width: 15px;
      height: 15px;
    }
    @media ${device.mobileL} {
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    border-color: #309da1;
    path {
      stroke: #309da1;
    }
  }
  @media ${device.mobileS} {
    width: 280px;
    font-size: 9px;
    height: 30px;
    box-sizing: border-box;
  }
  @media ${device.mobileL} {
    width: 350px;
    font-size: 15px;
    height: 50px;
    box-sizing: border-box;
  }
`;

