import React from "react";
import {
  InputContainer,
  Label,
  LabelText,
  NynPage,
  Page,
  Title,
  Recommendation,
} from "./styled/commonStyled";
import { FieldInputSmall } from "./InformationAboutCompany";
import styled from "styled-components";
import { Field } from "react-final-form";
import { device } from "./../../commons/commonsStyled/index";
import { Br } from "../../commons/commonsStyled";
import { ContainerHelper, Example, ExampleButton, ExampleText, Helper, HelperLogo, HelperText, Strong } from "./InformationAboutCompany";
import helperImg from "../../../img/helper.png";

const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span style={{ color: "red" }}>{error}</span> : null
    }
  />
);

const CompanyContacts = () => {
  window.scrollTo({
    top: 0,
  });
  return (
    <>
      <ContainerHelper>
        <Helper>
          <HelperText>
            Se tiver alguma dúvida no preenchimento deste formulário, por favor{" "}
            <br /> contacte a <Strong>Market Division</Strong> da{" "}
            <Strong>PortugalFoods</Strong> através do
            <Strong> 220 944 476</Strong>
          </HelperText>
          <HelperLogo>
            <img src={helperImg} alt="img-helper" />
          </HelperLogo>
        </Helper>
        <Br />
        <Example>
          <ExampleText>
            Clique para ver um exemplo de uma página de Empresa
          </ExampleText>
          <ExampleButton to="/directory/PortugalFoods" target="_blank">EXEMPLO PÁGINA DE EMPRESA</ExampleButton>
        </Example>
        <Br />
      </ContainerHelper>
      <Page>
        <Title>
          <NynPage>6. </NynPage> Contacto do representante da empresa
        <Recommendation>
            No perfil da empresa serão indicados os contactos gerais da sua
            empresa. No entanto, se quiser,
          <br /> pode disponibilizar aos potenciais compradores o contacto de um
          representante da empresa
        </Recommendation>
        </Title>
        <InputContainer>
          <LabelText>Nome</LabelText>
          <FieldInput
            name="contact_first_name"
            component="input"
            placeholder="Primeiro Nome"
          />

          <FieldInput
            name="contact_last_name"
            component="input"
            placeholder="Último Nome"
          />
        </InputContainer>
        <InputContainer>
          <Container>
            <Label>
              <LabelText>Cargo</LabelText>
              <FieldInput
                name="contact_role"
                component="input"
                placeholder="Por ex.: Diretor Comercial"
              />
            </Label>
            <Label>
              <LabelText>Email <Error name="contact_email" /></LabelText>
              <FieldInput name="contact_email" component="input" />
            </Label>
          </Container>
        </InputContainer>
        <InputContainer>
          <Label>
            <LabelText>Telefone</LabelText>
            <FieldInputTel>
              <FieldInputTelIndicativo
                name="contact_phone_indicative"
                component="input"
                type="text"
              />
              <FieldInputSmallTel name="contact_phone" component="input" />
              <Error name="contact.telefone" />
            </FieldInputTel>
          </Label>
        </InputContainer>
      </Page>
    </>
  );
};

export default CompanyContacts;

export const Container = styled.div`
  @media ${device.mobileS} {
    display: block;
    margin-bottom: 20px;
  }
  @media ${device.laptop} {
    display: flex;
    margin-bottom: 0px;
  }
`;
export const FieldInput = styled(FieldInputSmall)`
  @media ${device.mobileS} {
    width: 280px;
    margin-bottom: 20px;
  }
  @media ${device.mobileL} {
    width: 380px;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
    width: 400px;
  }
`;
export const FieldInputTel = styled.div`
  position: relative;
  // &:before {
  //   content: "+351";
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   position: absolute;
  //   width: 101px;
  //   height: 52px;
  //   object-fit: contain;
  //   border-radius: 27px;
  //   border: solid 1px #e27f7f;
  //   background-color: rgba(226, 127, 127, 0.16);
  //   @media ${device.mobileS} {
  //     height: 40px;
  //     font-size: 12px;
  //   }

  //   @media ${device.laptop} {
  //     height: 50px;
  //   }
  //   @media ${device.laptopL} {
  //     height: 50px;
  //   }
  // }
`;

export const FieldInputTelIndicativo = styled(FieldInputSmall)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 101px !important;
  height: 52px;
  object-fit: contain;
  border-radius: 27px;
  border: solid 1px #e27f7f;
  background-color: rgba(226, 127, 127, 0.16);
  text-align: center;
  @media ${device.mobileS} {
    height: 40px;
    font-size: 12px;
  }

    @media ${device.laptop} {
      height: 50px;
    }
    @media ${device.laptopL} {
      height: 50px;
    }
  @media ${device.laptop} {
    height: 50px;
  }
  @media ${device.laptopL} {
    height: 50px;
  }
`;

export const FieldInputSmallTel = styled(FieldInputSmall)`
  width: 436px;
  padding-left: 120px;
`;
