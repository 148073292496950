import React, {useEffect,Fragment} from "react";
import Navigation from "../commons/Navigation";
import { Wrapper, Container, HeaderContainer, Title,ContentContainer } from "../commons/commonsStyled/index";
import Logo from './../commons/Logo';
import Footer from "../commons/Footer";
import { useDispatch,useSelector } from 'react-redux';
import { fetchHowItWorks } from './../../redux/actions';
import styled from "styled-components";


const About = () => {
  const dispatch = useDispatch()
  const result= useSelector((root) => root.howItWorks.data);
  console.log(result)
  useEffect(() => {
    dispatch(fetchHowItWorks());
    
    }, []);
  
  
  return (
    <>
    <Wrapper>
      <Container>
        <HeaderContainer>
          <Logo />
          <Navigation />
        </HeaderContainer>
       
      </Container>
      <ContentContainer>
        <Title>How it works</Title>
        <Content>
         {result ?  <div dangerouslySetInnerHTML={{__html: result.description}}></div> : null}
        </Content>
      </ContentContainer>
    </Wrapper>
    <Footer/>
    </>
  );
};

export default About;

export const Content = styled.div`
  margin-top:50px;
  h1 {
    color: #3f424c;
  }
  p {
    margin-top:20px;
    line-height: 1.67;
    color: #3f424c;
    font-size:20px;
  }
`;