import React from "react";
import styled from "styled-components";
import { Field, useField } from "react-final-form";
import MyDropzone from "./MyDropzone";
import downloadImg from "../../../img/download-img.png";
import { Br, device, RequiredCircle } from "../../commons/commonsStyled";
import {
  Page,
  Title,
  NynPage,
  Label,
  LabelText,
  InputContainer,
} from "./styled/commonStyled";
import helperImg from "../../../img/helper.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateImageTest } from "../../../redux/actions";

window.scrollTo({
  top: 0,
});

const InformationAboutCompany = (props) => {
  const dispatch = useDispatch();

  const img = useSelector((root) => root.imageObj.logo_url);
  const logo_url = useSelector((root) => root.imageObj.products_image_url);
  const descriptionVal = useSelector((root) => root.imageObj.description);

  const Error = ({ name }) => {
    const {
      meta: { touched, error },
    } = useField(name, { subscription: { touched: true, error: true } });
    return touched && error ? (
      <span style={{ color: "red" }}>{error}</span>
    ) : null;
  };

  return (
    <>
      <ContainerHelper>
        <Helper>
          <HelperText>
            Se tiver alguma dúvida no preenchimento deste formulário, por favor{" "}
            <br /> contacte a <Strong>Market Division</Strong> da{" "}
            <Strong>PortugalFoods</Strong> através do
            <Strong> 220 944 476</Strong>
          </HelperText>
          <HelperLogo>
            <img src={helperImg} alt="img-helper" />
          </HelperLogo>
        </Helper>
        <Br />
        <Example>
          <ExampleText>
            Clique para ver um exemplo de uma página de Empresa
          </ExampleText>
          <ExampleButton to="/directory/PortugalFoods" target="_blank">EXEMPLO PÁGINA DE EMPRESA</ExampleButton>
        </Example>
        <Br />
      </ContainerHelper>
      <Page>
        <Title>
          {" "}
          <NynPage>1.</NynPage> Informação da Empresa
        </Title>

        <InputContainer>
          <Label>
            <LabelText>
              Nome comercial da empresa <RequiredCircle style={{ display: "inline-block" }} /><Error name="business_name" />
            </LabelText>

            <FieldInput name="business_name" component="input" type="text" />
          </Label>
        </InputContainer>
        <InputContainer onClick={(ev) => { ev.preventDefault(); }}>
          <Label>
            <LabelText>
              Upload de logotipo da empresa <RequiredCircle style={{ display: "inline-block" }} /><Error name="logo_url" />
            </LabelText>
            <InputFileContainer>
              <InputFile onClick={(ev) => { document.getElementById('logo_url_element').click() }}>
                {img ? (
                  <DownloadImg src={img} />
                ) : (
                    <>
                      <img src={downloadImg} alt="download-img" />
                      <InputFileText>
                        <Strong>JPEG/PNG</Strong>
                      1080 x 1080 px
                    </InputFileText>
                    </>
                  )}
              </InputFile>
              <InputFileDesc>
                Formato jpg ou png.
                <br />
                <br />
                Dimensões:
                <br />
                1080 x 1080 px
                <br />
                        <br />
                        Tamanho:
                        <br />
                        1mb
              </InputFileDesc>
            </InputFileContainer>
            <Field name="logo_url">
              {(props) => (
                <MyDropzone
                  validateSize={[1080, 1080]}
                  sendImg={(img) => dispatch(updateImageTest({ logo_url: img }))}
                  {...{ ...props.input, id: 'logo_url_element' }}
                />
              )}
            </Field>
          </Label>
        </InputContainer>
        <InputContainer>
          <Label>
            <LabelText>
              Breve descrição da sua empresa (texto em inglês e com um máximo de
              1000 caracteres).
              <RequiredCircle style={{ display: "inline-block" }} /><Error name="description" />
            </LabelText>
            <TextareaContainer>
              <FieldTextarea
                desc="252px"
                name="description"
                component="textarea"
                type="text"
                onKeyUp={e => dispatch(updateImageTest({ description: e.target.value }))}
              />
              <TextareaDesc>
                Para dar a conhecer a sua empresa a potenciais compradores.
                Alguns tópicos que podem ser úteis: Quem somos? O que fazemos? O
                que nos diferencia?
                <br />
                <p
                  style={descriptionVal == null || descriptionVal.length < 1000 ? { color: 'black', textAlign: 'left', fontSize: 'small' }
                    : { color: 'red', textAlign: 'left', fontSize: 'small' }}>
                  {(descriptionVal || '').length}/1000
              </p>
              </TextareaDesc>

            </TextareaContainer>

          </Label>
        </InputContainer>


        <InputContainer onClick={(ev) => { ev.preventDefault(); }}>
          <Label>
            <LabelText>
              Carregue uma imagem representativa dos principais produtos da sua
              empresa.
              <RequiredCircle style={{ display: "inline-block" }} /><Error name="products_image_url" />
            </LabelText>
            <TextareaContainer>
              <InputFileRepresentative onClick={(ev) => { document.getElementById('products_image_url').click() }}>
                {logo_url ? (
                  <DownloadImg src={logo_url} />
                ) : (
                    <>
                      <img src={downloadImg} alt="download-img" />
                      <InputFileText>
                        <Strong>JPEG/PNG</Strong>
                      2048 x 1080 px
                    </InputFileText>
                    </>
                  )}
              </InputFileRepresentative>
              <TextareaDesc>
                Esta é a primeira imagem que o comprador vai ver dos seus
                produtos.
                <br /> Idealmente, esta imagem deve conter o logótipo da empresa
                e publicitar a<br /> gama de produtos ou novos produtos que
                queira destacar.
                <br />
                <br />
                Formato jpg ou png.
                <br />
                Dimensões: 2048 x 1080 px
                <br />
                        <br />
                        Tamanho:
                        <br />
                        1mb
              </TextareaDesc>

            </TextareaContainer>
            <Field name="products_image_url" component="input">
              {(props) => (
                <MyDropzone
                  validateSize={[2048, 1080]}
                  sendImg={(logo_url) => dispatch(updateImageTest({ products_image_url: logo_url }))}
                  {...{ ...props.input, id: 'products_image_url' }}
                />
              )}
            </Field>
          </Label>
        </InputContainer>
        <List>
          <InputContainer>
            <Label>
              <LabelText>Website da empresa  <RequiredCircle style={{ display: "inline-block" }} /><Error name="website_url" /></LabelText>
              <FieldInputSmall
                name="website_url"
                component="input"
                placeholder="https://www.companywebsite.com"
                type="text"
              />
            </Label>
          </InputContainer>
          <InputContainer>
            <Label>
              <LabelText>Telefone geral da empresa<RequiredCircle style={{ display: "inline-block" }} /><Error name="company_phone" /></LabelText>
              <FieldInputTel>
                <FieldInputTelIndicativo
                  name="company_phone_indicative"
                  component="input"
                  type="text"
                />
                <FieldInputSmallTel
                  name="company_phone"
                  component="input"
                  type="text"
                />
              </FieldInputTel>
            </Label>
          </InputContainer>
          <InputContainer>
            <Label>
              <LabelText>Email geral da empresa <RequiredCircle style={{ display: "inline-block" }} /><Error name="company_email" /></LabelText>
              <FieldInputSmall
                name="company_email"
                component="input"
                type="text"
              />
            </Label>
          </InputContainer>
          <InputContainer>
            <Label>
              <LabelText>
                Vídeo, em inglês, de apresentação da empresa / produtos{" "}
                <br />
                <Error name="video_url" />
              </LabelText>

              <FieldInputSmall
                name="video_url"
                component="input"
                placeholder="Youtube video link"
                type="text"
              />
            </Label>
          </InputContainer>
        </List>
      </Page>
      <Br />
    </>
  );
};

export default InformationAboutCompany;

export const charactersIndicatorAlert = styled.div`
  color:red;
`;

export const charactersIndicator = styled.div`
  color:pink;
`;

export const ContainerHelper = styled.div`
  margin-bottom: 113px;
`;
export const Helper = styled.div`
  display: flex;
  align-items: center;
  margin: 7px 0 16px 0;
`;
export const HelperText = styled.p`
  font-size: 30px;
  color: #3f424c;
  margin-right: 30px;
  @media ${device.mobileS} {
    font-size: 14px;
  }
  @media ${device.laptop} {
    font-size: 18px;
  }
`;
export const Strong = styled.span`
  font-family: Montserrat-Bold;
  font-weight: bold;
`;
export const HelperLogo = styled.div``;
export const Example = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 40px 0;
  @media ${device.mobileS} {
    flex-direction: column;
    align-items: flex-start;
  }
  @media ${device.laptop} {
    flex-direction: row;
    align-items: baseline;
  }
`;
export const ExampleText = styled.p`
  color: #3f424c;
  margin-right: 24px;
  @media ${device.mobileS} {
    margin-bottom: 20px;
    font-size: 14px;
    min-width: 253px;
  }
  @media ${device.tablet} {
    width: 223px;
  }
  @media ${device.laptop} {
    min-width: 523px;
    font-size: 18px;
  }
`;
export const ExampleButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 323px;
  height: 44px;
  object-fit: contain;
  border-radius: 29px;
  background-color: #20babf;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  @media ${device.mobileS} {
    width: 284px;
    font-size: 12px;
  }
  @media ${device.tablet} {
    width: 323px;
  }
`;

export const FieldInput = styled(Field)`
  width: 1104px;
  height: 54px;
  height: ${(props) => props.desc};
  object-fit: contain;
  border-radius: 27px;
  border: solid 1px rgba(63, 66, 76, 0.3);
  background-color: #ffffff;
  outline: none;
  padding: 20px;
  font-size: 18px;
  box-sizing: border-box;
  @media ${device.mobileS} {
    width: 280px;
    height: 40px;
  }
  @media ${device.mobileL} {
    width: 380px;
    height: 40px;
  }
  @media ${device.tablet} {
    width: 500px;
  }
  @media ${device.laptop} {
    width: 700px;
    height: 52px;
  }
`;
export const FieldInputSmall = styled(FieldInput)`
  max-width: 536px;

  @media ${device.mobileS} {
    width: 280px;
    font-size: 12px;
  }
  @media ${device.mobileL} {
    width: 380px;
    font-size: 12px;
  }
  @media ${device.laptop} {
    margin-right: 26px;
  }
  @media ${device.laptopL} {
    width: 536px;
    font-size: 14px;
    margin-right: 40px;
  }
`;

export const InputFileContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const TextareaContainer = styled(InputFileContainer)`
  @media ${device.mobileS} {
    flex-direction: column;
    align-items: flex-start;
  }
  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

export const InputFile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 221px;
  height: 233px;
  object-fit: contain;
  border-radius: 27px;
  overflow: hidden;
  border: solid 1px rgba(63, 66, 76, 0.3);
  background-color: #ffffff;
  cursor: pointer;
  @media ${device.mobileS} {
    max-width: 221px;
    minheight: 221px;
  }
`;

export const InputFileRepresentative = styled(InputFile)`
  width: 416px;
  height: 244px;
  @media ${device.mobileS} {
    max-width: 286px;
  }
  @media ${device.mobileL} {
    max-width: 380px;
  }
`;
export const DownloadImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const InputFileText = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-top: 19px;
`;

export const InputFileDesc = styled.label`
  font-size: 16px;
  color: #556fab;
  margin-left: 47px;
  @media ${device.mobileS} {
    font-size: 11px;
    margin-left: 17px;
  }
  @media ${device.laptop} {
    font-size: 14px;
  }
`;
export const TextareaDesc = styled(InputFileDesc)`
  width: 300px;
  @media ${device.mobileS} {
    margin-left: 0px;
    font-size: 11px;
    margin-top: 10px;
  }
  @media ${device.tablet} {
    margin-left: 20px;
    font-size: 14px;
  }
`;

export const FieldImg = styled(Field)`
  display: none;
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;
export const FieldInputTel = styled.div`
  position: relative;
  // &:before {
  //   content: "+351";
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   position: absolute;
  //   width: 101px;
  //   height: 52px;
  //   object-fit: contain;
  //   border-radius: 27px;
  //   border: solid 1px #e27f7f;
  //   background-color: rgba(226, 127, 127, 0.16);
  //   @media ${device.mobileS} {
  //     height: 40px;
  //     font-size: 12px;
  //   }

  //   @media ${device.laptop} {
  //     height: 50px;
  //   }
  //   @media ${device.laptopL} {
  //     height: 50px;
  //   }
  // }
`;

export const FieldInputTelIndicativo = styled(FieldInputSmall)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 101px !important;
  height: 52px;
  object-fit: contain;
  border-radius: 27px;
  border: solid 1px #e27f7f;
  background-color: rgba(226, 127, 127, 0.16);
  text-align: center;
  @media ${device.mobileS} {
    height: 40px;
    font-size: 12px;
  }

    @media ${device.laptop} {
      height: 50px;
    }
    @media ${device.laptopL} {
      height: 50px;
    }
  @media ${device.laptop} {
    height: 50px;
  }
  @media ${device.laptopL} {
    height: 50px;
  }
`;

export const FieldInputSmallTel = styled(FieldInputSmall)`
  width: 436px;
  padding-left: 120px;
`;
export const FieldTextarea = styled(FieldInput)`
  @media ${device.mobileS} {
    height: 200px;
  }
`;
