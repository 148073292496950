import { FETCH_HOW_IT_WORKS} from "./actionTypes";

const initialState = {};

export const howItWorksReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOW_IT_WORKS:
      return action.payload;
    default:
      return state;

  }
};