import React, { useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { addFilterClaims } from "../../redux/actions";
import { deleteFilterClaims } from './../../redux/actions';
import {

  InputCastom,
  ItemLabel,
  List,
} from "../auth/addCompany/AddCategoryField";
import { device } from "./commonsStyled";

const AddClaimsFilter = ({
  input: { value, onChange, onBlur, onFocus },
  meta,
  claims,
}) => {
  const [isOpen, setOpen] = useState([false, 0]);
  const dispatch = useDispatch();
  const listFilterClaims = useSelector((root) => root.filterClaims);

  const handleChangeClaims = useCallback((e) => {
    const newValue = [...value];
    const claim = { id: e.target.id, name: e.target.value }

    if (e.target.checked) {
      if (newValue.find(v => v.id == claim.id) === undefined) {
        newValue.push(claim);
        dispatch(addFilterClaims(claim));
      }
    }

    if (!e.target.checked) {
      let index = newValue.findIndex(element => element.id === claim.id);
      // let elem = newValue.find(element => element.id === e.target.id);
      dispatch(deleteFilterClaims(e.target.id))
      newValue.splice(index, 1)
    }

    onChange(newValue);
  }, [onChange, value, deleteFilterClaims, addFilterClaims]);

  return (
    <>
      {
        isOpen[0] ? <GoBack href="#" onClick={() => {
          if (isOpen[0] == true) {
            isOpen[0] = false;
          }
        }}>
          <Arrow
            xmlns="http://www.w3.org/2000/svg"
            width="15.889"
            height="28.25"
            viewBox="0 0 15.889 28.25"
            transform="scale(-1,1)"
          >
            <path
              id="Контур_300"
              fill="none"
              data-name="Контур 300"
              d="M0,0,14.806,14.322,0,27.517"
              transform="translate(0.348 0.359)"
            />
          </Arrow>
        </GoBack> : <></>
      }


      {!isOpen[0] ?
        <List>
          {claims ? claims.map((item) => (
            <div key={item.id}>

              {
                item.children.length ?
                  <>
                    <InputCastom
                      id={item.id}
                      name="claims"
                      value={item.name}
                      type="checkbox"
                      onClick={() => { setOpen([true, item.id]) }}

                    />
                    <ItemLabelNoBall htmlFor={item.id} style={{ position: "relative" }}>{item.name}
                      <Arrow
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.889"
                        height="28.25"
                        viewBox="0 0 15.889 28.25"
                      >
                        <path
                          id="Контур_300"
                          fill="none"
                          data-name="Контур 300"
                          d="M0,0,14.806,14.322,0,27.517"
                          transform="translate(0.348 0.359)"
                        />
                      </Arrow></ItemLabelNoBall>
                  </>
                  : <>
                    <InputCastom
                      id={item.id}
                      name="claims"
                      value={item.name}
                      type="checkbox"
                      onChange={handleChangeClaims}
                      checked={
                        !!listFilterClaims?.some((element) => {
                          return element.id == item.id;
                        })
                      }
                    />

                    <ItemLabel htmlFor={item.id}>{item.name}</ItemLabel>
                  </>
              }

            </div>
          )) : null}
        </List>
        :
        //IS OPEN
        <>
          <List>
            {/*console.log(claims.find(x=>x.id == isOpen[1]))*/}
            {claims ? claims.find(x => x.id == isOpen[1]).children.map((item) => (
              <div key={item.id}>
                <>
                  <InputCastom
                    id={item.id}
                    name="claims"
                    value={item.name}
                    type="checkbox"
                    onChange={handleChangeClaims}
                    checked={
                      !!listFilterClaims?.some((element) => {
                        return element.id == item.id;
                      })
                    }
                  />
                  <ItemLabel htmlFor={item.id}>{item.name}</ItemLabel>
                </>
              </div>
            )) : null}
          </List>
        </>

      }




    </>
  );
};

export default AddClaimsFilter;
export const TitleModal = styled.p`
  font-size: 38px;
  color: #4e4e4e;
  margin: 60px 0 50px 0;
`;

export const Arrow = styled.svg`
  position: absolute;
  right: 5%;
  path {
    stroke: #aaa;
  }
  @media ${device.mobileS} {
    height: 15px;
    
  }
  @media ${device.mobileL} {
    height: 20px;
    
  }
`;

export const GoBack = styled.a`
 *{
  position: absolute;
  top: 10px;
  left: 10px;
 }

`;

export const ItemLabelNoBall = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 504px;
  height: 58px;
  border-radius: 36px;
  border: solid 1px;
  border-color: #aaaaaa;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: Montserrat-Bold;
  color: #aaaaaa;
  padding: 0 27px 0 27px;
  
    @media ${device.mobileS} {
      width: 15px;
      height: 15px;
    }
    @media ${device.mobileL} {
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    border-color: #309da1;
    path {
      stroke: #309da1;
    }
  }
  @media ${device.mobileS} {
    width: 280px;
    font-size: 9px;
    height: 30px;
    box-sizing: border-box;
  }
  @media ${device.mobileL} {
    width: 350px;
    font-size: 15px;
    height: 50px;
    box-sizing: border-box;
  }
`;