import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { userReducer } from "./userReducer";
import { claimsReducer } from './claimsReducer';
import { certificationsReducer } from './certificationsReducer';
import {filterClaimsReducer} from './filterClaimsReducer'
import {filterCategoriesReducer} from './filterCategoriesReducer'
import { howItWorksReducer } from './HowItWorksReducer';
import { privacyPolicyReducer } from './PrivacyPolicyReducer';
import { termsOfUsageReducer } from './TermsOfUsageReducer';
import { categoriesReducer } from "./categoriesReducer";
import { loginErrorReducer } from './loginErrorReducer';
import {filterCertificationsReducer} from './filterCertificationsReducer'
import { filterPrivateLabelReducer } from './filterPrivateLabelReducer';
import { filterSearchReducer } from './filterSearchReducer';
import { sendFeedbackErrorReducer } from './sendFeedbackErrorReducer';
import { companiesReducer } from "./companiesReducer";
import { updateImageReducer } from "./updateImageReducer";
export const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  claims:claimsReducer,
  categories:categoriesReducer,
  companies:companiesReducer,
  certifications:certificationsReducer,
  filterClaims:filterClaimsReducer,
  filterCertifications:filterCertificationsReducer,
  filterPrivateLabel:filterPrivateLabelReducer,
  filterSearch:filterSearchReducer,
  filterCategories:filterCategoriesReducer,
  howItWorks:howItWorksReducer,
  privacyPolicy:privacyPolicyReducer,
  termsOfUsage: termsOfUsageReducer,
  loginError:loginErrorReducer,
  sendFeedbackError:sendFeedbackErrorReducer,
  imageObj: updateImageReducer,
});