import styled from "styled-components";
import { Field } from "react-final-form";
import { device } from "./../commonsStyled/index";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  @media ${device.mobileL} {
    box-sizing:border-box;
  }
`;
export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-family: Montserrat-Bold;
  width: 1200px;
  font-size: 18px;
  color: #3f424c;
  line-height: 1.29;
  margin-top: 25px;
  
  @media ${device.mobileS} {
    width:280px;
  }
  @media ${device.mobileL} {
    width:380px;
  }
  @media ${device.tablet} {
    width: 700px;
  }
  @media ${device.laptop} {
    width: 750px;
  }
  @media ${device.laptopL} {
    width: 1000px;
  }
  
  
  
`;
export const LabelCastomTel = styled(Label)`
  margin-right: 30px;
  width:585px;
  @media ${device.mobileS} {
    margin-right: 0px;
    width:280px;
  }
  @media ${device.mobileL} {
    width:380px;
  }
  @media ${device.tablet} {
    width: 700px;
    
  }
  @media ${device.laptop} {
    width: 750px;
  }
  @media ${device.laptopL} {
    width: 1000px;
  }
  
  
  
  
`;
export const LabelCastomWeb = styled(LabelCastomTel)`
  margin-right: 0px;
`;
export const LabelButton = styled.button`
  width: 224px;
  cursor: pointer;
  margin: 50px 0 50px 0;
  background-color: initial;
  border: none;
  outline: none;
  margin-right:30px;
  *{z-index:-1 !important;}
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
`;
export const RowCastom = styled(Row)`
  
  
  @media ${device.mobileS} {
    flex-direction: column;
    width:280px;
  }
  @media ${device.mobileL} {
    width:380px;
  }
  @media ${device.tablet} {
    width:700px;
  }
  @media ${device.laptop} {
    flex-direction: row;
    justify-content: space-between;
    width: 750px;
    *{
      width:365px;
    }
  }
  @media ${device.laptopL} {
    flex-direction: row;
    justify-content: space-between;
    width: 1000px;
    *{
      width:475px;
    }
  }



`;

export const Input = styled(Field)`
  font-family: Montserrat-Light;
  height: 44px;
  width:100%;
  padding-left: 20px;
  margin-top: 10px;
  border-radius: 27px;
  border: solid 1px rgba(63, 66, 76, 0.3);
  outline: none;
  box-sizing:border-box;
  background-color: ${props => props.submitting ? '#f5f6fa' : '#F4FAFC'};
`;


export const CastomInput = styled.input`
  font-family: Montserrat-Light;
  width: ${(props) => (props.small ? "570px" : "1200px")};
  height: 44px;
  padding-left: 20px;
  margin-top: 10px;
  border-radius: 27px;
  border: solid 1px rgba(63, 66, 76, 0.3);
  outline: none;
  margin-right: ${(props) => (props.marginRight ? "30px" : "0px")};
`;

export const Textarea = styled(Field)`
  font-family: Montserrat-Light;
  width: ${(props) => (props.small ? "570px" : "1200px")};
  height: 122px;
  padding-left: 20px;
  padding-top: 20px;
  margin-top: 5px;
  border-radius: 27px;
  border: solid 1px rgba(63, 66, 76, 0.3);
  outline: none;
  box-sizing: border-box;
  margin-right: ${(props) => (props.marginRight ? "30px" : "0px")};
  box-sizing:border-box;
  background-color: ${props => props.submitting ? '#f5f6fa' : '#F4FAFC'};
  @media ${device.mobileS} {
    width:280px;
  }
  @media ${device.mobileL} {
    width:380px;
    
  }
  @media ${device.tablet} {
    width: 700px;
  }
  @media ${device.laptop} {
    width: 750px;
  }
  @media ${device.laptopL} {
    width: 1000px;
  }
  
  
  
  
`;

export const ContentFormButtom = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.login ? "224px" : "224px")};
  height: ${(props) => (props.login ? "64px" : "64px")};
  background-color: ${props => props.submitting ? '#309da1' : '#1cb5b9'};
  color: white;
  border-radius: 50px;
  position: relative;
`;
export const ContentFormButtonText = styled.p`
  padding-left: 70px;
  font-family: Montserrat-Bold;
  font-size: 17px;
  font-weight: bold;
`;
export const InputButton = styled.input`
  display: none;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.login ? "55px" : "64px")};
  height: ${(props) => (props.login ? "55px" : "64px")};
  background-color: #309da1;
  border-radius: 50%;
  position: absolute;
  right: 0px;
`;
