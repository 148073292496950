import React from "react";
import styled from "styled-components";
import { Br, device, RequiredCircle } from "./../../commons/commonsStyled/index";
import { useField } from "react-final-form";
import {
  InputContainer,
  Label,
  LabelText,
  NynPage,
  Page,
  Title,
  Recommendation,
} from "./styled/commonStyled";
import { FieldInputSmall } from "./InformationAboutCompany";
import { ContainerHelper, Example, ExampleButton, ExampleText, Helper, HelperLogo, HelperText, Strong } from "./InformationAboutCompany";
import helperImg from "../../../img/helper.png";

const Error = ({ name }) => {
  const {
    meta: { touched, error },
  } = useField(name, { subscription: { touched: true, error: true } });
  return touched && error ? (
    <span style={{ color: "red" }}>{error}</span>
  ) : null;
};

const InformationMarketCompany = () => {
  window.scrollTo({
    top: 0,
  });
  return (
    <>
      <ContainerHelper>
        <Helper>
          <HelperText>
            Se tiver alguma dúvida no preenchimento deste formulário, por favor{" "}
            <br /> contacte a <Strong>Market Division</Strong> da{" "}
            <Strong>PortugalFoods</Strong> através do
            <Strong> 220 944 476</Strong>
          </HelperText>
          <HelperLogo>
            <img src={helperImg} alt="img-helper" />
          </HelperLogo>
        </Helper>
        <Br />
        <Example>
          <ExampleText>
            Clique para ver um exemplo de uma página de Empresa
          </ExampleText>
          <ExampleButton to="/directory/PortugalFoods" target="_blank">EXEMPLO PÁGINA DE EMPRESA</ExampleButton>
        </Example>
        <Br />
      </ContainerHelper>
      <Page>
        <Title>
          <NynPage>4. </NynPage> Informação de Mercado
      </Title>
        <InputContainer>
          <Label>
            <Recommendation>
              Estas questões não são de resposta obrigatória apesar de fornecerem
              informação importante para os compradores.
            <br /> Ficará ao seu critério se deseja incluir esta informação no
            perfil da sua empresa.
          </Recommendation>
          </Label>
          <List>
            <Item>
              <Label>
                <LabelText>Capacidade de produção anual</LabelText>
                <Field
                  name="production_capacity"
                  type="text"
                  component="input"
                  placeholder="Por ex.: 100.000 L"
                />
              </Label>
            </Item>
            <Item>
              <Label>
                <LabelText>Principais Mercados {/*<RequiredCircle style={{display:"inline-block"}} /><Error name="main_markets" />*/}</LabelText>
                <Field
                  name="main_markets"
                  type="text"
                  component="input"
                  placeholder="Por ex.: Spain, France, Brazil"
                />
              </Label>
            </Item>
            <Item>
              <Label>
                <LabelText>Principais Clientes</LabelText>
                <Field
                  name="main_sales_channels"
                  type="text"
                  component="input"
                  placeholder="Por ex.: HoReCa, Retail"
                />
              </Label>
            </Item>
          </List>
          <Br />
        </InputContainer>
      </Page>
    </>
  );
};

export default InformationMarketCompany;

export const List = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 37px 0 97px 0;
  @media ${device.mobileS} {
    flex-direction: column;
  }
  @media ${device.laptopL} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Item = styled.li`
  display: flex;
  width: 474px;
  @media ${device.mobileS} {
    margin-bottom: 20px;
  }
  @media ${device.laptopL} {
    max-width: 348px;
  }
  @media ${device.desktop} {
    max-width: 474px;
  }
`;
export const Field = styled(FieldInputSmall)`
  width: 100%;
  @media ${device.laptopL} {
    max-width: 348px;
  }
  @media ${device.desktop} {
    max-width: 474px;
  }
`;
