import React, { Fragment, useEffect } from "react";
import styled from "styled-components";

import {
  ContentContainer,
  StickyHeaderContainer,
  MyHeader,
  Wrapper,
  Container,
  Br,
} from "../commons/commonsStyled/index";
import Logo from "./../commons/Logo";
import Footer from "../commons/Footer";
import Navigation from "../commons/Navigation";
import companylogo from "../../img/companylogo.png";
import privateTrue from "../../img/private-true.png";
import ScrollableTabsButtonAuto from "./ScrollableTabsButtonAuto";
import { device } from "./../commons/commonsStyled/index";
import { SRLWrapper } from 'simple-react-lightbox';
import { StickyContainer, Sticky } from 'react-sticky';
import { fetchOneCompany, fetchCategories } from "../../redux/actions";
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from "react-router-dom";
import { Share } from "@material-ui/icons";

//Images Import
import facebooklogo from '../../img/shareIcons/facebook.png';
import linkdinlogo from '../../img/shareIcons/linkdin.png';
import maillogo from '../../img/shareIcons/mail.png';
import copySharelogo from '../../img/shareIcons/share.png';
import twitterlogo from '../../img/shareIcons/twitter.png';
import whatsUplogo from '../../img/shareIcons/whatsUp.png';
import { Snackbar } from "@material-ui/core";

import Cookies from "../commons/Cookies";
import Loading from "../commons/Loading";

const displayPhone = (phone) => {
  if (!phone) {
    return;
  }

  let indicativo = '';
  let phoneAux = phone;

  if (phone.indexOf(' ') > -1) {
    const indPhon = phone.split(' ');
    indicativo = indPhon[0] + ' ';
    phoneAux = indPhon[1] || '';
  }

  return indicativo + phoneAux.match(/.{1,3}/g).join(' ');
}

const openWebSite = (website_url) => {
  let t_website_url = website_url;

  if (website_url.indexOf('https://') == -1 && website_url.indexOf('http://') == -1) {
    t_website_url = 'https://' + website_url;
  }

  window.open(t_website_url, '_blank');
}

const Detail = () => {
  let company = {};
  let categories = {};

  let list = useSelector((root) => root.companies.companies);
  let list2 = useSelector((root) => root.categories.categories.data);

  const [open, setOpen] = React.useState(false);
  const handleClick = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [openedMobileNav, setopenedMobileNav] = React.useState(false);
  /*Convert list2 */
  if (list2 !== undefined) {
    list2 = list2.data.map(cat => {
      return {
        name: cat.name,
        id: cat.id
      }
    });
  }

  /*MAP para converter*/
  let mapAux = {};
  list = list.map(resp => {
    return {
      contact_email: resp.contact_email || '',
      contact_first_name: resp.contact_first_name || '',
      contact_last_name: resp.contact_last_name || '',
      contact_phone: resp.contact_phone || '',
      contact_role: resp.contact_role || '',
      title: resp.business_name || '',
      text: resp.description || '',
      img: resp.logo_url || companylogo,
      id: resp.id,
      slug: resp.slug || '',
      filterHead: 'Category',
      video_url: resp.video_url || '',
      website_url: resp.website_url || '',
      company_email: resp.company_email || '',
      company_phone: resp.company_phone || '',
      product_image_url: resp.products_image_url || '',
      private_label: resp.private_label || 0,
      production_capacity: resp.production_capacity,
      main_sales_channels: resp.main_sales_channels,
      main_markets: resp.main_markets,
      products_description: resp.products_description,
      products: resp.products,
      category: (resp.subcategories || []).reduce((acc, sb) => {
        if (sb.category_id in mapAux) {
          return acc;
        }
        mapAux[sb.category_id] = true;
        acc.push({
          id: sb.category_id,
          category: sb.category_id,
          subcategoty:
            resp.subcategories.filter(el => el.category_id == sb.category_id).map(
              el => ({
                id: el.id,
                name: el.name
              })
            )
        });
        return acc;
      }, []
      ),
      awards:
        [
          {
            id: 1 || '',
            name: resp.distinction_1 || ''
          },
          {
            id: 2 || '',
            name: resp.distinction_2 || ''
          },
          {
            id: 3 || '',
            name: resp.distinction_3 || ''
          },
          {
            id: 4 || '',
            name: resp.distinction_4 || ''
          },
          {
            id: 5 || '',
            name: resp.distinction_5 || ''
          }
        ],
      products_image_url_list: (resp.images || []).map((img, index) =>
      ({
        id: index,
        img: img.image_url,
      })),
      filterlist: {
        category: (resp.subcategories || []).map(sb => sb.name),
        claims: (resp.claims || []).map(sb => ({ id: sb.id, name: sb.name })),
        certifications: (resp.certifications || []).map(sb => sb.name),
      },
      brands: (resp.brands || []).map(respBrands =>
      ({
        id: respBrands.id,
        img: respBrands.picture_url,
        name: respBrands.name
      })),
      certifications: (resp.certifications || []).map(respCert =>
      ({
        id: respCert.id,
        img: respCert.picture_url,
        name: respCert.name
      }))
    }
  });

  company = (list && list.length > 0) ? list[0] : {};
  categories = (list2 && list2.length > 0) ? list2 : [];

  //TIAGO COMO DESTINGO VAZIO DE AINDA NAO ESTA CHEIO?
  // console.log('--looks--', useSelector((root) => root.companies.companies))
  // console.log('-------', company);

  function getCategoryByID(id) {
    const element = categories.find(element => (element.id == id));
    return element ? element.name : "";
  }

  let urlActual = useRouteMatch();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOneCompany(urlActual.params.id));
    dispatch(fetchCategories());
  }, []);

  return (
    <>
      {(list[0] && list[0].title === urlActual.params.id) || (list[0] && list[0].slug === urlActual.params.id) ? null : <Loading></Loading>}
      <Cookies />
      <div id="fb-root"></div>
      <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v8.0" nonce="1XRD8IUs"></script>

      <ShareElement>
        <ShareHead>
          <Share style={{ fontSize: 48 }} />
        SHARE <br />THIS<br />PAGE
        </ShareHead>
        <LinksToShare>
          <a href={'mailto:?subject=I wanted you to see this site&amp;body=Check out this site https://business.portugalfoods.org/directory/' + urlActual.params.id}
            title="Share by Email" style={{ textAlign: 'center' }}>
            <img src={maillogo} alt="Emial logo" style={{ width: 50, height: 30, marginTop: '10px' }} />
            <p style={{ textAlign: 'center', marginTop: '25px', color: '#20BABF' }}>EMAIL</p>
          </a>

          <a href={'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbusiness.portugalfoods.org%2Fdirectory%2F' + urlActual.params.id + '&amp;src=sdkpreparse'}
            target='_blank' style={{ textAlign: 'center' }}>
            <img src={facebooklogo} alt="Facebook logo" style={{ width: 50, height: 50 }} />
            <p style={{ textAlign: 'center', marginTop: '15px', color: '#20BABF' }}>FACEBOOK</p>
          </a>

          <a href={'https://www.linkedin.com/sharing/share-offsite/?url=https://business.portugalfoods.org/directory/' + urlActual.params.id} style={{ textAlign: 'center' }} target="_blank">
            <img src={linkdinlogo} alt="Linkdin logo" style={{ width: 50, height: 50 }} />
            <p style={{ textAlign: 'center', marginTop: '15px', color: '#20BABF' }}>LINKEDIN</p>
          </a>


          <a href={'http://twitter.com/share?text=' + "Found this company on the Portuguese Food and Beverage portfolio." + '&url=https://business.portugalfoods.org/directory/' + urlActual.params.id + '&hashtags=PortugalFoods'} target="_blank"
            style={{ textAlign: 'center' }}>
            <img src={twitterlogo} alt="Twitter logo" style={{ width: 50, height: 50 }} />
            <p style={{ textAlign: 'center', marginTop: '15px', color: '#20BABF' }}>TWITTER</p>
          </a>

          <a href={'whatsapp://send?text=Check this Company: https://business.portugalfoods.org/directory/' + urlActual.params.id} data-action="share/whatsapp/share" style={{ textAlign: 'center' }}>
            <img src={whatsUplogo} alt="WhatsApp logo" style={{ width: 50, height: 50 }} />
            <p style={{ textAlign: 'center', marginTop: '15px', color: '#20BABF' }}>WHATSAPP</p>
          </a>

          <a onClick={() => {
            let copyTeste = document.createElement("TEXTAREA");
            copyTeste.value = 'https://business.portugalfoods.org/directory/' + urlActual.params.id.replaceAll(" ","%20");
            copyTeste.setAttribute('readonly', '');
            copyTeste.style.position = 'absolute';
            copyTeste.style.left = '-9999px';
            document.body.appendChild(copyTeste);
            copyTeste.select();
            copyTeste.setSelectionRange(0, 99999);
            document.execCommand("copy");
            document.body.removeChild(copyTeste);
            setOpen(true);
          }} style={{ cursor: 'pointer', textAlign: 'center' }}>
            <Snackbar
              open={open}
              onClose={handleClose}
              message="Link Copied with success"
              autoHideDuration={6000}
            />
            <img src={copySharelogo} alt="Share Link logo" style={{ width: 50, height: 50 }} />
            <p style={{ textAlign: 'center', marginTop: '15px', color: '#20BABF' }}>COPY LINK</p>
          </a>
        </LinksToShare>
      </ShareElement>

      <TextShareMobile>
        <a style={{ backgroundColor: "#20BABF" }} onClick={() => setopenedMobileNav(!openedMobileNav)}>
          <Share style={{ fontSize: 35, transform: "translateY(+25%)", textAlign: "center", zIndex: 101, marginRight: "3px" }} />
        </a>

        {openedMobileNav ?
          <>
            <a href={'mailto:?subject=I wanted you to see this site&amp;body=Check out this site https://business.portugalfoods.org/directory/' + urlActual.params.id}
              title="Share by Email" style={{ textAlign: 'center' }}>
              <img src={maillogo} alt="Emial logo" style={{ width: 30, height: 20, transform: "translateY(+75%)" }} />

            </a>

            <a href={'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbusiness.portugalfoods.org%2Fdirectory%2F' + urlActual.params.id + '&amp;src=sdkpreparse'}
              target='_blank' style={{ textAlign: 'center' }}>
              <img src={facebooklogo} alt="Facebook logo" />

            </a>

            <a href={'https://www.linkedin.com/sharing/share-offsite/?url=https://business.portugalfoods.org/directory/' + urlActual.params.id} style={{ textAlign: 'center' }}>
              <img src={linkdinlogo} alt="Linkdin logo" />

            </a>


            <a href={'http://twitter.com/share?text=' + "Found this company on the Portuguese Food and Beverage portfolio." + '&url=https://business.portugalfoods.org/directory/' + urlActual.params.id + '&hashtags=PortugalFoods'} target="_blank"
              style={{ textAlign: 'center' }}>
              <img src={twitterlogo} alt="Twitter logo" />

            </a>

            <a href={'whatsapp://send?text=Check this Company: https://business.portugalfoods.org/directory/' + urlActual.params.id} data-action="share/whatsapp/share" style={{ textAlign: 'center' }}>
              <img src={whatsUplogo} alt="WhatsApp logo" />

            </a>

            <a onClick={() => {
              let copyTeste = document.createElement("TEXTAREA");
              copyTeste.value = 'https://business.portugalfoods.org/directory/' + urlActual.params.id.replaceAll(" ","%20");;
              copyTeste.setAttribute('readonly', '');
              copyTeste.style.position = 'absolute';
              copyTeste.style.left = '-9999px';
              document.body.appendChild(copyTeste);
              copyTeste.select();
              copyTeste.setSelectionRange(0, 99999);
              document.execCommand("copy");
              document.body.removeChild(copyTeste);
              setOpen(true);
            }} style={{ cursor: 'pointer', textAlign: 'center' }}>
              <Snackbar
                open={open}
                onClose={handleClose}
                message="Link Copied with success"
                autoHideDuration={6000}
              />
              <img src={copySharelogo} alt="Share Link logo" />

            </a></> : <></>
        }

      </TextShareMobile>

      <StickyContainer>
        <Wrapper>
          <Container>

            <Sticky topOffset={57}>
              {({ style, isSticky }) => <StickyHeaderContainer style={style} isSticky={isSticky} stickable>
                <MyHeader>
                  <Logo />
                  <Navigation />
                </MyHeader>
              </StickyHeaderContainer>}
            </Sticky>
          </Container>
          <ContentContainer>
            <Company>
              <CompanyHead>
                <CompanyList>
                  <CompanyLogo>
                    <LogoImg src={company.img || companylogo} alt="logo" />
                  </CompanyLogo>
                  <Name>{company.title}</Name>
                  <Info>
                    <InfoList>
                      <Website onClick={() => openWebSite(company.website_url || '')}>{(company.website_url || '').replace('http://', '').replace('https://', '')}</Website>
                      <Email onClick={() => window.location = ("mailto:" + company.company_email)}>{company.company_email}</Email>
                      <Phone>{displayPhone(company.company_phone)}</Phone>
                    </InfoList>
                  </Info>
                </CompanyList>
              </CompanyHead>
              <ProductLogo>
                <ProductImg src={ company.product_image_url} alt="productImg" />
              </ProductLogo>
              <ProductCategory>
                <Title>Product Categories</Title>
                <Br />
                <CategoryList>
                  {company.category ?
                    company.category.map((itemC) => (
                      <Fragment key={itemC.id}>
                        <CategoryItem>
                          <Category>{getCategoryByID(itemC.category)}</Category>
                          <SubcategoryList>
                            {itemC.subcategoty.map((item) => (
                              <SubcategoryItem key={item.id} onClick={() => window.location = '/directory?categories=c' + itemC.id + ',sc' + item.id}>
                                {item.name}
                              </SubcategoryItem>
                            ))}
                          </SubcategoryList>
                        </CategoryItem>
                        <Br />
                      </Fragment>
                    )) : <></>}
                </CategoryList>
              </ProductCategory>
              <Claims>
                <Title color="#209ebf">Claims</Title>
                <Br />
                <CategoryItem>
                  {company.filterlist && company.filterlist.claims ?
                    <SubcategoryList>
                      {company.filterlist.claims.map((item) => (
                        <SubcategoryItem onClick={() => window.location = ('/directory?claims=' + item.id)} key={item.id} color="#209ebf">
                          {item.name}
                        </SubcategoryItem>
                      ))}
                    </SubcategoryList>
                    : <></>
                  }

                </CategoryItem>
              </Claims>
            </Company>
          </ContentContainer>
          <Description>
            <ContentContainer>
              <Title>Company Description</Title>
              <DescriptionText>
                {(company.text || '').split('\n').map(t => <>{t}<br /></>)}
              </DescriptionText>
            </ContentContainer>
          </Description>
          <ContentContainer>
            <Brands>
              {company.brands && company.brands.length > 0 ? <><Title>Brands</Title><Br /></> : null}

              <BrandsList>
                {company.brands ?
                  company.brands.map((item) => (
                    item.img ?
                      <BrandsItem key={item.id}>
                        <BrandLogo>
                          <BrandImg src={item.img} alt="brandimg" />
                          <BrandName>{item.name}</BrandName>
                        </BrandLogo>
                      </BrandsItem> : <></>
                  ))
                  : <></>}
              </BrandsList>
            </Brands>
            {company.private_label ?
              <Private>
                <Title>Private Label</Title>
                <Br />

                <PrivateLabel>
                  <PrivateLabelImg>
                    {company.private_label ? (
                      <img src={privateTrue} alt="private-img" />
                    ) : (
                        ""
                      )}
                  </PrivateLabelImg>
                  {company.private_label ? "Private Label" : "No Private Label"}
                </PrivateLabel>

              </Private> : <></>}
            <Certifications>
              {company.certifications && company.certifications.length > 0 ? <><Title>Certifications</Title><Br /></> : null}

              <CertificationsList>
                {company.certifications ?
                  company.certifications.map((item) => (
                    <CertificationItem key={item.id} onClick={() => window.location = '/directory?certification=' + item.id}>
                      {
                        item.img
                          ? <CertificationsLogo> <ImgCertificate src={item.img} alt="certificationsImg" /></CertificationsLogo>
                          : <></>
                      }
                      {/* <CertificationsLogo>
                        {item.img ? <ImgCertificate src={item.img} alt="certificationsImg" /> : <></>}
                      </CertificationsLogo> */}
                      <CertificationsName>{item.name}</CertificationsName>
                    </CertificationItem>
                  )) : <></>}
              </CertificationsList>
            </Certifications>
            <Awards>
              {company.awards && company.awards.find(x => x.name != "") ? <><Title>International Awards</Title>  <Br /></> : null}

              <AwardsList>
                {company.awards !== undefined ?
                  company.awards.map((item) => (
                    item.name !== '' ?
                      <AwardsItem key={item.id}>{item.name}</AwardsItem>
                      : <></>
                  )) :
                  <></>}
              </AwardsList>
            </Awards>
            <List>
              {company.production_capacity ?
                <Item >
                  <Title style={{height:"120px"}}>Yearly Production Capacity</Title>
                  <Br />
                  <Text style={{height:"120px"}}>
                    {company.production_capacity}
                  </Text>
                </Item>
                :
                null}

              {company.main_markets ?
                <Item>
                  <Title style={{height:"120px"}}>Main Markets</Title>
                  <Br />
                  <Text style={{height:"120px"}}>
                    {company.main_markets}
                  </Text>
                </Item> : <></>}

              {company.main_sales_channels ?
                <Item>
                  <Title style={{height:"120px"}}>Main Costumers</Title>
                  <Br />
                  <Text style={{height:"120px"}}>
                    {company.main_sales_channels}
                  </Text>
                </Item>
                :
                <></>}
            </List>

            {company.video_url ?
              <Video>
                <Title>Company Video</Title>
                <Br />
                <VideoContent>
                  <iframe
                    width="100%"
                    height="100%"
                    src={company.video_url}
                    title="Video Company"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </VideoContent>
              </Video>
              : <></>}

            <ProductListImg>

              {company.products_image_url_list ? <><Title>Product Images</Title><Br /></> : null}

              <SRLWrapper>
                <ProductImgList>
                  {company.products_image_url_list ?
                    company.products_image_url_list.map((item) => (
                      <ProductImgItem key={item.id}>
                        <Img src={item.img} alt="product" />
                      </ProductImgItem>
                    )) : <></>}
                </ProductImgList>

              </SRLWrapper>
            </ProductListImg>
            <ProdectDesc>
              <Title>Product Description</Title>
              <Br />
              <DescriptionText>
                {(company.products_description || '').split('\n').map(t => <>{t}<br /></>)}
              </DescriptionText>
            </ProdectDesc>
            <Product>
              <ScrollableTabsButtonAuto company={company} categories={categories} />
            </Product>
          </ContentContainer>

          <ParentInfo>
            <ForMoreInfo>
              <InfoMoreRespresentative >
                <CompanyLogo>
                  <LogoImg src={company.img || companylogo} alt="logo" />
                </CompanyLogo>
              </InfoMoreRespresentative>

              <TexMoreInfo><h4>For more information<br /> please contact</h4></TexMoreInfo>

              <InfoMoreRespresentative>
                <p><b>{company.contact_first_name + " " + company.contact_last_name}</b></p>
                <p>{company.contact_role}</p>
                <hr style={{ marginLeft: "0" }} />
                <p style={{ cursor: 'pointer', marginTop: 5 }} onClick={() => window.location = ("mailto:" + company.contact_email)}>{company.contact_email}</p>
                <p style={{ cursor: 'pointer', marginTop: 5 }}>{displayPhone(company.contact_phone)}</p>
              </InfoMoreRespresentative>

              <InfoMoreRespresentative>
                <p><b>{company.title}</b></p>
                <p style={{ cursor: 'pointer', marginTop: 5 }} onClick={() => window.location = ("mailto:" + company.company_email)}>{company.company_email}</p>
                <p style={{ cursor: 'pointer', marginTop: 5 }}>{displayPhone(company.company_phone)}</p>
              </InfoMoreRespresentative>

            </ForMoreInfo>
          </ParentInfo>

        </Wrapper>
        <Footer />
      </StickyContainer>
    </>
  );
};

export default Detail;

export const ParentInfo = styled.div`
 display:flex;
 align-items: center;
 width: 100vw;
 margin-top:135px;
 background-color:#F6F6F6;


 @media ${device.mobileS} {
  flex-direction:column;
  height:fit-content;
  padding-top:25px;
  padding-bottom:25px;
  text-align: center;
}

@media ${device.laptop} {
  flex-direction:row;
  height:385px;
}

@media only screen and (min-width: 768px) and (max-width: 1260px)  {
  flex-direction:column;
  height:fit-content;
  padding-top:25px;
  padding-bottom:25px;
  text-align: center;
  }
`;

export const ForMoreInfo = styled.div`
 display:flex;
 align-items: center;
 justify-content: space-between;
 margin:auto;
 width: 87vw;

 @media ${device.mobileS} {
  flex-direction:column;
}

@media ${device.laptop} {
  flex-direction:row;
}
@media (min-width: 768px) and (max-width: 1260px)  {
  flex-direction:column;
}

`;

export const TexMoreInfo = styled.div`
font-size: 32px;
color: #20babf;
margin-bottom: 7.5px;
text-align: left;
color: ${(props) => props.color};
@media ${device.mobileS} {
  font-size: 18px;
  padding-top:25px;
  padding-bottom:25px;
}
@media ${device.tablet} {
  font-size: 32px;
  padding-right:25px;
  padding-left:25px;
}
font-family: Montserrat-Bold;
`;
export const InfoMoreRespresentative = styled.div`

@media ${device.mobileS} {
  font-size: 18px;
  padding-top:25px;
  padding-bottom:25px;
}
@media ${device.tablet} {
  
  padding-right:25px;
  padding-left:25px;
}
`;



export const LinksToShare = styled.div`
  Background-color: #FBFBFB;
  height:150px;
  display:flex;
  align-items:center;
  justify-content: space-evenly;
  width:800px;
  margin-right:-800px;
  transition: 0.5s;
  z-index:5;


  :first-child{
    padding-left:20px;
  }
  *{
    padding-left:10px;
    padding-right:10px;
  }
 
`;

export const ShareHead = styled.div`
display:flex;
flex-direction: column;
margin-right: 22px;
margin-left: 36px;
`;

export const ShareElement = styled.div`
@media ${device.mobileS} {
  display:none;
}
@media ${device.tablet} {
  z-index:100;
  display:flex;
  justify-content:flex-start;
  position: absolute;
  align-items: center;
  position:fixed;
  right:0;
  top: 30vh;
  
  height:fit-content;
  background-color: #20BABF;
  border-radius: 35px 0px 0px 35px;
  color: white;
  font-size: 13px;
  color:white;
  font-size: 13px;
  font-family: Montserrat-ExtraBold;

&:hover ${LinksToShare}{
  margin-right: 0px;
}
}

`;


export const TextShareMobile = styled.div`

@media ${device.mobileS} {
  display:flex;
  flex-direction:column-reverse;
  justify-content: space-between;
  position:fixed;
  padding: 5px;
  padding-right:7px;
  padding-top:7px;
  right:2vh;
  bottom:2vh;
  background-color:transparent;
  text-align: center;
  z-index:5;
  color:white;

  a{
    background-color:#F1F1F1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top:8px;
    
    -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  }
  & * img{
    margin: auto;
    transform: translateY(+25%);
    width:35px; 
    height:35px;
  }


  @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px); /*tenho de dar get screen size*/
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }

}

@media ${device.tablet} {
  display:none;
}

  `;
export const Company = styled.div`
  margin-top: 155px;
`;
export const CompanyHead = styled.div`
  margin-bottom: 75px;
  @media ${device.mobileS} {
    display: flex;
    justify-content: center;
  }
`;
export const CompanyList = styled.ul`
  display: flex;
  align-items: center;
  @media ${device.mobileS} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.tablet} {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  @media ${device.laptop} {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;
export const CompanyLogo = styled.li`
  margin-right: 32px;
  /* width: 168px;
  height: 168px; */
  border-radius: 50%;
  overflow: hidden;
  @media ${device.mobileS} {
    width: 168px;
    height: 168px;
    margin: 0 auto;
  }

  @media ${device.tablet} {

    margin: 0 ;
  }
`;
export const LogoImg = styled.img`
  height: 100%;
  width: 100%;
`;

export const Name = styled.li`
  font-size: 64px;
  font-weight: 300;
  color: #3f424c;
  font-family: Montserrat-Light;
  @media ${device.mobileS} {
    font-size: 24px;
    margin: 20px 0 20px 0;
  }
  @media ${device.laptop} {
    font-size: 34px;
    margin-left: 20px;
  }
  @media ${device.laptopL} {
    font-size: 54px;
  }
`;
export const Info = styled.li`
  @media ${device.mobileS} {
    margin-left: 0px;
  }
  @media ${device.laptop} {
    margin-left: auto;
  }
`;
export const InfoList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Website = styled.li`
  font-family: Montserrat-Bold;
  font-size: 18px;
  color: #20babf;
  cursor: pointer;
`;
export const Email = styled.li`
  margin: 10px 0px 10px 0px;
  cursor: pointer;
`;
export const Phone = styled.li`
  font-family: Montserrat-Regular;
`;
export const ProductLogo = styled.section`
min-width: 100%;
  text-align: center;
  margin: auto;
  /*height: 777px;*/

  @media ${device.mobileS} {
    min-width: 100%;
    /*max-height: 200px;*/
  }
  @media ${device.mobileL} {
    min-width: 100%;
    /*max-height: 280px;*/
  }
  @media ${device.tablet} {
    min-width: 100%;
    /*max-height: 500px;*/
  }
  @media ${device.laptop} {
    min-width: 100%;
    /*max-height: 500px;*/
    margin: 0 auto;
  }
  @media ${device.laptopL} {
    min-width: 100%;
    /*max-height: 700px;*/
  }
  @media ${device.desktop} {
    min-width: 100%;
    /*max-height: 800px;*/
  }
`;
export const ProductImg = styled.img`
  width: 100%;
  height: auto;
`;

export const ProductCategory = styled.section`
  margin-top: 137px;
`;
export const Claims = styled.section`
  margin-top: 110px;
`;
export const Title = styled.p`
  font-size: 32px;
  color: #20babf;
  margin-bottom: 7.5px;
  color: ${(props) => props.color};
  @media ${device.mobileS} {
    font-size: 18px;
  }
  @media ${device.tablet} {
    font-size: 32px;
  }
`;
export const Title2 = styled.p`
  font-size: 32px;
  color: #20babf;
  margin-bottom: 7.5px;
  color: ${(props) => props.color};
  @media (max-width: 1024px)  {
    font-size: 24px!important;
  }
  @media ${device.mobileS} {
    font-size: 18px;
  }
  @media ${device.tablet} {
    font-size: 32px;
  }
`;

export const CategoryList = styled.ul``;
export const CategoryItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin: 20px 0 20px 0;
`;
export const Category = styled.p`
  background-color: rgba(32, 186, 191, 0.05);
  padding: 14px 24px 14px 24px;
  border-radius: 30px;
  border: solid 1px #20babf;
  font-family: Montserrat-Bold;
  font-size: 18px;
  color: #20babf;
  margin: 7px 14px 0px 0px;
  @media ${device.mobileS} {
    font-size: 14px;
  }
`;
export const SubcategoryList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;
export const SubcategoryItem = styled.li`
  cursor:pointer;
  font-family: Montserrat-Medium;
  margin: 12px 10px 12px 0px;
  padding: 11px 17px 11px 17px;
  border-radius: 30px;
  border: solid 1px;
  border-color: ${(props) => (props.color ? props.color : "#20babf")};
  color: ${(props) => (props.color ? props.color : "#20babf")};
  @media ${device.mobileS} {
    font-size: 14px;
  }
`;

export const Description = styled.section`
  display: flex;
  justify-content: center;
  width:100vw;
  background-color: #f6f6f6;/*#f6f6f6;*/
  padding: 114px 0px 83px 0px;
  margin-top: 100px;
`;
export const DescriptionText = styled.p`
  margin-top: 38px;
  color: #3f424c;
  font-size: 16pt;
  line-height: 1.63;
  word-wrap: break-word;
`;
export const Brands = styled.section`
  display: block;
  margin-top: 107px;
`;
export const BrandsList = styled.ul`
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  @media ${device.mobileS} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.tablet} {
    flex-direction: row;
    justify-content: flex-start;
  }
  @media ${device.laptopL} {
    justify-content: flex-start;
  }
`;
export const BrandsItem = styled.li`
  @media ${device.mobileS} {
    margin-right: 0px;
    margin-bottom: 90px;
  }
  @media ${device.tablet} {
    margin-right: 30px;
  }
`;
export const BrandLogo = styled.div`
  /*height: 221px;*/
  object-fit: contain;
  
  @media ${device.mobileS} {
    width: 280px;
  }
  @media ${device.laptop} {
    width: 280px;
  }
`;
export const BrandImg = styled.img`
width: 280px;
cursor: pointer;
height: 250px;
object-fit: cover;

  border: solid 1px #e1e1e1;
`;
export const BrandName = styled.p`
  font-family: Montserrat-Medium;
  font-size: 21px;
  font-weight: 500;
  color: #3f424c;
  margin-top: 30px;
`;
export const Private = styled.section`
  margin-top: 128px;
`;
export const PrivateLabel = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat-Medium;
  font-size: 21px;
  font-weight: 500;
  color: #3f424c;
  margin-top: 40px;
`;
export const PrivateLabelImg = styled.div`
  margin-right: 14px;
`;
export const Certifications = styled.div`
  margin-top: 128px;
`;
export const CertificationsList = styled.ul`
  display: flex;
  margin-top: 50px;
  width: 80vw;
  flex-wrap: wrap;
  @media ${device.mobileS} {
    flex-direction: column;
  }
  @media ${device.laptop} {
    flex-direction: row;
  }
`;
export const CertificationItem = styled.li`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 133px;
  @media ${device.mobileS} {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  @media ${device.laptop} {
    margin-right: 20px;
  }
`;
export const CertificationsLogo = styled.div`
  margin-bottom: 37px;
`;
export const CertificationsName = styled.p`
  border-radius: 20px;
  border: solid 1px #556fab;
  padding: 10px 18px 10px 18px;
`;
export const Awards = styled.div`
  margin-top: 128px;
`;
export const AwardsList = styled.ul`
  margin-top: 50px;
`;
export const AwardsItem = styled.li`
  font-family: Montserrat-Medium;
  font-size: 21px;
  font-weight: 500;
  color: #3f424c;
  margin-bottom: 10px;
`;
export const List = styled.ul`
  width: 100%;
  display: flex;
  margin-top: 128px;
  @media ${device.mobileS} {
    flex-direction: column;
  }
  @media ${device.laptop} {
    flex-direction: row;
    align-items: flex-end
  }
`;
export const Item = styled.li`
  width: 33.3%;
  overflow: hidden;
  margin-right: 30px;
  &:last-child {
    margin-right: 0px;
  }
  @media ${device.mobileS} {
    width: 280px;
    margin-bottom: 20px;
  }
  @media ${device.laptop} {
    width: 33.3%;
    margin-right: 30px;
  }
`;

export const Item2 = styled.li`
  flex:1;
  overflow: hidden;
  &:last-child {
    margin-right: 0px;
  }
  @media ${device.mobileS} {
    flex:1;
    margin-bottom: 20px;
  }
  @media ${device.laptop} {
    flex:1;
    margin-right: 30px;
  }
  @media (max-width: 1024px) {
    flex:1;
    margin-right: 5px!important;
  }
`;

export const Text = styled.p`
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 1; /* number of lines to show */
  // -webkit-box-orient: vertical;

  font-family: Montserrat-Medium;
  font-size: 21px;
  font-weight: 500;
  color: #3f424c;
  margin-bottom: 10px;
  &:before {
    content: "";
    display: block;
    margin-top: 37px;
  }
`;

export const Video = styled.div`
  margin-top: 107px;
`;
export const VideoContent = styled.div`
  margin: 40px auto;
  @media ${device.mobileS} {
    width: 280px;
    max-height: 200px;
  }
  @media ${device.mobileL} {
    width: 380px;
    height: 300px;
  }
  @media ${device.tablet} {
    width: 680px;
    min-height: 500px;
  }
  @media ${device.laptop} {
    width: 900px;
    min-height: 500px;
    padding-top: 40px;
    margin: 0 auto;
  }
  @media ${device.laptopL} {
    width: 1200px;
    min-height: 700px;
  }
  @media ${device.desktop} {
    width: 1400px;
    min-height: 800px;
  }
`;
export const ProductListImg = styled.section`
  margin-top: 106px;
`;

export const ProductImgList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 42px;
  @media ${device.mobileS} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.tablet} {
    flex-direction: row;
    justify-content: left;
  }
  @media ${device.laptopL} {
    justify-content: flex-start;
  }
`;
export const ProductImgItem = styled.li`
  object-fit: contain;
  margin: 0px 24px 20px 0;
  @media ${device.mobileS} {
    width: 280px;
    height: 277px;
    margin: 0 0 20px 0;
  }
  @media ${device.tablet} {
    margin: 0px 24px 20px 0;
  }
`;
export const ProdectDesc = styled.section`
  margin-top: 111px;
`;

export const Product = styled.section`
  margin-top: 111px;
`;
export const Img = styled.img`
  width: 280px;
  cursor: pointer;
  height: 250px;
  object-fit: cover;
`;
export const ImgCertificate = styled.img`
  width: 94x;
  cursor: pointer;
  height: 94px;
  object-fit: cover;
`;
