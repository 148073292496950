import React, { useState } from "react";
import styled from "styled-components";
import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import { Label, LabelText } from "./styled/commonStyled";
import downloadImg from "../../../img/download-img.png";
import close from "../../../img/close.svg";
import addfilter from "../../../img/addfilter.png";
import {
  InputFileContainer,
  InputFileRepresentative,
  DownloadImg,
  InputFileText,
  Strong,
  InputFileDesc,
  InputFile,

} from "./InformationAboutCompany";
import MyDropzone from "./MyDropzone";
import { Br, device } from "./../../commons/commonsStyled/index";
import {
  ButtonImg,
  ButtonText,
  RefineSeachButton,
  Img
} from "../../pages/Directory";

const updateFieldsValues = (fields, obj) => {
  const lastObj = fields.value[fields.length - 1];
  obj.name = lastObj.name;
  fields.pop();
  fields.push(obj);
};

let universalname = {};

const AddBrandFrom = () => {
  const [state, setState] = useState([]);

  return (
    <>
      <FieldArray name="brands">
        {({ fields }) => (
          <>
            {fields.map((name, index) => (
              <div key={name}>
                {console.log(fields)}
                <LabelContainerBrand>
                  <Brand>
                    <Label>
                      <LabelText>Nome da Marca</LabelText>
                      <FieldInput
                        name={`${name}.name`}
                        component="input"
                        type="text"
                        onKeyUp={(event) => {
                          universalname[index] = event.target.value;
                          fields.update(index, { ...fields.value[index], name: event.target.value })

                        }
                        }
                      />
                    </Label>
                  </Brand>
                  <BrandImg>
                    <LabelText>Upload de logotipo da Marca</LabelText>

                    <InputFileContainer>
                      <InputFileBrand>
                        {(fields.value[index] && fields.value[index].picture_url != '') ? (
                          <>
                            <DownloadImg src={fields.value[index].picture_url} />
                            <Delete
                              onClick={() => {
                                // state.splice(index, 1);
                                fields.remove(index);
                                //fields.pop()
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={close} alt="close" />
                            </Delete>
                          </>
                        )
                          : (
                            <Label>
                              <div>
                                <img src={downloadImg} alt="download-img" />
                                <InputFileText>
                                  <Strong>JPEG/PNG</Strong>
                                600 x 600 px
                              </InputFileText>
                                <Field name={`${name}.picture_url`} component="input">
                                  {(props) => (
                                    <MyDropzone
                                    validateSize={[600, 600]}
                                      // updateFieldsValues(fields, { img: marca.map((item) => item)
                                      sendImg={(marca) => {
                                        console.log(fields.value[index]);
                                        fields.update(index, { name: (index in universalname) ? universalname[index] : '', picture_url: marca.map((item) => item) })
                                      }}
                                      {...props.input}
                                    />
                                  )}
                                </Field>
                              </div>
                            </Label>
                          )}
                      </InputFileBrand>
                      <InputFileDesc>
                        Formato jpg ou png.
                        <br />
                        <br />
                        Dimensões:
                        <br />
                        600 x 600 px
                        <br />
                        <br />
                        Tamanho:
                        <br />
                        1mb
                      </InputFileDesc>
                    </InputFileContainer>
                  </BrandImg>
                </LabelContainerBrand>
                <Br />
              </div>
            ))}

            {fields.length === 0 ||
              (fields.length < 4 && fields.value[fields.length - 1].name !== "" && fields.value[fields.length - 1].picture_url !== "")
              ?
              <ButtonBrand
                type="button"
                onClick={() => {
                  // if (fields.value)
                  //   fields.value.map((item) => console.log(item.picture_url.preview));
                  // console.log(state);
                  console.log(fields);
                  fields.push({ name: "", picture_url: "" });
                }}
              >
                <ButtonText>Adicionar Marca</ButtonText>
                <ButtonImg>
                  <Img src={addfilter} alt="addfilter" />
                </ButtonImg>
              </ButtonBrand>
              : <></>}

            <Br />
          </>
        )}
      </FieldArray>
    </>
  );
};

export default AddBrandFrom;

export const FieldInput = styled(Field)`
  
  height: 54px;
  border-radius: 27px;
  border: solid 1px rgba(63, 66, 76, 0.3);
  background-color: #ffffff;
  outline: none;
  padding-left:20px;
  box-sizing:border-box;
  @media ${device.mobileS} {
    width: 280px;
    height: 44px;
    border-radius: 22px;
  }
  @media ${device.laptop} {
    width: 348px;
    height: 44px;
    border-radius: 22px;
  }
  @media ${device.laptopL} {
    width: 548px;
    
  }
`;
export const LabelContainer = styled.div`
  display: flex;
`;
export const Brand = styled.div`
  margin-right: 94px;
  @media ${device.mobileS} {
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    margin-right: 44px;
  }
  
`;
export const Delete = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 33px;
  height: 33px;
  background-color: #e27f7f;
  top: 0;
  right: 0;
`;
export const BrandImg = styled.div`
`;
export const InputFileBrand = styled(InputFile)`
  position: relative;
  border-top-right-radius: 0px;
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  border-bottom-right-radius: 27px;
`;
export const LabelContainerBrand = styled(LabelContainer)`
  margin: 58px 0 63px 0;
  @media ${device.mobileS} {
    display:flex;
    flex-direction:column;
  }
  @media ${device.tablet} {
    display:flex;
    flex-direction:row;
  }
`;
export const ButtonBrand = styled(RefineSeachButton)`
  margin: 47px 0 47px 0;
  
`;
