import styled from "styled-components";
import { device } from './../../../commons/commonsStyled/index';

export const Label = styled.label`
position:relative;
`;
export const LabelText = styled.div`
  display: block;
  font-family: Montserrat-Bold;
  font-size: 19px;
  margin-bottom: 16px;
  @media ${device.mobileS} {
    font-size: 12px;
  }
  @media ${device.tablet} {
    font-size: 16px;
  }
`;
export const InputContainer = styled.li`
  display: block;
  margin-bottom: 46px;
`;
export const Page = styled.div`
  margin-bottom: 90px;
`;
export const Title = styled.div`
  font-size: 36px;
  margin-bottom: 37px;
  @media ${device.mobileS} {
    font-size: 21px;
  }
  @media ${device.laptop} {
    font-size: 36px;
  }
`;
export const NynPage = styled.span`
  font-size: 36px;  
  font-family: Montserrat-Bold;
  font-weight:bolder;
  color:#20babf;
  @media ${device.mobileS} {
    font-size: 21px;
  }
  @media ${device.laptop} {
    font-size: 36px;
  }
`;
export const Recommendation = styled.p`
  font-size: 16px;
  line-height: 1.19;
  color: #556fab;
  margin: 33px 0 33px 0;
  @media ${device.mobileS} {
    font-size: 11px;
  }
  @media ${device.tablet} {
    font-size: 14px;
  }
  
`;