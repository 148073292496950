import {FETCH_CLAIMS} from "./actionTypes";

const initialState = {
    claims:{}
};

export const claimsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLAIMS:
      return {...state,claims:action.payload}
    default:
      return state;
  }
};