import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Navigation from "../../commons/Navigation";
import {
  Title,
  HeaderContainer,
  Wrapper,
  Container,
  ContentContainer,
  Br,
} from "../../commons/commonsStyled/index";
import Logo from "../../commons/Logo";
import Footer from "../../commons/Footer";
import Wizard from "./Wizard";
import InformationAboutCompany from "./InformationAboutCompany";
import AddCategoryForm from "./AddCategoryForm";
import InformationBrandCompany from "./InformationBrandCompany";
import InformationMarketCompany from "./InformationMarketCompany";
import ProductsOnSale from "./ProductsOnSale";
import CompanyContacts from "./CompanyContacts";
import { useDispatch, useSelector } from "react-redux";
import { fetchClaims, fetchCategories, fetchOneCompany, fetchOneCompanyEdit, updateImageTest } from './../../../redux/actions';
import { isEmpty as _isEmpty } from 'lodash';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

let wizardRef;
let timeoutSuccessMessage;
let timeoutErrorMessage;

//**VALIDATION METHODS */
function ValidateEmail(mail) {
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail)) {
    return (true)
  }
  return (false)
}

function ValidateYoutube(link) {
  var re = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=([^&]+)/m;
  var re2 = /^(https|http):\/\/(?:www\.)?youtube.com\/embed\/[A-z0-9]+/m;
  if (re.test(link) || re2.test(link)) {
    return (true)
  }
  return (false)
}

function ValidURL(str) {
  var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  if (!regex.test(str)) {
    return false;
  } else {
    return true;
  }
}

function ValidNumber(str) {
  if (/^-?\d+$/.test(str) && str.length == 9) {
    return true;
  } else {
    return false;
  }
}

const validateForm = (values) => {
  const errors = {}

  console.log(values['submit'])

  if (!values.business_name) {
    errors.business_name = ' Nome da Empresa'
  }

  if (!values.logo_url) {
    errors.logo_url = ' O Logotipo'
  }

  if (!values.description || !values.description.trim()) {
    errors.description = ' A Descrição da Empresa'
  }

  if (values.description ?
    values.description.length > 1000 ?
      true : false : false) {
    errors.description = ' Não deve ultrapassar os 1000 caracteres na Descrição'
  }
  //Falta o existe mas é vazio

  if (!values.website_url || !values.website_url.trim()) {
    errors.website_url = ' O website '
  } else if (!values.website_url.includes("http://") && !values.website_url.includes("https://")) {
    errors.website_url = 'Comece com http:// ou https://';
  } else if (!(ValidURL(values.website_url))) {
    errors.website_url = 'Este URL é inválido'
  }


  console.log(values.products_image_url) 
  if (!values.products_image_url) {
    errors.products_image_url = ' Imagem representativa do produto'
  }

  if (!values.company_phone) {
    errors.company_phone = ' O telemóvel'
  } else if (values.company_phone.length > 9) {
    errors.company_phone = 'Máximo de 9 dígitos'
  } else if (!ValidNumber(values.company_phone)) {
    errors.company_phone = ' Formato inválido no telemóvel'
  }

  if (!values.company_email) {
    errors.company_email = ' O email da empresa'
  }
  if (values.company_email ?
    !ValidateEmail(values.company_email) ?
      true : false : false) {
    errors.company_email = 'Esse formato de email é inválido'
  }

  if (values.video_url) {
    if (!ValidateYoutube(values.video_url)) {
      errors.video_url = 'Este youtube URL é inválido'
    }
  }

  //Page 2 
  if (!values.categories || values.categories.length === 0) {
    errors.categories = ' As categorias'
  }

  if (!values.claims || values.claims.length === 0) {
    errors.claims = ' Os Claims'
  }

  //Needs work
  if (!values.brands || values.brands.length === 0) {
    errors.brands = ' As marcas associadas'
  }
  // else{
  //   values.brands.forEach(element => {
  //     if(element.name || element.picture_url){
  //       if(!errors.brands){
  //         errors.brands = 'Não pode ter valores vazios'
  //       }
  //     }
  //   });
  // }


  if (values.private_label == undefined || values.private_label == null) {

    errors.private_label = ' Etiqueta Privada'
  }

  /* if (!values.main_markets) {
     errors.main_markets = ' Campo Obrigatório'
   }*/

  if (!values.products_description || !values.products_description.trim()) {
    errors.products_description = ' Descrição dos Produtos'
  }

  if (values.products_description && values.products_description.length > 1000) {
    errors.products_description = ' Não deve ultrapassar os 1000 caracteres'
  }

  console.log("PI", values.productImg)
  if (!values.productImg || values.productImg.length == 0) {
    errors.productImg = ' Imagens dos produtos'
  } else if (values.productImg[0].image_url === '') {
    errors.productImg = ' Imagens dos produtos'
  }

  if (!values.products) {
    errors.ProductsError = " Produtos";
  }

  //  if (values.products) {
  //    Object.keys(values.products).forEach(key => {
  //      const element = values.products[key];
  //    element.forEach(e => {
  //      if (!e.name || e.name == "" || !e.storageType || e.storageType == "" || !e.shelfLife || e.shelfLife == "") {
  //        errors.ProductsError = "Deve preencher os campos pedidos";
  //       }
  //      console.log(e);
  //     })
  //  });
  // }

  // if (values.products && values.categories) {
  //   const result = values.categories.every(cat => {
  //     const subcategories = cat.subcategories;

  //     return subcategories.every(subCat => (subCat.id in values.products) && values.products[subCat.id] && values.products[subCat.id].length > 0);
  //   });


  //   if (result) {
  //     console.log('PUMBA - OK');
  //   } else {
  //     errors.ProductsError = "Deve adicionar pelo menos um produto a cada sub-categoria";
  //   }
  // }

  if (values.contact_email && !ValidateEmail(values.contact_email)) {
    errors.contact_email = 'Esse formato de email é inválido'
  }

  return errors;
}

const AddCompany = (props) => {
  const dispatch = useDispatch();
  const id = useSelector((root) => root.user.user.id);

  let company = useSelector((root) => root.companies.companies[0]);
  let numberAux;

  if (company) {
    // console.log('>> Aqui <<');
    //console.log(company);
    if (company.images) {
      company.productImg = company.images.map(image => ({ ...image, data: image.image_url }));
    } else {
      company.productImg = [];
    }


    // extrai o indicativo do numero
    if (company.company_phone && company.company_phone.indexOf(' ') > -1) {
      numberAux = company.company_phone.split(' ');
      company.company_phone_indicative = numberAux[0];
      company.company_phone = numberAux[1];
    }

    // extrai o indicativo do numero
    if (company.contact_phone && company.contact_phone.indexOf(' ') > -1) {
      numberAux = company.contact_phone.split(' ');
      company.contact_phone_indicative = numberAux[0];
      company.contact_phone = numberAux[1];
    }
  }

  if (company && _isEmpty(company.company_phone_indicative)) {
    company.company_phone_indicative = '+351';
  }

  if (company && _isEmpty(company.contact_phone_indicative)) {
    company.contact_phone_indicative = '+351';
  }

  // todas 
  let subCat = useSelector((root) => root.categories ? root.categories.categories.data : {});

  let errorBakc = null;

  if (subCat) {
    const ccs = (company) ? company.subcategories : []; // as que queremos mostrar

    const t = subCat.data.reduce((acc, cat) => {
      const subCategories = cat.subcategories.filter(catSubCat => ccs.find(subCatFromServer => subCatFromServer.id == catSubCat.id) != undefined);

      if (subCategories && subCategories.length > 0) {
        acc.push({ ...Object.assign({}, cat), subcategories: subCategories });
      }

      return acc;
    }, []);

    // const t = subCat.data.filter(elem => elem.subcategories.filter(subC => ccs.find(csc => csc.id == subC.id)).length > 0);
    company = { ...company, categories: t };
    console.log("finaly", company);

  }

  if (company && company.products && Object.keys(company.products).length > 0) {
    const mapProducts = {};

    (company.products || []).forEach(elem => {
      const key = elem.sub_category_id;
      if (!(key in mapProducts)) {
        mapProducts[key] = [];
      }

      mapProducts[key].push({
        company_id: '' + elem.company_id,
        id: '' + elem.id,
        name: '' + elem.name,
        shelfLife: '' + elem.self_life,
        storageType: '' + elem.storage_type,
        subcategory_id: '' + elem.sub_category_id,
      });
    });

    company.products = mapProducts;
  }

  if (company) {
    dispatch(updateImageTest({ logo_url: company.logo_url, radio: company.private_label, products_image_url: company.products_image_url, description: company.description, products_description: company.products_description }));
  } else {
    dispatch(updateImageTest({}));
  }

  const onSubmit = async (values) => {
    wizardRef.updateStateProperty('showLoading', true);

    let data = {
      rawData: values,
      id: id,
    }

    //DATA TRANSFORM
    if (data.rawData.products && Array.isArray(data.rawData.products)) {
      // console.log(data.rawData.products);
      data.rawData.products = data.rawData.products.filter(el => el !== null);
    }

    if (data.rawData.certifications && Array.isArray(data.rawData.certifications)) {

      data.rawData.certifications = data.rawData.certifications.filter(el => el.name !== null);
    }

    if (data.rawData.description && data.rawData.description.length > 1000) {
      data.rawData.description = data.rawData.description.slice(0, 1000);
    }
    if (data.rawData.products_description && data.rawData.products_description.length > 1000) {
      data.rawData.products_description = data.rawData.products_description.slice(0, 1000);
    }

    if (data.rawData.video_url && !data.rawData.video_url.includes("/embed/")) {
      data.rawData.video_url = "https://www.youtube.com/embed/" + data.rawData.video_url.split("=")[1];
    }
    if (!data.rawData.business_name) {
      data.rawData.business_name = "";
    }
    if (!data.rawData.logo_url) {
      data.rawData.logo_url = "";
    }
    if (!data.rawData.description) {
      data.rawData.description = "";
    }
    if (!data.rawData.products_image_url) {
      data.rawData.products_image_url = "";
    }

    /**NEW STUFF */

    if (!data.rawData.website_url) {
      data.rawData.website_url = "";
    }

    if (!data.rawData.company_phone) {
      data.rawData.company_phone = "";
    }

    if (!data.rawData.company_email) {
      data.rawData.company_email = "";
    }

    if (!data.rawData.categories) {
      data.rawData.categories = [];
    }
    if (!data.rawData.claims) {
      data.rawData.claims = [];
    }
    if (!data.rawData.brands) {
      data.rawData.brands = [];
    }
    if (!data.rawData.certifications) {
      data.rawData.certifications = [];
    }
    if (!data.rawData.private_label) {
      data.rawData.private_label = "0";
    }
    if (!data.rawData.productImg) {
      data.rawData.productImg = [];
    }
    if (!data.rawData.products) {
      data.rawData.products = [];
    }
    /**End of new STUFF */

    if (!data.rawData.distinction_1) {
      data.rawData.distinction_1 = null;
    }
    if (!data.rawData.distinction_2) {
      data.rawData.distinction_2 = null;
    }
    if (!data.rawData.distinction_3) {
      data.rawData.distinction_3 = null;
    }
    if (!data.rawData.distinction_4) {
      data.rawData.distinction_4 = null;
    }
    if (!data.rawData.distinction_5) {
      data.rawData.distinction_5 = null;
    }
    if (!data.rawData.production_capacity) {
      data.rawData.production_capacity = null;
    }

    if (!data.rawData.main_markets) {
      data.rawData.main_markets = null;
    }
    if (!data.rawData.main_sales_channels) {
      data.rawData.main_sales_channels = null;
    }
    if (!data.rawData.products_description) {
      data.rawData.products_description = null;
    }
    if (!data.rawData.contact_first_name) {
      data.rawData.contact_first_name = null;
    }
    if (!data.rawData.contact_last_name) {
      data.rawData.contact_last_name = null;
    }
    if (!data.rawData.contact_role) {
      data.rawData.contact_role = null;
    }
    if (!data.rawData.contact_email) {
      data.rawData.contact_email = null;
    }
    if (!data.rawData.contact_phone) {
      data.rawData.contact_phone = null;
    }
    if (!data.rawData.video_url) {
      data.rawData.video_url = null;
    }

    console.log(values)

    // contact_role:"",
    // contact_email:"",
    // contact_phone:""
    // console.log("Check data", data);

    try {
      if (values.submit == 0) {
        const errors = validateForm(values);
        if (Object.keys(errors).length > 0) {
          console.log(errors)
          wizardRef.updateStateProperty('showFormErrorMessage', true);
          wizardRef.updateStateProperty('errors', errors);

          if (timeoutErrorMessage) {
            clearTimeout(timeoutErrorMessage);
          }

          timeoutErrorMessage = setTimeout(() => {
            if (wizardRef) {
              wizardRef.updateStateProperty('showFormErrorMessage', false)
            }
          }

            , 5000);
          return;
        }
      }

      // aqui senao o validateForm dá sempre errado 
      if (data.rawData.company_phone && data.rawData.company_phone_indicative) {
        data.rawData.company_phone = data.rawData.company_phone_indicative + ' ' + data.rawData.company_phone;
      }

      if (data.rawData.contact_phone && data.rawData.contact_phone_indicative) {
        data.rawData.contact_phone = data.rawData.contact_phone_indicative + ' ' + data.rawData.contact_phone;
      }

      let response = await fetch(apiBaseUrl + "/api/company", {
        method: 'POST',
        headers: {
          'Connection': 'keep-alive',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': "application/json",
          'Accept-Encoding': 'gzip, deflate, br',
          'Authorization': `Bearer ${localStorage["token"]}`,
        },
        body: JSON.stringify(data)
      });

      // console.log(response)

      let result = await response.json();

      // console.log(id)

      // console.log(JSON.stringify(data, 0, 2))

      if (result.exception == 'ErrorException') {
        //alert("erro ao registar verifique os campos e tente novamente")
        errorBakc("erro ao registar verifique os campos e tente novamente")
        console.log("reults man", result);
      } else {
        // ajustar visualmente os numeros de telefone
        if (data.rawData.company_phone) {
          data.rawData.company_phone = data.rawData.company_phone.replace(data.rawData.company_phone_indicative, '').replace(' ', '');
        }

        if (data.rawData.contact_phone) {
          data.rawData.contact_phone = data.rawData.contact_phone.replace(data.rawData.contact_phone_indicative, '').replace(' ', '');
        }

        data.rawData.id= result.data;
        if (data.rawData.submit == 1) {
          if (wizardRef) {
            wizardRef.updateStateProperty('showLogMessage', true);
            if (timeoutSuccessMessage) {
              clearTimeout(timeoutSuccessMessage);
            }

            console.log(result.data);
            // refresh company
            if (props.history.location.query != undefined) {
              dispatch(fetchOneCompanyEdit(props.history.location.query.companyId));
            } 
            else if(result.data){
              dispatch(fetchOneCompanyEdit(result.data));
            }
            
            else {
              //dispatch(fetchOneCompanyEdit());
            }


            timeoutSuccessMessage = setTimeout(
              () => {
                if (wizardRef) wizardRef.updateStateProperty('showLogMessage', false)
              },
              5000);
          }
        } else {
          window.location.href = "/success";
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      wizardRef.updateStateProperty('showLoading', false);
    }
  };

  useEffect(() => {
    dispatch(fetchClaims());
    dispatch(fetchCategories());

    if (props.history.location.query != undefined)
      dispatch(fetchOneCompanyEdit(props.history.location.query.companyId));
    else{
      dispatch(fetchOneCompany());
    }


  }, []);

  const [errors, setErrors] = useState();

  return (
    <>
      <Wrapper>
        <Container>
          <HeaderContainer>
            <Logo />
            <Navigation />
          </HeaderContainer>
        </Container>
        <ContentContainer>
          <Title>Novo registo</Title>
          {/*errorBakc ? <Loading loading={true} loadingState={1} loadMessage={errorBakc}></Loading> : <></>*/}
          <FormAddCompany>
            <Wizard ref={ref => wizardRef = ref} onSubmit={onSubmit} company={company} >
              <Wizard.Page>
                <InformationAboutCompany />
              </Wizard.Page>
              <Wizard.Page>
                <AddCategoryForm />
              </Wizard.Page>
              <Wizard.Page>
                <InformationBrandCompany />
              </Wizard.Page>
              <Wizard.Page>
                <InformationMarketCompany />
              </Wizard.Page>
              <Wizard.Page>
                <ProductsOnSale company={company} />
              </Wizard.Page>
              <Wizard.Page>
                <CompanyContacts />
              </Wizard.Page>
            </Wizard>
          </FormAddCompany>
        </ContentContainer>
      </Wrapper>
      <Footer />
    </>
  );
};

export default AddCompany;

export const FormAddCompany = styled.div`
  margin-top: 40px;
  color: #3f424c;
`;
