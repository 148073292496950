import React, { useEffect } from "react";
import Navigation from "../../commons/Navigation";
import Logo from "../../commons/Logo";
import LogoHome from "./LogoHome";
import FormContainer from "../../commons/Form/Form";
import Footer from "./../../commons/Footer";
import Search from './../../commons/Search/Search';
import Cookies from "../../commons/Cookies";
import { StickyContainer, Sticky } from 'react-sticky';

import {
  Section,
  Text,
  CardsList,
  CardsItem,
  CardsTitle,
  Contact,
  ContactTitle,
  Required,
  TitleMain,
  P,
  CardsItemImg,
  CardsItemTitle,
  CardsItemText,
  CardsItemContainer,
  CardImg,
  TitleHome
} from "./styledHome";

import {
  Title,
  StickyHeaderContainer,
  MyHeader,
  Wrapper,
  Container,
  ContentContainer,
  RequiredCircle,
} from "../../commons/commonsStyled/index";

import pic1 from "../../../img/pic1.png";
import pic2 from "../../../img/pic2.png";
import pic3 from "../../../img/pic3.png";
import pic4 from "../../../img/pic4.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchHowItWorks } from "../../../redux/actions";




const Home = () => {
  const dispatch = useDispatch()
const result= useSelector((root) => root.howItWorks.data);

useEffect(() => {
  dispatch(fetchHowItWorks());
  /*document.title = 'Portuguese Food & Beverage Portfolio - Home'*/
  }, []);
  return (

    <> 
    <Cookies />
    <StickyContainer>
      <Wrapper>
        <Section>
          <Container>
            <Sticky topOffset={30}>
              {({ style, isSticky }) => <StickyHeaderContainer style={style} isSticky={isSticky} stickable>
                <MyHeader>
                  { isSticky ? (<Logo />) : (<LogoHome />)}
                  <Navigation home="true" isSticky={isSticky} />
                </MyHeader>
              </StickyHeaderContainer> }
            </Sticky>
            <TitleMain>Portuguese Food <br /> & Beverage portfolio</TitleMain>
            <P>Connect with the finest portuguese brands</P>
            <Search placeholder="Search for Companies, Products, Certifications..." />
          </Container>
        </Section>
        <ContentContainer id="about">
          <TitleHome>How it works</TitleHome>
          <Text>
          {result ?  <div dangerouslySetInnerHTML={{__html: result.description}}></div> : null}
          </Text>
          <CardsTitle>
            Discover a new business opportunity in four simple steps:
          </CardsTitle>
          <CardsList>
            <CardsItem>
              <CardsItemContainer>
                <CardsItemImg>
                  <CardImg src={pic1} alt="pic1" />
                </CardsItemImg>
                <CardsItemTitle>
                  1.
                  <br />
                  Explore our portfolio
                </CardsItemTitle>
                <CardsItemText>
                  Search for companies,
                  <br /> products, or certifications.
                </CardsItemText>
              </CardsItemContainer>
            </CardsItem>
            <CardsItem>
              <CardsItemContainer>
                <CardsItemImg>
                  <CardImg src={pic2} alt="pic2" />
                </CardsItemImg>
                <CardsItemTitle>
                  2.
                  <br />
                  Find the best match
                </CardsItemTitle>
                <CardsItemText>
                  Add filters to make your  search results more precise.
                </CardsItemText>
              </CardsItemContainer>
            </CardsItem>
            <CardsItem>
              <CardsItemContainer>
                <CardsItemImg>
                  <CardImg src={pic3} alt="pic3" />
                </CardsItemImg>
                <CardsItemTitle>
                  3.
                  <br />
                  Share the results
                </CardsItemTitle>
                <CardsItemText>
                  Send the results to your team via email or WhatsApp.{" "}
                </CardsItemText>
              </CardsItemContainer>
            </CardsItem>
            <CardsItem>
              <CardsItemContainer>
                <CardsItemImg>
                  <CardImg src={pic4} alt="pic4" />
                </CardsItemImg>
                <CardsItemTitle>
                  4.
                  <br />
                  Connect with brands
                </CardsItemTitle>
                <CardsItemText>
                  Get in touch with the selected companies.{" "}
                </CardsItemText>
              </CardsItemContainer>
            </CardsItem>
          </CardsList>
        </ContentContainer>
        <Contact id="contact">
          <ContentContainer>
            <ContactTitle>Contact us</ContactTitle>
            <Required>
              <RequiredCircle />
              Required fields
            </Required>
            <FormContainer />
          </ContentContainer>
        </Contact>
      </Wrapper>
      <Footer></Footer>
    </StickyContainer>
    </>
  );
};

export default Home;
