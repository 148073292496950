import React from "react";
import Navigation from "../commons/Navigation";
import {
  Title,
  HeaderContainer,
  Wrapper,
  Container,
  ContentContainer,
} from "../commons/commonsStyled/index";
import Logo from "./../commons/Logo";
import styled from "styled-components";
import Footer from "../commons/Footer";
import ChangePassword from "../commons/ChangePassword";

const RecoverPassword = () => {
  return (
    <>
      <HeaderContainer>
        <Logo />
        <Navigation />
      </HeaderContainer>

      <MyWrapper>
        <ChangePassword></ChangePassword>
      </MyWrapper>
      <Footer></Footer>

    </>
  )
};
export const MyWrapper = styled.div`
  margin-top:5vh;
  margin-left:5vw;
  margin-right:5vw;


  * input{
    width:50vw;
  }
`;
export default RecoverPassword;