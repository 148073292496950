import React from "react";
import styled from "styled-components";
import { FieldInputSmall } from './InformationAboutCompany';
import { Br } from "./../../commons/commonsStyled/index";

const AddAwardsFrom = () => {
  return (
    <>
    <List>
      <FieldInput name="distinction_1" type="text" component="input" placeholder="1." />
      <FieldInput name="distinction_2" type="text"  component="input" placeholder="2." />
      <FieldInput name="distinction_3" type="text"  component="input" placeholder="3." />
      <FieldInput name="distinction_4" type="text"  component="input" placeholder="4." />
      <FieldInput name="distinction_5" type="text"  component="input" placeholder="5." />
    </List>
    <Br/>
    </>
  );
};

export default AddAwardsFrom;
export const FieldInput = styled(FieldInputSmall)`
  font-family: Montserrat-Bold;
  font-size: 19px;
  font-weight: bold;
  color: #aaaaaa;
  margin-bottom:18px;
  padding-left:20px;

`;

export const List = styled.div`
  display: flex;
  flex-direction:column;
  margin-bottom:97px;

`;