
import React, { useRef, useState } from "react";
import { Container, device } from "./commonsStyled";
import styled from "styled-components";
import { BrowserRouter as Router, Link } from "react-router-dom";



const Cookies = () => {
    const coockiesContainer = useRef();
    const [acepted, setacepted] = useState(localStorage.getItem('acepted') || false)
    const onAcepet = event => {
      localStorage.setItem('acepted', true);
      setacepted(true);
    }
    return (
     
        
            !acepted
            ?
        <>
            <Container ref={coockiesContainer} style={{backgroundColor: "#e5e5e5", position:"fixed", bottom:0}}>
            <Cflfex>
                <Space></Space>
                <Ptex><span style={{fontSize:"24px", fontFamily:"Montserrat-Bold", color:"black"}}><b>This site uses cookies</b></span><br /><br />We use cookies (and similar technologies) and browsing data to make our site and services work and to help us improve them. For more detailed information about the cookies we use click on “Privacy Policy.” By clicking on “I Accept” you agree to the use of cookies and the processing of your personal data while using our site and services. </Ptex>
                <BtnParents>
                    <ButtonAce onClick={()=> onAcepet()}>I Accept</ButtonAce>
                    <MyLink to="/privacy-policy">Privacy Policy</MyLink>
                </BtnParents>
                <Space></Space>
            </Cflfex>
        </Container> 
        </>
        :
        <></>
    
        
    );
};
export default Cookies;


export const Cflfex = styled.div`
  display: flex;
  align-items:center;

  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  width:70vw;
  margin:auto;
  line-height: 1.5;
  @media ${device.mobileS} {
    flex-direction: column;
    width:90vw;
  }
  @media ${device.tablet} {
    flex-direction: row;
    width:70vw;
  }
`;
export const Space = styled.span`
  width:10vw;
  @media ${device.mobileS} {

  }
  @media ${device.tablet} {
    height:200px;
  }

`;

export const BtnParents = styled.div`

  display: flex;
  
  align-items:center;
  text-align:center;

  @media ${device.mobileS} {
    flex-direction:row ;
    width: 100vw;
    justify-content: flex-start;

  }
  @media ${device.tablet} {
    flex-direction: column;

  }
`;

export const Ptex = styled.p`
    text-align: justify;
    font-size: 16px;
    margin:auto;
    
    color:rgba(0, 0, 0, 0.50);

    @media ${device.mobileS} {
      padding-right: 0px;
  
    }
    @media ${device.tablet} {
      padding-right: 50px;
  
    }



`;
export const ButtonAce = styled.button`
background-color: #00b973;
border: none;
color: black;
padding: 10px 20px;
text-align: center;
text-decoration: none;
display: inline-block;
margin: 4px 2px;
cursor: pointer;
border-radius: 16px;
color:white;
margin:auto;

border-radius: 50px;
background-color: #00b973;
padding: 15px 45px;
text-transform: uppercase;
font-weight: bold;
width: max-content;
padding-top:5px;
padding-bottom:5px;
height: 55px;

@media ${device.mobileS} {
  margin-top:25px

}
@media ${device.tablet} {
  margin-top:45px

}
`;

export const MyLink = styled(Link)`
  display:flex;
  align-items: center;
  font-family:Montserrat-Regular;
 

  font-size: 16px;
  color: #00b973;
  margin: 1.5em auto 0 auto;
  text-decoration: underline;
  font-family: 'Montserrat-Bold';

`;