import React from "react";
import Navigation from "../commons/Navigation";
import {
  Title,
  HeaderContainer,
  Wrapper,
  Container,
  ContentContainer,
} from "../commons/commonsStyled/index";
import Logo from "./../commons/Logo";
import Footer from "../commons/Footer";

const Contact = () => {
  return (
    <>
      <Wrapper>
        <Container>
          <HeaderContainer>
            <Logo />
            <Navigation />
          </HeaderContainer>
        </Container>
        <ContentContainer>
          <Title>Contant</Title>
        </ContentContainer>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Contact;
