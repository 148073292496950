import {
  LOGIN,
  LOGOUT,
  SEND_FEEDBACK,
  SEND_FEEDBACK_ERROR,
  LOGIN_USER,
  FETCH_CLAIMS,
  ADD_FILTER_CLAIMS,
  DELETE_FILTER_CLAIMS,
  ADD_FILTER_CATEGORIES,
  ADD_FILTER_SUBCATEGORIES,
  DELETE_FILTER_CATEGORIES,
  FETCH_HOW_IT_WORKS,
  FETCH_PRIVACY_POLICY,
  FETCH_TERMS_OF_USAGE,
  FETCH_CATEGORIES,
  FETCH_CERTIFICATIONS,
  FETCH_COMPANIES,
  LOGIN_ERROR,
  ADD_FILTER_CERTIFICATIONS,
  DELETE_FILTER_CERTIFICATIONS,
  ADD_FILTER_PRIVATE_LABEL,
  DELETE_FILTER_PRIVATE_LABEL,
  ADD_FILTER_SEARCH,
  UPDATE_IMAGE
} from "./actionTypes";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
console.log(process.env);

export const fetchClaims = () => {
  return async (dispatch) => {
    const response = await fetch(
      apiBaseUrl + "/api/claims"
    );
    const json = await response.json();
    dispatch({ type: FETCH_CLAIMS, payload: json });
  };
};
export const fetchCategories = () => {
  return async (dispatch) => {
    const response = await fetch(
      apiBaseUrl + "/api/subcategories"
    );
    const json = await response.json();
    dispatch({ type: FETCH_CATEGORIES, payload: json });
  };
};
export const fetchCertifications = () => {
  return async (dispatch) => {
    const response = await fetch(
      apiBaseUrl + "/api/certifications"
    );
    const json = await response.json();
    dispatch({ type: FETCH_CERTIFICATIONS, payload: json });
  };
};
export const fetchCompanies = () => {
  return async (dispatch) => {
    const response = await fetch(
      apiBaseUrl + "/api/companies/"
    );
    const json = await response.json();
    console.log("companies");
    console.log(json);
    dispatch({ type: FETCH_COMPANIES, payload: json })
  };
};

export const fetchOneCompany = (id) => {
  return async (dispatch) => {
    const response = await fetch(
      apiBaseUrl + "/api/companies/"+id
    );
    const json = await response.json();
    console.log("companie is");
    console.log(json);
    dispatch({ type: FETCH_COMPANIES, payload: json })
  };
};
export const fetchOneCompanyEdit = (id) => {
  return async (dispatch) => {
    const response = await fetch(
      apiBaseUrl + "/api/companies/edit/"+id
    );
    const json = await response.json();
    console.log("companie is");
    console.log(json);
    dispatch({ type: FETCH_COMPANIES, payload: json })
  };
};
export const addFilterClaims = (filter, type = ADD_FILTER_CLAIMS) => {
  return (dispatch) => {
    dispatch({ type: type, payload: filter });
  };
};

export const addFilterCertification = (filter) => {
  return (dispatch) => {
    dispatch({ type: ADD_FILTER_CERTIFICATIONS, payload: filter });
  };
};
export const addFilterPrivateLabel = (filter) => {
  return (dispatch) => {
    dispatch({ type: ADD_FILTER_PRIVATE_LABEL, payload: filter });
  };
};
export const addFilterSearch = (filter) => {
  return (dispatch) => {
    dispatch({ type: ADD_FILTER_SEARCH, payload: filter });
  };
};
export const deleteFilterCertifications = (filter) => {
  return (dispatch) => {
    dispatch({ type: DELETE_FILTER_CERTIFICATIONS, payload: filter });
  };
};
export const deleteFilterPrivateLabel = (filter) => {
  return (dispatch) => {
    dispatch({ type: DELETE_FILTER_PRIVATE_LABEL, payload: filter });
  };
};
export const deleteFilterClaims = (filter) => {
  return (dispatch) => {
    dispatch({ type: DELETE_FILTER_CLAIMS, payload: filter });
  };
};
export const addFilterCategories = (filter) => {
  return (dispatch) => {
    dispatch({ type: ADD_FILTER_CATEGORIES, payload: filter });
  };
};
export const addFilterSubCategories = (filter) => {
  return (dispatch) => {
    dispatch({ type: ADD_FILTER_SUBCATEGORIES, payload: filter });
  };
};
export const deleteFilterCategories = (filter) => {
  return (dispatch) => {
    dispatch({ type: DELETE_FILTER_CATEGORIES, payload: filter });
  };
};
export const loginError = (error) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_ERROR, payload: error });
  };
};
export const loginUser = (user) => {
  console.log(user);
  return async (dispatch) => {
    const response = await fetch(
      apiBaseUrl + "/api/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(user),
      }
    );
    const json = await response.json();
    if (json.error) {
      console.log(json.error[0]);
      dispatch({ type: LOGIN_ERROR, payload: json.error[0] });
    } else if (json.success) {
      console.log(json);
      localStorage.setItem("token", json.token);
      dispatch({ type: LOGIN_USER, payload: json });
      dispatch({ type: LOGIN });
    }
  };
};

export const sendFeedback = (contact) => {
  console.log(contact);
  return async (dispatch) => {
    const response = await fetch(
      apiBaseUrl + '/api/sendFeedback',
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(contact),
      }
    );
    const json = await response.json();
    if (json.error) {
      console.log(json.error[0]);
      dispatch({ type: SEND_FEEDBACK_ERROR, payload: json.error[0] });
    } else if (json.success) {
      dispatch({ type: SEND_FEEDBACK, payload: json });
    }
  }
};

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem("token");
    dispatch({ type: LOGOUT });
  };
};

export const fetchHowItWorks = () => {
  return async (dispatch) => {
    const response = await fetch(
      apiBaseUrl + "/api/howitworks"
    );
    const json = await response.json();
    dispatch({ type: FETCH_HOW_IT_WORKS, payload: json });
  };
};

export const fetchPrivacyPolicy = () => {
  return async (dispatch) => {
    const response = await fetch(
      apiBaseUrl + "/api/privacypolicy"
    );
    const json = await response.json();
    dispatch({ type: FETCH_PRIVACY_POLICY, payload: json });
  };
}

export const fetchTermsOfUsage = () => {
  return async (dispatch) => {
    const response = await fetch(
      apiBaseUrl + "/api/termsofusage"
    );
    const json = await response.json();
    dispatch({ type: FETCH_TERMS_OF_USAGE, payload: json });
  };
}

export const updateImageTest = (imageObj) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_IMAGE, payload: imageObj });
  };
}