import FormContainerJoinUS from "../commons/Form/FormJoinUs";
import React from 'react'
import ReactDOM from 'react-dom'
import Logo from './../commons/Logo';
import Navigation from "../commons/Navigation";
import {
  Section,
  Text,
  CardsList,
  CardsItem,
  CardsTitle,
  Contact,
  ContactTitle,
  Required,
  TitleMain,
  P,
  CardsItemImg,
  CardsItemTitle,
  CardsItemText,
  CardsItemContainer,
  CardImg,
  TitleHome
} from "./Home/styledHome";

import {
  Title,
  StickyHeaderContainer,
  MyHeader,
  Wrapper,
  Container,
  ContentContainer,
  RequiredCircle,
  HeaderContainer
} from "../commons/commonsStyled/index";


const ContactJoinUs = () => {
  return (
    <Wrapper>
      <Container>
        <HeaderContainer>
          <Logo />
          <Navigation />
        </HeaderContainer>

        <Contact id="contact">
          <ContentContainer>
            <ContactTitle>Registo</ContactTitle>
            <h4 style={{width: "60vw", textAlign:"justify"}}>
              Este é o primeiro passo para a sua empresa constar do Portuguese Food & Beverage Portfolio.<br />
              Após preenchimento do formulário, a PortugalFoods entrará em contacto
              consigo para concluir o processo.</h4>
            <Required>
              <RequiredCircle />
              Campos obrigatórios
          </Required>
            <FormContainerJoinUS />
          </ContentContainer>
        </Contact>
      </Container>
    </Wrapper>

  )
}

export default ContactJoinUs;

