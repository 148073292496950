import { ADD_FILTER_CLAIMS,DELETE_FILTER_CLAIMS, RESET_OBJECT, } from "./actionTypes";

const initialState = [];

export const filterClaimsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_OBJECT:
      return action.payload;
    case ADD_FILTER_CLAIMS:
      return [...state, action.payload];
    
    case DELETE_FILTER_CLAIMS:
      return [...state.filter(filter => filter.id !== action.payload)]
    default:
      return state;

  }
};
