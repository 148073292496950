import { FETCH_TERMS_OF_USAGE} from "./actionTypes";

const initialState = {};

export const termsOfUsageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TERMS_OF_USAGE:
      return action.payload;
    default:
      return state;

  }
};