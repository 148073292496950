import {LOGIN_ERROR} from "./actionTypes";

const initialState = "";

export const loginErrorReducer= (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ERROR:
      return action.payload
    default:
      return state;
  }
};