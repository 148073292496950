import React, { useState } from "react";
import styled from "styled-components";
import { device, HeaderContainer } from "../commons/commonsStyled";
import Logo from "../commons/Logo";
import Navigation from "../commons/Navigation";

import {
    Title,
} from "../commons/commonsStyled/index";

import Footer from "../commons/Footer";

let indication = (new URLSearchParams(window.location.search)).get("indication");
console.log(indication)

const Success = () => {
    let [timeRem, setRem] = useState(10);

    {
        setTimeout(() => {
            if (timeRem > 0) {
                setRem(timeRem - 1);
            }
            else { setRem(0) }

            if (timeRem <= 0) {
                window.location.href = "/";
            }
        }, 1000)
    }

    return (
        <>

            <HeaderContainer>
                <Logo />
                <Navigation />
            </HeaderContainer>

            {indication == 'registo' ?

                <Title style={{ margin: "auto", width: "90vw", textAlign: "justify", paddingTop: "74px", fontSize: "25px" }}>
                    Empresa registada com sucesso.<br />
                    Entraremos em contacto brevemente com as credenciais de acesso para criação do perfil da empresa.<br />
                    Será redirecionado para a página principal em <b>{timeRem}</b> segundos.
                    {/* Empresa registada com sucesso.<br />
                Entraremos em contacto brevemente com as credenciais de acesso para criação do perfil da empresa.<br />
                Será redirecionado para a página principal em <b>{timeRem}</b> segundos. */}
                </Title>
                :
                indication == 'contact' ?
                    <Title style={{ margin: "auto", width: "90vw", textAlign: "justify", paddingTop: "74px", fontSize: "25px" }}>
                        Your message has been sent.<br />
                We will get back to you as soon as possible. Thank you! <br />
                Redirecting to the homepage in <b>{timeRem}</b>.</Title>

                    :
                    indication == 'save' ?
                        <Title style={{ margin: "auto", width: "90vw", textAlign: "justify", paddingTop: "74px", fontSize: "25px" }}>
                            Rascunho guardado com sucesso.<br />
                Será redirecionado para a página principal em <b>{timeRem}</b> segundos.</Title>

                        :
                        <Title style={{ margin: "auto", width: "90vw", textAlign: "justify", paddingTop: "74px", fontSize: "25px" }}>
                            A informação da sua empresa foi adicionada com sucesso ao nosso diretório.<br />
                            Entraremos em contacto assim que a informação seja validada.<br />
                            Obrigado!<br />
                            Será redirecionado para a página principal em <b>{timeRem}</b> segundos.</Title>
            }

            <PDiv  >
                <Footer />
            </PDiv>
        </>
    );
};

export default Success;

export const PDiv = styled.div`
position:absolute; 
bottom:0;
margin:auto; 
width:100vw;

@media ${device.mobileS} {
    display:none;
  }

  @media ${device.tablet} {
    display:block;
  }
`;
