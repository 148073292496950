import styled from "styled-components";
import { device } from "./../commonsStyled/index";
import { Form, Field } from "react-final-form";

export const SearchForm = styled.div`
  display: flex;
  
  
  margin-top: 58px;
  width: ${(props) => (props.home ? "100%" : null)};
  @media ${device.mobileS} {
    flex-direction: column;
    align-items: flex-start;
    margin-left:${(props) => (!props.home ? "10vw" : "0")};
  }
  @media ${device.mobileL} {
    
    margin-left:${(props) => (!props.home ? "10vw" : "0")};
  }
  @media ${device.tablet} {
    margin-left: ${(props) => (props.home ? null : "50px")};
    flex-direction: column;
    align-items: flex-start;

  }
  @media ${device.laptop} {
    flex-direction: row;
    align-items:${props => props.directory? "center" : null } ;
    margin-left: ${(props) => (props.home ? null : "10vw")};
    form{
      display:flex ;
      justify-content: space-between;
      width: 70vw;
    }
  }
  @media ${device.laptopL} {
    margin-left: ${(props) => (props.home ? null : "10vw")};
    form{
      display:flex ;
      justify-content: space-between;
      width: 70vw;
    }
  }
  
  
  
`;
export const SearchFormInput = styled(Field)`



  display: flex;
  font-family: Montserrat-Regular;
  font-size: ${(props) => (props.home ? "57px" : " 24px")};
  width: 100%;
  height:100%;
  border-radius: ${(props) => (props.home ? "none" : "36px")};

  border: ${(props) => (props.home ? "1px solid grey" : "1px solid grey")};
  border-top: ${(props) => (props.home ? "none" : null)};
  border-left: ${(props) => (props.home ? "none" : null)};
  border-right: ${(props) => (props.home ? "none" : null)};
  background-color: #ffffff;

  padding-left: ${(props) => (props.home ? null : "40px")};
  padding-top: ${(props) => (props.home ? "10px" : null)};
  outline: none;
  box-sizing: border-box;
  @media ${device.mobileS} {
    font-size: 13px;
    height: 44px;
    padding-left:18px;
    width: 280px;
  }
  @media ${device.mobileL} {
    font-size: 13px;
    width: 361px;
    height: 44px;
    padding-left:18px;
  }
  @media ${device.tablet} {
   
    width: 600px;
    padding-left: 30px
  }
  @media ${device.laptop} {
    font-size:${props => props.directory? "50px" : "1.5vw !important" } ;
    width: 45vw;
    height:70px;
    padding-left: 32px
  }
  @media ${device.laptopL} {
    font-size:${props => props.directory? "50px" : "1.2vw !important"} ;
    width: 50vw; 
    padding-left: 32px
  }
  
  
`;

export const SearchFormInputLabel = styled.label`
  
  font-family: Montserrat-Regular;
  font-size: 20px;
  
  width: ${(props) => (props.home ? "70%" : "1104px")};
  

  @media ${device.mobileS} {
    width: 280px;
    margin-right:0px;
    height:44px;
    box-sizing: border-box;
    margin-bottom: 15px;
  }
  @media ${device.mobileL} {
    width: 361px;
    margin-right:0px;
    box-sizing: border-box;
  }
  @media ${device.tablet} {
    margin-right: 70px;
    margin-bottom: 15px;
    width: 600px;
  }
  @media ${device.laptop} {
    width: 750px;
    height: 70px;
    input{
      font-size: 25px;
    }
  }
  @media ${device.laptopL} {
    width: 750px;
    height: 70px;

  }
  

  
  
  
  
`;
export const SearchFormButton = styled.input`
  display: none;
  outline: none;
`;
export const SearchFormButtonLabel = styled.button`
  border:none;
  display: flex;
  align-items: center;
  position: relative;
  width: 348px;
  height: 70px;
  border-radius: 36px;
  background-color: #20babf;
  cursor: pointer;
  outline: none;
  @media ${device.mobileS} {
    width: 158px;
    height: 44px;
    margin-top:${props => props.directory ? "20px" : "10px"}
  }
  @media ${device.mobileL} {
    width: 218px;
    height: 44px;
  }
  @media ${device.laptop} {
    width: 300px;
    height:70px;
    margin-top:0px;
    margin-left:0px;
  }
  
  
`;
export const SearchFormButtonCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #309da1;
  @media ${device.mobileS} {
    width: 44px;
    height: 44px;
  }
  @media ${device.mobileL} {
    width: 44px;
    height: 44px;
  }
  @media ${device.laptop} {
    width: 70px;
    height:70px;
  }
  
  
`;
export const SearchFormButtonText = styled.p`
  position: absolute;
  font-family: Montserrat-Bold;
  font-size: 20px;
  color: #ffffff;
  left: 110px;
  @media ${device.mobileS} {
    left: 25%;
    font-size: 13px;
  }
  @media ${device.mobileL} {
    left: 25%;
    font-size: 16px;
  }
  @media ${device.tablet} {
    font-size: 16px;
    left: 31%;
  }
  @media ${device.laptop} {
    left: 20%;
    font-size: 16px;
    
  }
  @media ${device.laptopL} {
    left: 31%;
    font-size: 18px;
  }
  
  
`;
export const CircleImg = styled.img`
  @media ${device.mobile} {
    width:25px;

  }
  @media ${device.tablet} {
    width:50%;
    height:50%;
  }
`;
