import { ADD_FILTER_PRIVATE_LABEL,DELETE_FILTER_PRIVATE_LABEL, } from "./actionTypes";

const initialState = [];

export const filterPrivateLabelReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILTER_PRIVATE_LABEL:
      return [...state, action.payload];
    
    case DELETE_FILTER_PRIVATE_LABEL:
      return [...state.filter(filter => filter.id !== action.payload)]
    default:
      return state;

  }
};
