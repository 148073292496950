import React, { useState, Fragment, useEffect, useMemo } from "react";
import styled from "styled-components";
import { FieldInput, InputFileDesc } from "./InformationAboutCompany";
import downloadImg from "../../../img/download-img.png";
import close from "../../../img/close.svg";
import productClose from "../../../img/product-close.svg";

import addfilter from "../../../img/addfilter.png";

import {
  ButtonImg,
  ButtonText,
  RefineSeachButton,
  Img,
} from "../../pages/Directory";
import MyDropzone from "./MyDropzone";
import { FieldArray } from "react-final-form-arrays";
import { Field, FormSpy, useField } from "react-final-form";
import {
  InputContainer,
  Label,
  LabelText,
  NynPage,
  Page,
  Title,
  Recommendation,
} from "./styled/commonStyled";

import {
  InputFileContainer,
  InputFileRepresentative,
  DownloadImg,
  InputFileText,
  Strong,
  InputFile,
} from "./InformationAboutCompany";
import { Container, Category, Subcategory } from "./AddCategoryField";
import { InputFileBrand, Delete } from "./AddBrandFrom";
import { Br, device, RequiredCircle } from "./../../commons/commonsStyled/index";
import { Th, Td, Table } from "../../pages/ProductTable";
import { LabelPrivate, FieldPrivate } from "./InformationBrandCompany";
import { useDispatch, useSelector } from "react-redux";
import { updateImageTest } from "../../../redux/actions";
import { ContainerHelper, Example, ExampleButton, ExampleText, Helper, HelperLogo, HelperText } from "./InformationAboutCompany";
import helperImg from "../../../img/helper.png";

const ProductsOnSale = (props) => {
  let universalname = {};
  const [state, setState] = useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const dispatch = useDispatch();

  const Error = ({ name }) => {
    const {
      meta: { touched, error },
    } = useField(name, { subscription: { touched: true, error: true } });
    return touched && error ? (
      <span style={{ color: "red" }}>{error}</span>
    ) : null;
  };

  const products_descriptionVal = useSelector((root) => root.imageObj.products_description);

  const formDasSubCategorias = useSelector((root) => root.imageObj.formDasSubCategorias);

  const updateObject = (values, subCatId, index, key, value) => {
    console.log("values", values);
    console.log("subCatId", subCatId);
    console.log("key", key);
    console.log("index", index);
    console.log("value", value);

    //values[subCatId][index][key] = value;
    (values.products[subCatId][index])[key] = value;
    dispatch(updateImageTest({
      formDasSubCategorias: {
        key: subCatId,
        values: values.products[subCatId]
      }
    }));
  };
  const removeObject = (values, subCatId, index) => {
    if (index > -1) {
      values.products[subCatId].splice(index, 1);
    }

    dispatch(updateImageTest({
      formDasSubCategorias: {
        key: subCatId,
        values: values.products[subCatId]
      }
    }));
  };

  const valueForSubCatAndIndex = (values, subCatId, index, property) => {
    console.log((values.products[subCatId]));
    if (values.products[subCatId][index])
      return (values.products[subCatId][index])[property];
  };

  if (formDasSubCategorias) {
    console.log('AENASDTRI', formDasSubCategorias)
    props.company.products[formDasSubCategorias.key] = formDasSubCategorias.values;
  }

  return (
    <>
      <ContainerHelper>
        <Helper>
          <HelperText>
            Se tiver alguma dúvida no preenchimento deste formulário, por favor{" "}
            <br /> contacte a <Strong>Market Division</Strong> da{" "}
            <Strong>PortugalFoods</Strong> através do
            <Strong> 220 944 476</Strong>
          </HelperText>
          <HelperLogo>
            <img src={helperImg} alt="img-helper" />
          </HelperLogo>
        </Helper>
        <Br />
        <Example>
          <ExampleText>
            Clique para ver um exemplo de uma página de Empresa
          </ExampleText>
          <ExampleButton to="/directory/PortugalFoods" target="_blank">EXEMPLO PÁGINA DE EMPRESA</ExampleButton>
        </Example>
        <Br />
      </ContainerHelper>
      <Page>
        <Title>
          <NynPage>5. </NynPage> Produtos Comercializados
      </Title>
        <InputContainer>
          <Label>
            <LabelText>
              Descreva os produtos que a sua empresa comercializa.
            <RequiredCircle style={{ display: "inline-block" }} /><Error name="products_description" />
            </LabelText>
            <Recommendation>
              (texto em inglês e com um máximo de 1000 caracteres)
          </Recommendation>
            <List>
              <FieldInputCastom
                desc="252px"
                name="products_description"
                component="textarea"
                type="text"
                onKeyUp={e => dispatch(updateImageTest({ products_description: e.target.value }))}
              />
              <InputFileDescCastom>
                Para dar a conhecer a sua empresa a potenciais compradores.
              <br />
              Alguns tópicos que podem ser úteis: Quem somos? O que fazemos? O
              que nos diferencia?
              <br />
                <p
                  style={products_descriptionVal == null || products_descriptionVal.length < 1000 ? { color: 'black', textAlign: 'left', fontSize: 'small' }
                    : { color: 'red', textAlign: 'left', fontSize: 'small' }}>
                  {(products_descriptionVal || '').length}/1000
              </p>
              </InputFileDescCastom>
            </List>
          </Label>
        </InputContainer>
        <InputContainer>
          <LabelText>
            Carregue imagens ilustrativas dos produtos da sua empresa.
          <RequiredCircle style={{ display: "inline-block" }} /><Error name="productImg" />
          </LabelText>
          <Recommendation>(máximo de 10 imagens)</Recommendation>
          <FieldArray name="productImg">
            {({ fields }) => (
              <List>
                {fields.map((name, index) => (
                  <Item key={name}>
                    <InputFileBrand>
                      {(fields.value[index] && fields.value[index].data != '') ? (
                        <>
                          <DownloadImg src={fields.value[index].data} />
                          <Delete
                            onClick={() => {
                              //state.splice(index, 1);
                              fields.remove(index);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={close} alt="close" />
                          </Delete>
                        </>
                      ) : (
                          <Label>
                            <img src={downloadImg} alt="download-img" />
                            <InputFileText>
                              <Strong>JPEG/PNG</Strong>
                          1080 x 1080 px
                        </InputFileText>
                            <Field name={`${name}.data`} component="input">
                              {(props) => (
                                <MyDropzone
                                  accept="image/*"
                                  validateSize={[1080, 1080]}
                                  sendImg={(marca) => {
                                    fields.update(index, { name: (index in universalname) ? universalname[index] : '', img: marca.map((item) => item) })
                                  }}
                                  {...props.input}
                                />
                              )}
                            </Field>
                          </Label>
                        )}
                    </InputFileBrand>
                  </Item>
                ))}
                <Item>

                  {fields.length === 0 || (fields.length < 10 && fields.value[fields.length - 1].data !== "") ?


                    <ButtonContainer>
                      <InputFile onClick={() => fields.push({ data: "" })}>
                        {/*<img src={downloadImg} alt="download-img" />*/}
                        <ButtonImg>
                          <Img src={addfilter} alt="addfilter" />
                        </ButtonImg>
                        <InputFileText>
                          <Strong>JPEG/PNG</Strong>
                      1080 x 1080 px
                    </InputFileText>
                      </InputFile>
                      <InputFileDesc>
                        Formato jpg ou png.
                    <br />
                        <br />
                    Dimensões:
                    <br />
                    1080 x 1080 px
                    <br />
                    <br />
                     Tamanho:
                    <br />
                      1mb
                  </InputFileDesc>
                    </ButtonContainer>
                    :
                    <></>
                  }
                </Item>
              </List>
            )}
          </FieldArray>
          <Br />
        </InputContainer>
        <InputContainer>
          <LabelText>
            Indique, para cada subcategoria de produto, os principais produtos
          <br />e respetivas vida útil (em número de dias) e condição de
          armazenamento. <RequiredCircle style={{ display: "inline-block" }} /><Error name="ProductsError" />
          </LabelText>
          <Recommendation>
            Adicione até 10 produtos por subcategoria.
        </Recommendation>
        </InputContainer>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <>
              {values.categories.map((item) =>
                item.subcategories.map((sub) => (
                  <div key={sub.id}>
                    <ContainerProduct key={sub.id}>
                      <CategoryProd> {item.name}</CategoryProd>
                      <SubcategoryProd>{sub.name}</SubcategoryProd>
                      {console.log("From spy tem tudo ", values)}
                    </ContainerProduct>
                    {/* `products.${item.id}_${sub.id}` */}
                    <FieldArray name={`products.${sub.id}`}>
                      {({ fields }) => (
                        <>
                          <TableProduct>
                            <thead>
                              <Tr align="center">
                                <ThName align="left" width="50%">
                                  PRODUCT NAME
                              </ThName>
                                <ThShelf align="center" width="15%">
                                  SHELF-LIFE (DAYS)
                              </ThShelf>
                                <ThStorage align="center" width="35%">
                                  STORAGE TYPE
                              </ThStorage>
                              </Tr>
                            </thead>

                            <tbody>
                              {fields.map((name, index) => (
                                <Fragment key={index}>
                                  {console.log("o que esta?", name)}
                                  <Tr key={name}>
                                    <Td align="left">
                                      <FieldName
                                        name={`${name}.name`}
                                        component="input"
                                        placeholder="Nome do Produto"
                                        onChange={(e) => updateObject(values, sub.id, index, 'name', e.target.value)}
                                      />
                                    </Td>
                                    <Td align="center">
                                      <FieldShelfLife
                                        name={`${name}.shelfLife`}
                                        component="input"
                                        placeholder="18"
                                        onChange={(e) => updateObject(values, sub.id, index, 'shelfLife', e.target.value)}
                                      />
                                    </Td>
                                    <Td align="center">
                                      <LabelContainerCastom>
                                        <LabelPrivateCastom>
                                          Chilled
                                        <FieldStorageType
                                            name={`${name}.storageType`}
                                            component="input"
                                            type="radio"
                                            value="0"
                                            onChange={() => { }}
                                            onClick={() => updateObject(values, sub.id, index, 'storageType', 0)}
                                            checked={valueForSubCatAndIndex(values, sub.id, index, 'storageType') == '0'}
                                          />
                                        </LabelPrivateCastom>

                                        <LabelPrivateCastom>
                                          Frozen
                                        <FieldStorageType
                                            name={`${name}.storageType`}
                                            component="input"
                                            type="radio"
                                            value="1"
                                            onChange={() => { }}
                                            onClick={(e) => updateObject(values, sub.id, index, 'storageType', 1)}
                                            checked={valueForSubCatAndIndex(values, sub.id, index, 'storageType') == '1'}
                                          />
                                        </LabelPrivateCastom>

                                        <LabelPrivateCastom>
                                          Shelf Stable
                                        <FieldStorageType
                                            name={`${name}.storageType`}
                                            component="input"
                                            type="radio"
                                            value="2"
                                            onChange={() => { }}
                                            onClick={(e) => updateObject(values, sub.id, index, 'storageType', 2)}
                                            checked={valueForSubCatAndIndex(values, sub.id, index, 'storageType') == '2'}
                                          />
                                        </LabelPrivateCastom>
                                      </LabelContainerCastom>
                                    </Td>
                                    <TdProduct>
                                      <CloseButtonProduct
                                        type="button"
                                        //onClick={() => fields.remove(index)}
                                        onClick={() => removeObject(values, sub.id, index)}
                                      ></CloseButtonProduct>
                                    </TdProduct>
                                  </Tr>
                                </Fragment>
                              ))}
                            </tbody>
                          </TableProduct>
                          {
                            (fields && fields.length >= 10)
                              ? null
                              : <>
                                <ButtonAddProduct
                                  type="button"
                                  // onClick={() => console.log(fields)}
                                  onClick={() => {
                                    const obj = {
                                      key: sub.id,
                                      values: (!(sub.id in values.products)) ? [] : values.products[sub.id].map(e => e)
                                    };

                                    obj.values.push({
                                      name: "",
                                      shelfLife: "",
                                      storageType: "",
                                      subcategory_id: "" + sub.id,
                                    });

                                    dispatch(updateImageTest({ formDasSubCategorias: obj }));
                                    // fields.push({
                                    //   name: "",
                                    //   shelfLife: "",
                                    //   storageType: "",
                                    //   subcategory_id: ""+sub.id,
                                    // })
                                  }
                                  }
                                >
                                  <ButtonText>Adicionar Produto</ButtonText>
                                  <ButtonImg>
                                    <Img src={addfilter} alt="addfilter"></Img>
                                  </ButtonImg>
                                </ButtonAddProduct>
                              </>
                          }
                        </>
                      )}
                    </FieldArray>
                  </div>
                ))
              )}
            </>
          )}
        </FormSpy>
      </Page>
    </>
  );
};

export default ProductsOnSale;

export const List = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Item = styled.li`
  margin: 0 30px 30px 0;
`;
export const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
  margin: 36px 0 32px 0;
  padding-left: 25px;
  @media ${device.mobileS} {
    margin: 26px 0 29px 0;
  }
  @media ${device.laptop} {
    margin: 33px 0 32px 0;
  }
  @media ${device.laptopL} {
    margin: 26px 0 38px 0;
  }
`;
export const Tr = styled.tr`
  vertical-align: middle;
  @media ${device.mobileS} {
    vertical-align: bottom;
  }
`;
export const FieldName = styled(Field)`
  width: 95%;
  height: 54px;
  object-fit: contain;
  border-radius: 27px;
  border: solid 1px rgba(63, 66, 76, 0.3);
  background-color: #ffffff;
  margin: 20px 0 20px 0;
  padding-left: 25px;
  outline: none;
  box-sizing: border-box;
  @media ${device.mobileS} {
    height: 44px;
    width: 110px;
    font-size: 9px;
  }
  @media ${device.mobileL} {
    width: 180px;
  }
  @media ${device.tablet} {
    width: 250px;
  }
  @media ${device.laptop} {
    width: 377px;
    margin-right: 20px;
  }
  @media ${device.laptopL} {
    width: 448px;
    font-size: 14px;
  }
`;
export const FieldShelfLife = styled(FieldName)`
  @media ${device.mobileS} {
    width: 44px;
    height: 44px;
    padding-left: 14px;
  }
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    width: 70px;
    margin: 20px 0 28px 0;
  }
  @media ${device.laptop} {
    width: 140px;
  }
  @media ${device.laptopL} {
    width: 148px;
    font-size: 14px;
  }
`;
export const ThProduct = styled(Th)`
  &:after {
    display: none;
  }
`;
export const TdProduct = styled(Td)`
  &:after {
    display: none;
  }
  @media ${device.mobileS} {
    vertical-align: middle;
  }
`;
export const ThName = styled(Th)`
  @media ${device.mobileS} {
    font-size: 11px;
  }
  @media ${device.laptop} {
    width: 20%;
    font-size: 14px;
  }
  @media ${device.laptopL} {
    width: 30%;
  }
`;
export const ThShelf = styled(Th)`
  @media ${device.mobileS} {
    font-size: 11px;
  }
  @media ${device.laptop} {
    width: 20%;
    font-size: 14px;
  }
  @media ${device.laptopL} {
    width: 20%;
  }
`;
export const ThStorage = styled(Th)`
  @media ${device.mobileS} {
    font-size: 11px;
  }
  @media ${device.laptop} {
    font-size: 14px;
  }
`;
export const TableProduct = styled(Table)`
  border-spacing: 0px;
`;
export const ContainerProduct = styled(Container)`
  margin-top: 52px;

  @media ${device.mobileS} {
    height: 44px;
    align-items: center;
    flex-direction: row;
  }
  @media ${device.mobileL} {
    width: fit-content;
  }
`;
export const ButtonAddProduct = styled(RefineSeachButton)`
  margin: 20px 0 20px 0;
`;
export const CloseButtonProduct = styled.button`
  width:20px;
  height:20px;
  background-image:url("${productClose}");
  background-color:transparent;
  background-repeat:no-repeat;
  border:none;
`;

export const FieldStorageType = styled(FieldPrivate)`
  @media ${device.mobileS} {
    min-width: 20px;
    min-height: 20px;
    margin-right: 0px;
  }
  @media ${device.laptop} {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  @media ${device.laptopL} {
    min-width: 36px;
    min-height: 36px;
    font-size: 14px;
    margin-right: 20px;
  }
`;
export const FieldInputCastom = styled(FieldInput)`
  @media ${device.mobileS} {
    width: 280px;
    height: 200px;
  }
  @media ${device.tablet} {
    width: 350px;
    height: 200px;
  }
  @media ${device.laptop} {
    width: 500px;
    height: 300px;
  }

  @media ${device.laptopL} {
    width: 700px;
  }
`;
export const LabelPrivateCastom = styled(LabelPrivate)`
  @media ${device.mobileS} {
    font-size: 9px;
    margin-bottom: 10px;
  }
  @media ${device.laptopL} {
    font-size: 16px;
  }
  @media ${device.tablet} {
    margin-right: 10px;
  }
`;
export const LabelContainerCastom = styled(LabelContainer)`
  @media ${device.mobileS} {
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 12px;
  }
  @media ${device.mobileL} {
    padding-left: 29px;
  }
  @media ${device.tablet} {
    flex-direction: row;
    padding-left: 0px;
    width: 320px;
  }
  @media ${device.laptop} {
    margin: 33px 0 26px 0;
  }
`;

export const InputFileDescCastom = styled(InputFileDesc)`
  @media ${device.mobileS} {
    width: 280px;
    margin-left: 0px;
    margin-top: 20px;
  }
  @media ${device.tablet} {
    margin-left: 20px;
  }
  @media ${device.laptop} {
    width: 200px;
    font-size: 14px;
  }
  @media ${device.laptopL} {
    width: 200px;
  }
`;

export const CategoryProd = styled(Category)`
  @media ${device.mobileS} {
    min-width: fit-content;
    padding: 0 20px;
    font-size: 9px;
    max-height: 43px;
  }
  @media ${device.tablet} {
    font-size: 12px;
  }
`;
export const SubcategoryProd = styled(Subcategory)`
  @media ${device.mobileS} {
    font-size: 12px;
    height: fit-content;
    margin-top:9px;
    margin-bottom:9px;
  }
`;
