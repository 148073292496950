import React,{useState} from "react";
import {
  Label,
  Row,
  Input,
  ContentFormButtom,
  Circle,
  ContentFormButtonText,
  Textarea,
  InputButton,
  LabelButton,
  CastomInput,
  RowCastom,
  LabelCastomTel,
  LabelCastomWeb
} from "./styledForm";
import {RequiredCircle} from "../commonsStyled/index";
import circleImg from "../../../img/sircle.png";
import { Form, useField  } from 'react-final-form'
import styled from "styled-components";
import Loading from "../Loading";


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const Error = ({ name }) => {
  const {
    meta: { touched, error,submitError }
  } = useField(name, { subscription: { touched: true, error: true} });
return  touched && error ? <span style={{color:"red"}}>{error}</span> : null;
};

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;  //Remove on production

const FormContainer = () => {
  const [massage,setMassage] = useState(false)
  const onSubmit = async (values,form) => {
    try {
      let response = await fetch(apiBaseUrl + '/api/sendFeedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(values)
    });
    
    let result = await response.json();
    if (result.success) {
      setMassage(result.success)
      window.location.href = "/success?indication=contact";
      //alert('Message sent');
      setTimeout(form.restart);
    }
    
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <Form
    onSubmit={onSubmit}
    validate={values => {
      const errors = {}
      if (!values.name) {
        errors.name = 'Required'
      }
      if (!values.email) {
        errors.email = 'Required'
      }

      if (values.email && !values.email.match(/.+@.+/)) {
        errors.email = 'Must be a valid email address';
      }

      if (!values.company) {
        errors.company = 'Required'
      }
      if (!values.phone) {
      }else if (isNaN(values.phone)) {
        errors.phone = ' Must be a number'
      }
     
      if (!values.subject) {
        errors.subject = 'Required'
      }
      if (!values.message) {
        errors.message = 'Required'
      }
      if (!values.name || !values.email  || !values.company  || !values.subject  || !values.message ) {
        errors.error = 'The form contains errors'
      }
      
      return errors
    }}
    render={({ handleSubmit, form, submitError, submitting, pristine, values,error }) => (
      <form onSubmit={handleSubmit}>
        {massage ? <h2 style={{color:"#309DA1"}}>Message sent</h2> : null}
        <Label>
        <Row>
          Name
          <RequiredCircle />
          <Error name="name" />
        </Row>
        <Input name="name"  component="input" submitting={submitting} />
       
      </Label>
      <Label>
        <Row>
          Email
          <RequiredCircle />
          <Error name="email" />
        </Row>
        <Input name="email" type="email"  component="input" submitting={submitting} />
      </Label>
      <Label>
        <Row>
          Company
          <RequiredCircle />
          <Error name="company" />
        </Row>
        <Input name="company"  component="input" submitting={submitting} />
      </Label>
      <RowCastom>
        <LabelCastomTel>
        <Row>
          Telephone
          <Error name="phone" />
        </Row>
          <Input name="phone"  component="input"   type="tel" submitting={submitting} />
        </LabelCastomTel>
        <LabelCastomWeb>
          Website
          <Input name="website_url"  component="input" submitting={submitting}  />
        </LabelCastomWeb>
      </RowCastom>
      <Label>
        <Row>
          Subject
          <RequiredCircle />
          <Error name="subject" />
        </Row>
        <Input name="subject"  component="input" submitting={submitting} />
      </Label>
      <Label>
        <Row>
          Message
          <RequiredCircle />
          <Error name="message" />

        </Row>
        <Textarea name="message" component="textarea" submitting={submitting}></Textarea>
      </Label>
      <Container>
      <LabelButton  disabled={submitting || pristine}  type="submit">
        <ContentFormButtom  submitting={submitting}>
          <ContentFormButtonText>SEND</ContentFormButtonText>
          <Circle>
            <img src={circleImg} alt="circleImg" />
          </Circle>
        </ContentFormButtom>
      </LabelButton>
      <Error name="error" />
      {submitting ? <Loading loading={true} loadingState={2} loadMessage={"A entrar..."}></Loading> : null}

      </Container>
      </form>
    )}
  />
  );
};

export default FormContainer;

export const Container = styled.div`
  display: flex;
  align-items:center;
`;