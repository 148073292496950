import { FETCH_PRIVACY_POLICY} from "./actionTypes";

const initialState = {};

export const privacyPolicyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRIVACY_POLICY:
      return action.payload;
    default:
      return state;

  }
};