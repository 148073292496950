import { ADD_FILTER_SEARCH} from "./actionTypes";

const initialState = [];

export const filterSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILTER_SEARCH:
      return [action.payload];
    
    default:
      return state;
  }
};