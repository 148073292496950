import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled, { css, keyframes } from 'styled-components'

import successImg from "../../img/LoadingImg/success@2x.png";
import loadingImg from "../../img/LoadingImg/loading@2x.png";
import errorImg from "../../img/LoadingImg/error@2x.png";
import spnBig from "../../img/LoadingImg/spinnerLoadingBig.png";
function getModalStyle() {
    const top = 50;
    const left = 50;
    const padding = 50;
    const lineHeight = 2.6;
    const color = '#309DA1';
    const backgroundColor ='#FFFFFF';

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        padding: `${padding}px`,
        lineHeight: `${lineHeight}`,
        color: `${color}`,
        backgroundColor:`${backgroundColor}`,
        height:'100vh',
        width:'100vw'

    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',

    },
}));

/*STATES*
Since this is not TS insted of a Enum lets use

props.loadingState:
1-inactive
2-Loading
3-Error
4-Sucess
*/
   

export default function Loading(props) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(true);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const pulse = keyframes`
    0% {
    transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    `

    const animation = () =>
        css`
    ${pulse} 2s linear infinite ;
    `

    const LoadIMG = styled.img`
    animation: ${animation};
    margin:auto;
    height:40px;
    top:50%;
    left:50%;
    position: fixed;
    transform: translate(-50%, -50%);
    `


   
    const body = (
        <div style={modalStyle} className={classes.paper}>
           {/*<h2 id="simple-modal-title" style={{textAlign:"center"}}>{props.loadMessage}</h2> */} 
            <div id="simple-modal-description" style={{display:"flex"}}>

                { 
               /* props.loadingState == 2 ?
                <LoadIMG src={loadingImg} />:
                props.loadingState == 3 ?
                <img src={successImg} style={{margin:"auto"}}/>:
                <img src={errorImg} style={{margin:"auto"}}/> */        
                }
                <LoadIMG src={spnBig} />

            </div>
        </div>
    );

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}



